import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const Box = styled.div`
  margin: 73px auto 44px;
  height: 42px;
  display: flex;
  justify-content: center;
  ${mixins.gap4}
  position: relative;
  width: fit-content;
  &::before {
    ${({ disableAccess }) =>
      disableAccess
        ? `display: none;`
        : ` pointer-events: none;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            transition: width 0.3s;
            content: "";
            width: calc(250% + 200px);
            height: 600%;
            background-image: url("/assets/search-bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;`};
  }
  @media ${breakpoints.desktopXl} {
    margin: 5.069vw auto 3.056vw;
    height: 3.284vw;
  }
  @media ${breakpoints.tabletL} {
    margin: 8.753vw auto 5.276vw;
    height: 4.106vw;
  }
  @media ${breakpoints.tabletM} {
    margin: 8.753vw auto 5.276vw;
    height: 5.653vw;
  }
  @media ${breakpoints.mobile} {
    width: 80.251vw;
    height: 11.285vw;
    margin: 10.031vw 0 11.285vw 3.762vw;
  }
  transition: margin 0.3s;
  ${({ disable }) =>
    disable &&
    `
    opacity: 0;
    pointer-events: none;
    margin: 42px 0 0 !important;
    @media ${breakpoints.mobile} {
      margin-top: 6.27vw !important;
    }
  `};
  ${({ open }) => open && `gap: 0 !important;`};
`;

const SearchButton = styled.button`
  border-radius: 100%;
  position: absolute;
  max-width: 42px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  @media ${breakpoints.desktopXl} {
    max-width: 3.284vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 4.106vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 5.653vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 11.285vw;
  }
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    & path {
      ${({ open }) => open && `stroke-opacity: 0.4;`};
    }
  }
  &:active {
    & path {
      ${({ open }) => open && `stroke-opacity: 0.2;`};
    }
  }
`;

// --- loupe ---

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  transition: width 0.64s, background-color 0.3s;
  width: 42px;
  border-radius: 24px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.876vw;
    width: 3.284vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 2.346vw;
    width: 4.106vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 3.23vw;
    width: 5.653vw;
  }
  @media ${breakpoints.mobile} {
    width: 11.285vw;
    border-radius: 7.524vw;
  }
  &:hover {
    background-color: var(--wh08);
  }
  &:focus {
    background-color: var(--wh04);
    color: var(--wh6);
    &::placeholder {
      color: var(--wh12);
    }
  }

  ${({ disabled }) =>
    `background-color: ${disabled ? "var(--wh02)" : "var(--wh04)"};
    pointer-events: ${disabled ? "none" : "all"};
    `}

  ${({ open }) => open && `width: 506px !important;`}
  ${({ loading }) =>
    loading &&
    `
    animation: gradientInput 1.5s ease infinite;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.04) );
    background-size: 300% 100%;
    `}


    & > button {
    ${({ disabled }) => disabled && `&>svg>path{fill-opacity: 0.2}`};
  }
`;

export const LoupeButton = styled(SearchButton)`
  left: 0;
  & > svg {
    pointer-events: none;
    @media ${breakpoints.mobile} {
      width: 4.389vw;
      height: 4.389vw;
    }
  }
`;
export const CloseButton = styled(SearchButton)`
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  & > svg {
    pointer-events: none;
  }
  ${({ show }) =>
    show &&
    `
      opacity: 1;
      pointer-events: all;
  `}
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    & path {
      stroke-opacity: 0.4;
    }
  }
  &:active {
    & path {
      stroke-opacity: 0.2;
    }
  }
`;
export const Input = styled.input`
  ${fonts.manrope5}
  ${mixins.fs16}
  letter-spacing: 0.48px;
  color: var(--wh6);
  background-color: transparent;
  height: 100%;
  width: 100%;
  &::placeholder {
    transition: color 0.3s;
    color: var(--wh6);
  }
`;
export const InputContainer = styled.div`
  position: relative;
  width: 0;
  ${({ open }) => open && `width: 82%;`};
  @media ${breakpoints.mobile} {
    ${({ open }) => open && `width: 63%;`};
  }
  overflow: hidden;
  &::before,
  &::after {
    opacity: 0;
    transition: opacity 0.3s;
    ${({ isOverflow }) => isOverflow && `opacity: 1;`}
    position: absolute;
    content: "";
    top: 0;
    left: 0px;
    transform: translateX(-50%);
    height: 100%;
    width: 16px;
    -webkit-mask-image: linear-gradient(
      90deg,
      #161616 26%,
      rgba(22, 22, 22, 0.08) 100%
    );
    mask-image: linear-gradient(
      90deg,
      #161616 26%,
      rgba(22, 22, 22, 0.08) 100%
    );
    -webkit-filter: blur(6px);
    filter: blur(6px);
    -webkit-backdrop-filter: blur(64px);
    backdrop-filter: blur(64px);
  }
  &::after {
    right: 0px;
    left: initial;
    transform: translateX(50%);
    -webkit-mask-image: linear-gradient(
      -90deg,
      #161616 26%,
      rgba(22, 22, 22, 0.08) 100%
    );
    mask-image: linear-gradient(
      90deg,
      #161616 26%,
      rgba(22, 22, 22, 0.08) 100%
    );
  }
`;
