import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Fade } from "@mui/material";
import Link from "next/link";
import styled from "styled-components";

export const ListItem = ({ data, i }) => {
  const { label, title, url, svg } = data;
  return (
    <Fade in={true} timeout={700} style={{ transitionDelay: 30 * i }}>
      <div>
        {url ? (
          <ItemLink href={url} rel="noopener noreferrer">
            <Title>
              {svg && <SvgSelector svg={svg} />}
              <span>{title}</span>
            </Title>
            <Label>{label}</Label>
          </ItemLink>
        ) : (
          <ItemDisabled>
            <Title>{title}</Title>
            <Soon>скоро</Soon>
          </ItemDisabled>
        )}
      </div>
    </Fade>
  );
};
export const ListItemOpenCampus = ({ onClick }) => {
  return (
    <Fade in={true} timeout={700}>
      <div>
        <Item onClick={onClick} rel="noopener noreferrer">
          <Title>Учебный класс</Title>
          <LabelWr>
            <Label>Курсы и лекции</Label>
            <SvgSelector svg={"user-fonts-button-arrow"} />
          </LabelWr>
        </Item>
      </div>
    </Fade>
  );
};

const LabelWr = styled.div`
  display: flex;
  align-items: center;
  ${mixins.gap8}
`;
const Title = styled.span`
  color: var(--wh6);
  display: flex;
  align-items: center;
  ${mixins.gap8}
  &>svg {
    width: 16px;
    height: 16px;
    @media ${breakpoints.desktopXl} {
      width: 1.25vw;
      height: 1.25vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.56vw;
      height: 1.56vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.15vw;
      height: 2.15vw;
    }
    @media ${breakpoints.mobile} {
      width: 5.02vw;
      height: 5.02vw;
    }
  }
`;
const Label = styled.div`
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  text-align: right;
  color: var(--wh3);
`;

const Soon = styled.span`
  border: 0.8px solid var(--wh12);
  text-transform: uppercase;
  ${mixins.fs8}
  line-height: 100%;
  letter-spacing: 0.12em;
  color: var(--wh12);
  border-radius: 5px;
  padding: 3px 4px;
  @media ${breakpoints.desktopXl} {
    border-radius: 0.39vw;
    padding: 0.23vw 0.31vw;
    border-width: 0.06vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 0.49vw;
    padding: 0.29vw 0.39vw;
    border-width: 0.08vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 0.67vw;
    padding: 0.4vw 0.54vw;
    border-width: 0.11vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 1.57vw;
    padding: 0.94vw 1.25vw;
    border-width: 0.25vw;
  }
`;

const itemStyles = `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  @media ${breakpoints.desktopXl} {
    padding: 0.86vw 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.08vw 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.48vw 2.15vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.45vw 5.02vw;
  }

  ${fonts.artel4}
  ${mixins.fs12};
  line-height: 117%;
  letter-spacing: 0.02em;
  
`;

const Item = styled.li`
  ${itemStyles};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
  padding: 17px 16px;
  @media ${breakpoints.desktopXl} {
    padding: 1.33vw 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.66vw 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.29vw 2.15vw;
  }
  @media ${breakpoints.mobile} {
    padding: 5.33vw 5.02vw;
  }
`;
const ItemLink = styled(Link)`
  ${itemStyles};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
`;
const ItemDisabled = styled.li`
  ${itemStyles};
  pointer-events: none;
  ${Title} {
    color: var(--wh12);
  }
`;
