import { ACCESS } from "@/shared/constants/access";
import moment from "moment";
import { useSelector } from "react-redux";

export const hasSubscriptions = (value) => {
  return !!value && !!value?.subscriptions;
};

const isOverdue = (date_end) => {
  return moment() > moment(date_end);
};

const willEndSoon = (date_end) => {
  return moment(date_end).diff(moment(), "days") < 8;
};

export const useAccess = () => {
  const { data, loading, error } = useSelector((state) => state.user);

  if (error) return [ACCESS.UNKNOWN];
  if (loading) return [undefined];

  if (hasSubscriptions(data)) {
    const fonts = data.subscriptions.find(
      (subs) => subs.product === "foundation"
    );
    if (isOverdue(fonts?.date_end) || fonts === undefined) {
      return [ACCESS.OVERDUE];
    } else return [ACCESS.FULL, willEndSoon(fonts?.date_end)];
  }

  return [ACCESS.UNKNOWN];
};
