import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";

export const TypefaceSelect = ({ options, defaultoption = 0, setFont }) => {
  const fontStyles = options.map((option) => {
    return {
      value: option.name,
      label: option.name[0]?.toUpperCase() + option?.name.slice(1),
    };
  });
  const [open, setOpen] = useState(false);
  return (
    <>
      <SelectStyled
        openMenuOnFocus
        menuIsOpen={open}
        isdisabled={fontStyles.length < 2}
        single={fontStyles.length === 1}
        components={{ DropdownIndicator }}
        isSearchable={false}
        classNamePrefix="select-fonts"
        defaultValue={fontStyles[defaultoption]}
        options={fontStyles}
        onChange={(e) =>
          setFont(options.map((option) => option.name).indexOf(e.value))
        }
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: "transform 0.3s",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
            display: fontStyles.length < 2 ? "none" : "block",
          }),
        }}
      />
    </>
  );
};

const SelectStyled = styled(Select)`
  ${fonts.manrope5};
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 0.2px;
  height: 100%;
  background-color: var(--wh04);
  display: flex;
  align-items: center;
  transition: background-color 0.3s, border-radius 0.4s;
  z-index: 1;
  ${(props) =>
    props.menuIsOpen ? `border-radius: 8px 8px 0 0;` : `border-radius: 8px;`}

  &:hover,
  &:focus,
  &:active {
    background-color: var(--wh08);
  }

  @media ${breakpoints.mobile} {
    ${({ menuIsOpen }) =>
      menuIsOpen ? `border-radius: 8px 8px 0 0;` : `border-radius: 8px;`}
  }

  & > .select-fonts {
    .select-fonts__control__menu {
      border: 2px solid red;
    }
    &__control {
      ${({ single }) => `padding: ${single ? "0 9 %" : "0 6% 0 9%"};`}

      cursor: pointer;
      width: 100%;
      background-color: transparent;
      min-height: auto;
      border: none !important;
      box-shadow: none;
      height: 100%;
      & .select-fonts__value-container {
        padding: 0;
        height: 100%;

        & .select-fonts__single-value {
          height: 100%;
          color: var(--wh6);
          margin: 0;
          display: flex;
          align-items: center;
          ${({ single }) => single && `justify-content: center;`}
        }
      }
      & .select-fonts__indicators {
        & .select-fonts__indicator-separator {
          display: none;
        }
        & .select-fonts__dropdown-indicator {
          padding: 0;
          & > svg {
            @media ${breakpoints.mobile} {
              top: 25%;
              width: 4vw;
              height: 4vw;
              right: 5%;
            }
          }
        }
      }
    }
    &__menu {
      margin: 0;
      box-shadow: none;
      border-radius: 0px 0px 8px 8px;
      background-color: var(--wh04);
      -webkit-backdrop-filter: blur(7.5px);
      backdrop-filter: blur(7.5px);
      animation: fadeOut 0.2s ease-in-out;
      overflow: hidden;
      /* &::after,
      &::before {
        content: "";
        height: 4px;
        background: linear-gradient(
          180deg,
          #202525 0%,
          rgba(255, 255, 255, 0) 100%
        );
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 10;
      }
      &::before {
        background: linear-gradient(
          0deg,
          #302c25 0%,
          rgba(255, 255, 255, 0) 100%
        );
        top: initial;
        bottom: 0;
      } */

      & .select-fonts__menu-list {
        max-height: 112px;
        padding: 0;
        @media ${breakpoints.mobile} {
          max-height: 200px;
        }
      }
      & .select-fonts__option {
        padding: 0;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        ${fonts.manrope5}
        ${mixins.fs10};
        line-height: 120%;
        letter-spacing: 0.2px;
        transition: background-color 0.2s;
        background-color: var(--wh04);

        @media ${breakpoints.tabletL} {
          height: 2.6vw;
        }
        @media ${breakpoints.tabletM} {
          height: 3.5vw;
        }
        @media ${breakpoints.mobile} {
          height: 7vw;
        }
      }
      & .select-fonts__option--is-focused {
        background-color: var(--wh08);
        color: inherit;
      }
      & .select-fonts__option--is-selected {
        color: inherit;
        background-color: var(--wh2);
        pointer-events: none;
      }
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg {...props} width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M13.6791 5.44726L9.28005 9.8463C8.88953 10.2368 8.25636 10.2368 7.86584 9.8463L3.4668 5.44727"
        stroke="white"
        strokeOpacity="0.6"
        strokeWidth="1.2"
      />
    </svg>
  </components.DropdownIndicator>
);
