import api from "@/services/api";
import {
  fetchCardsError,
  fetchCardsLoading,
  fetchCardsSuccess,
} from "../fetchSlices/cardsSlice";

export const fetchCards = (
  offset = 0,
  index = 10,
  filterParams = [],
  isBookmarked = false,
  name = undefined
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCardsLoading(true));
      const res = await api.get("api/fonts/fonts/", {
        params: {
          limit: index,
          offset: offset * index,
          ...filterParams,
          is_bookmarked: isBookmarked ? true : undefined,
          name: name ? name : undefined,
        },
      });
      dispatch(fetchCardsLoading(false));

      if (res.status === 200) {
        dispatch(fetchCardsSuccess(res.data));
      } else {
        dispatch(fetchCardsError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchCardsError(error.message));
      console.log(error);
    }
  };
};
