import { ButtonWithLabel } from "@/shared/UI/ButtonWithLabel";
import { Pulsed } from "./components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { useRouter } from "next/router";
import { useAccess } from "@/hooks/useAccess";
import { isLibrary } from "@/shared/constants/routes";
import { isFull, isOverdue } from "@/shared/constants/access";

export const Heart = ({ setOpen, open }) => {
  const { pathname } = useRouter();
  const [access, willEndSoon] = useAccess();
  return (
    isLibrary(pathname) &&
    (isOverdue(access) || (isFull(access) && willEndSoon)) && (
      <ButtonWithLabel
        location="cl"
        handleClick={() => setOpen(true)}
        open={open}
        promo={false}
      >
        Подписка
      </ButtonWithLabel>
    )
  );
};

export const PulsedHeart = () => (
  <Pulsed>
    <SvgSelector svg={"heart"} />
  </Pulsed>
);
