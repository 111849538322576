import { breakpoints } from "@/styles/variables/variables";
import { Slide, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { ArtelButtonWithLabel } from "./ArtelButtonWithLabel";
import { TeamContent } from "./TeamContent";
import { TeamDialog } from "./components";

export const Team = () => {
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <>
      <ArtelButtonWithLabel setOpen={setOpen} />

      <TeamDialog
        fullScreen={isMobile}
        onClose={() => setOpen(false)}
        id="team-popup-menu"
        TransitionComponent={Slide}
        TransitionProps={{ direction: isMobile ? "up" : "left" }}
        open={open}
        scroll="paper"
      >
        <TeamContent handleClose={() => setOpen(false)} />
      </TeamDialog>
    </>
  );
};
