import { breakpoints } from "@/styles/variables/variables";
import { Slide, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { ArtelPlusButtonWithLabel } from "./ArtelPlusButtonWithLabel";
import { ArtelPlusContent } from "./ArtelPlusContent";
import { ArtelPlusDialog } from "./components";

export const ArtelPlus = () => {
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const handleClose = () => setOpen(false);

  return (
    <>
      <ArtelPlusButtonWithLabel setOpen={setOpen} />

      <ArtelPlusDialog
        fullScreen={isMobile}
        onClose={handleClose}
        id="artelplus-popup-menu"
        TransitionComponent={Slide}
        TransitionProps={{
          direction: isMobile ? "left" : "right",
        }}
        open={open}
        scroll="paper"
      >
        <ArtelPlusContent handleClose={handleClose} />
      </ArtelPlusDialog>
    </>
  );
};
