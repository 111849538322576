import { useState } from "react";
import { Box, Help, TabStyled, TabsStyled } from "./components";
import { TabContent } from "./TabContent";
import { Fade, useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";
import { helpLink } from "@/shared/constants/links";
import { isLibrary } from "@/shared/constants/routes";
import { useRouter } from 'next/router';

export const VALID_STATE = {
  neutral: 0,
  bad: 1,
  good: 2,
};

export const LoginTabs = () => {
  const { pathname } = useRouter();

  const [value, setValue] = useState(isLibrary(pathname) ? 1 : 0); //0 - логин, 1 - регистрация

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setValid(VALID_STATE.neutral);
  };
  const [shake1, setShake1] = useState(false);
  const [shake2, setShake2] = useState(false);

  const [valid, setValid] = useState(VALID_STATE.neutral);

  const animate1 = () => {
    setShake1(true);
    setTimeout(() => setShake1(false), 1000);
  };
  const animate2 = () => {
    setShake2(true);
    setTimeout(() => setShake2(false), 1000);
  };

  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <Box valid={valid}>
      <Fade in={true} timeout={700} style={{ transitionDelay: 70 }}>
        <TabsStyled
          selectionFollowsFocus
          value={value}
          allowScrollButtonsMobile
          orientation={"horizontal"}
          onChange={handleChange}
          valid={valid}
        >
          <TabStyled
            valid={valid}
            shake={shake1}
            onClick={animate1}
            label="Вход"
            {...a11yProps(0)}
          />
          <TabStyled
            valid={valid}
            shake={shake2}
            onClick={animate2}
            label="Регистрация"
            {...a11yProps(1)}
          />
          {}
          {isMobile || (
            <Help
              valid={valid}
              href={helpLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Помощь
            </Help>
          )}
        </TabsStyled>
      </Fade>
      <TabPanel value={value} index={0}>
        <TabContent valid={valid} setValid={setValid} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabContent valid={valid} setValid={setValid} isRegister={true} />
      </TabPanel>
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};
