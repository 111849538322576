import { breakpoints } from "@/styles/variables/variables";
import styled from "styled-components";

const regexp = new RegExp("^(>|<)");

export const TextParseSpacing = ({ text }) => {
  return text.split(/([>|<]+)/g).map((elem, i, arr) => {
    if (i < arr.length - 1 && regexp.test(arr[i + 1])) {
      return (
        <SpanWithMargin
          key={i}
          mr={
            arr[i + 1].startsWith(">") ? arr[i + 1].length : -arr[i + 1].length
          }
        >
          {elem}
        </SpanWithMargin>
      );
    } else if (!regexp.test(elem)) {
      return <span key={i}>{elem}</span>;
    }
  });
};

const SpanWithMargin = styled.span`
  ${({ mr }) => `
  margin-right: ${mr}px;
  @media ${breakpoints.desktopXl} {
    margin-right: ${mr * 0.078}vw;
  }
  @media ${breakpoints.tabletL} {
    margin-right: ${mr * 0.098}vw;
  }
  @media ${breakpoints.tabletM} {
    margin-right: ${mr * 0.1346}vw;
  }
  @media ${breakpoints.mobile} {
    margin-right: ${mr * 0.31348}vw;
  }
  `}
`;
