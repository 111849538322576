import { createSlice } from "@reduxjs/toolkit";

const alertRetrySlice = createSlice({
  name: "alertRetry",
  initialState: {
    open: false,
    retryTime: undefined,
  },
  reducers: {
    setOpenAlertRetry: (state, action) => {
      state.open = action.payload;
    },
    setRetryTime: (state, action) => {
      state.retryTime = Number(action.payload);
    },
  },
});

export const alertRetryReducer = alertRetrySlice.reducer;
export const { setOpenAlertRetry, setRetryTime } = alertRetrySlice.actions;
