import { breakpoints } from "@/styles/variables/variables";
import { DialogContent, Fade, useMediaQuery } from "@mui/material";
import {
  BoxP,
  Footer,
  HeadP,
  Header,
  Main,
  Price,
  SectionP,
  WrFooter,
} from "./components";
import {
  Artel,
  CloseButton,
  Help,
  LoginLabel,
} from "@/components/header/loginTabs/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { Avatar } from "@/components/header/AuthorizedUserDropdown";
import { Label as LabelPay } from "@/components/header/userContent/settings/components";
import { Label } from "@/components/promo/components";
import { helpLink } from "@/shared/constants/links";
import { PayForm } from "./PayForm";
import { useSelector } from "react-redux";
import { PromoButton } from "./promocode/PromoButton";
import { useState } from "react";
import { Back, PromoContent } from "./promocode/PromoContent";

export const PayContent = ({ handleClose }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  const { avatar } = useSelector((state) => state.user.data);

  const [openPromocode, setOpenPromocode] = useState(false);

  const handleBack = () => setOpenPromocode(false);

  return (
    <BoxP>
      {isMobile && (
        <HeadP>
          <Artel>
            <SvgSelector svg={"logo-artel"} />
          </Artel>
          <CloseButton onClick={handleClose}>
            <SvgSelector svg={"close-popup"} />
          </CloseButton>
        </HeadP>
      )}
      <DialogContent scroll="paper">
        <SectionP>
          <Fade in={true} timeout={700} style={{ transitionDelay: 50 }}>
            <Header>
              <Avatar avatar={avatar ?? "/assets/avatar.jpg"} />
              <div>
                <LabelPay>подписка</LabelPay>
                <Label>Доступ к Шрифтотеке</Label>
              </div>
              {isMobile || (
                <Help href={helpLink} target="_blank" rel="noopener noreferrer">
                  Помощь
                </Help>
              )}
            </Header>
          </Fade>
          {openPromocode ? (
            <PromoContent handleClose={handleClose} handleBack={handleBack} />
          ) : (
            <>
              <Fade in={true} timeout={700} style={{ transitionDelay: 100 }}>
                <Main>
                  <Price>
                    <span>
                      -<span>990</span>₽
                    </span>
                    <SvgSelector svg={"pay-card"} />
                  </Price>
                  <LoginLabel>Продлить доступ к сайту на 1 год</LoginLabel>
                </Main>
              </Fade>
              <Fade in={true} timeout={700} style={{ transitionDelay: 150 }}>
                <Footer>
                  <PayForm />
                  <p>
                    <span>Нажав на кнопку, вы перейдёте на сервис оплаты</span>
                    <SvgSelector svg={"umoney"} />
                  </p>
                </Footer>
              </Fade>
              <PromoButton onClick={() => setOpenPromocode(true)} />
            </>
          )}
          {isMobile && (
            <Fade in={true} timeout={700} style={{ transitionDelay: 200 }}>
              <WrFooter>
                {openPromocode && (
                  <Back onClick={handleBack}>Вернуться к оплате</Back>
                )}
                <Help href={helpLink} target="_blank" rel="noopener noreferrer">
                  Помощь
                </Help>
              </WrFooter>
            </Fade>
          )}
        </SectionP>
      </DialogContent>
    </BoxP>
  );
};
