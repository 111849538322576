import api from "@/services/api";
import { ROUTES } from "@/shared/constants/routes";
import { useEffect, useRef, useState } from "react";
import { ButtonAccept } from "../disclaimer/components";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";
import SvgSelector from "@/shared/UI/SvgSelector";

export const PayForm = () => {
  const [order, setOrder] = useState(null);
  const form = useRef(null);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const handlePayment = () => {
    api
      .post("/api/create-order/", {
        product: "foundation",
      })
      .then(
        (res) => {
          if (res.status === 201) setOrder(res.data);
        },
        (error) => {
          console.log("произошла ошибка оплаты: ", error);
        }
      );
  };

  useEffect(() => {
    if (order) {
      form.current.submit();
    }
  }, [order]);

  return (
    <>
      <form
        ref={form}
        id="payment"
        method="POST"
        action="https://yoomoney.ru/quickpay/confirm.xml"
      >
        <input type="hidden" name="receiver" value="410016040384130" />
        <input type="hidden" name="quickpay-form" value="donate" />
        <input type="hidden" name="targets" value="Бесплатная кириллица" />
        <input
          type="hidden"
          name="label"
          value={order?.id}
          data-type="number"
        />
        <input
          type="hidden"
          name="sum"
          value={order?.price}
          data-type="number"
        />
        {/* <input type="hidden" name="comment" value={data.email} /> */}
        <input type="hidden" name="need-fio" value="false" />
        <input type="hidden" name="need-email" value="false" />
        <input type="hidden" name="need-phone" value="false" />
        <input type="hidden" name="need-address" value="false" />
        <input
          type="hidden"
          name="successURL"
          value={`https://artpavlushin.ru${ROUTES.THANKS}`}
        />
      </form>
      <ButtonAccept type="button" form="payment" onClick={handlePayment}>
        <SvgSelector svg={"button-accept-before"} />
        {isMobile ? (
          <SvgSelector svg={"button-accept-mobile"} />
        ) : (
          <SvgSelector svg={"button-accept"} />
        )}
        <span>Оплатить</span>
      </ButtonAccept>
    </>
  );
};
