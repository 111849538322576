import { breakpoints } from "@/styles/variables/variables";
import { Fade, Slide, Zoom, useMediaQuery } from "@mui/material";
import SvgSelector from "@/shared/UI/SvgSelector";
import { DialogSt } from "../../UserDropdown";
import {
  Artel,
  CloseButton,
  Help,
  PopupHeader,
} from "../../loginTabs/components";
import { useState } from "react";
import { VALID_STATE } from "../../loginTabs/LoginTabs";
import { TabContent } from "../../loginTabs/TabContent";
import { BoxEmail } from "./components";
import { helpLink } from "@/shared/constants/links";

export const EmailPopup = ({ open, setOpen }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <DialogSt
      fullScreen={isMobile}
      onClose={() => setOpen(false)}
      id="bind-email-menu"
      TransitionComponent={isMobile ? Slide : Zoom}
      TransitionProps={{ direction: "up" }}
      open={open}
    >
      {isMobile && (
        <Fade in={true} timeout={700}>
          <PopupHeader>
            <Artel>
              <SvgSelector svg={"logo-artel"} />
            </Artel>
            <CloseButton onClick={() => setOpen(false)}>
              <SvgSelector svg={"close-popup"} />
            </CloseButton>
          </PopupHeader>
        </Fade>
      )}
      <EmailBind />
    </DialogSt>
  );
};

const EmailBind = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  const [valid, setValid] = useState(VALID_STATE.neutral);

  return (
    <BoxEmail valid={valid}>
      <div>
        <span>Привязка почты</span>
        {isMobile || (
          <Help
            valid={valid}
            href={helpLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Помощь
          </Help>
        )}
      </div>
      <TabContent bind={true} valid={valid} setValid={setValid} />
    </BoxEmail>
  );
};
