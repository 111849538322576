import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Fade, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Title, Wrap8 } from "./components";
import Link from "next/link";
import { useState } from "react";

const storageKey = "tg-button";

export const News = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  const [active, setActive] = useState(
    localStorage.getItem(storageKey) ?? "true"
  );

  return (
    <Fade in={true} timeout={700}>
      <Wrap8>
        <Title>новости и обновления</Title>
        <Button
          href={"https://t.me/artpavlushin"}
          target="_blank"
          active={active}
          onClick={() => {
            setActive("false");
            localStorage.setItem(storageKey, "false");
          }}
        >
          <SvgSelector svg={"button-accept-before"} />
          {isMobile ? (
            <SvgSelector svg={"button-accept-mobile"} />
          ) : (
            <SvgSelector svg={"button-accept"} />
          )}
          <div>
            <SvgSelector svg={"social-telegram"} />
            <span>ТГ канал</span>
          </div>
        </Button>
      </Wrap8>
    </Fade>
  );
};

const Button = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${fonts.artel4}
  ${mixins.fs14}
  line-height: 114%;
  letter-spacing: 0.01em;
  transition: color 0.3s;
  color: var(--wh7);
  & > div {
    z-index: 1;
    display: flex;
    align-items: center;
    ${mixins.gap4}
    & > svg {
      width: 22px;
      height: 22px;
      @media ${breakpoints.desktopXl} {
        width: 1.72vw;
        height: 1.72vw;
      }
      @media ${breakpoints.tabletL} {
        width: 2.15vw;
        height: 2.15vw;
      }
      @media ${breakpoints.tabletM} {
        width: 2.96vw;
        height: 2.96vw;
      }
      @media ${breakpoints.mobile} {
        width: 6.9vw;
        height: 6.9vw;
      }
    }
  }
  margin-bottom: 12px;
  height: 48px;
  @media ${breakpoints.desktopXl} {
    height: 3.75vw;
    margin-bottom: 0.94vw;
  }
  @media ${breakpoints.tabletL} {
    height: 4.69vw;
    margin-bottom: 1.17vw;
  }
  @media ${breakpoints.tabletM} {
    height: 6.46vw;
    margin-bottom: 1.62vw;
  }
  @media ${breakpoints.mobile} {
    height: 15.05vw;
    margin-bottom: 0;
  }

  position: relative;
  & > svg {
    position: absolute;
    &:first-of-type {
      pointer-events: none;
      width: 120%;
      height: 200%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: none;
      max-height: none;
      transition: opacity 0.3s;
    }
    &:last-of-type {
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      & path {
        fill: #ff3496;
      }
    }
  }

  & > svg:first-of-type {
    opacity: 0.8;
  }

  &:hover {
    & > svg:first-of-type {
      opacity: 1;
    }
  }
  &:active {
    & > svg:first-of-type {
      opacity: 0.8;
    }
  }
  ${({ active }) =>
    active === "false" &&
    `
      &>svg {
        display: none;
      }
      &>div path{
        fill-opacity: 0.4;
      }
      color: var(--wh5);
      border-radius: 16px;
      @media ${breakpoints.desktopXl} {
        border-radius: 1.25vw;
      }
      @media ${breakpoints.tabletL} {
        border-radius: 1.56vw;
      }
      @media ${breakpoints.tabletM} {
        border-radius: 2.15vw;
      }
      @media ${breakpoints.mobile} {
        border-radius: 5.02vw;
      }
      transition: background-color 0.3s;
      background: var(--wh02);
      &:hover {
        background: var(--wh04);
      }
      &:active {
        background: var(--wh08);
      }
      `}
`;
