export const ROUTES = {
  PROMO: "/fonts",
  LIBRARY: "/fonts/library",
  THANKS: "/fonts/thanks",
  HANDBOOK: "/handbook",
  FOUNDATION: "/artpreparation",
  CAMPUS: "/campus",
  CAMPUS_GRAPHIC: "/campus/graphic-metaphors",
  CAMPUS_CLASSROOM_GRAPHIC: "/campus/classroom/graphic-metaphors",
};
export const isNotPromo = (page) => page !== ROUTES.PROMO;
export const isPromo = (page) => page === ROUTES.PROMO;
export const isLibrary = (page) => page === ROUTES.LIBRARY;
export const isThanks = (page) => page === ROUTES.THANKS;
export const isHandbook = (page) => page === ROUTES.HANDBOOK;
export const isHandbookSlug = (page) => page.includes(ROUTES.HANDBOOK);
export const isOnlyHandbookSlug = (page) =>
  isHandbookSlug(page) && !isHandbook(page);

export const isFoundation = (page) => page === ROUTES.FOUNDATION;
export const isFoundationSlug = (page) => page.includes(ROUTES.FOUNDATION);
export const isOnlyFoundationSlug = (page) =>
  isFoundationSlug(page) && !isFoundation(page);

export const isCampus = (page) => page === ROUTES.CAMPUS;
export const isCampusSlug = (page) => page.includes(ROUTES.CAMPUS);
export const isOnlyCampusSlug = (page) => isCampusSlug(page) && !isCampus(page);
