import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

export const ArtelPlusButtonWithLabel = ({ setOpen }) => {
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <>
      <Button onClick={handleClick}>
        <ButtonArtel
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <SvgSelector svg={`artel-plus-button${isMobile ? "-mobile" : ""}`} />
        </ButtonArtel>
        {isMobile || <Label show={hovered}>Артель+</Label>}
      </Button>
    </>
  );
};

const ButtonArtel = styled.button`
  & path {
    transition: fill-opacity 0.3s;
  }
  &:hover {
    & path {
      fill-opacity: 0.5;
    }
  }
  &:active {
    & path {
      fill-opacity: 0.4;
    }
  }

  & > svg {
    width: 32px;
    height: 32px;
    @media ${breakpoints.desktopXl} {
      width: 2.5vw;
      height: 2.5vw;
    }
    @media ${breakpoints.tabletL} {
      width: 3.13vw;
      height: 3.13vw;
    }
    @media ${breakpoints.tabletM} {
      width: 4.31vw;
      height: 4.31vw;
    }
    @media ${breakpoints.mobile} {
      width: 18.81vw;
      height: 57.99vw;
    }
  }
`;

const Label = styled.span`
  left: 120%;
  order: -1;
  transform: translateX(-10px);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.5s, background-color 0.3s;
  ${mixins.fs12};
  line-height: 120%;
  letter-spacing: 0.24px;
  border-radius: 21px;
  background-color: var(--wh04);
  padding: 5px 12px;
  color: var(--wh6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: absolute;
  ${({ show }) => show && `opacity: 1; transform: translateX(0) !important; `};
`;

const Button = styled.div`
  position: fixed;
  bottom: 28px;
  left: 20px;
  @media ${breakpoints.desktopXl} {
    bottom: 28px;
    left: 20px;
  }
  @media ${breakpoints.tabletL} {
    bottom: 28px;
    left: 20px;
  }
  @media ${breakpoints.tabletM} {
    bottom: 28px;
    left: 20px;
  }
  @media ${breakpoints.mobile} {
    bottom: 50%;
    left: initial;
    right: 0;
    transform: translate(25%, 50%);
  }

  z-index: 1301;
  display: flex;
  ${mixins.gap8}
  align-items: center;
  transition: opacity 0.3s;
`;
