import { breakpoints, mixins } from "@/styles/variables/variables";
import { Fade, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { ButtonAccept } from "../../disclaimer/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { Footer, Main } from "../components";
import { PromoInput } from "./PromoInput";
import { useState } from "react";

export const PromoContent = ({ handleBack, handleClose }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(false);

  return (
    <>
      <Fade in={true} timeout={700} style={{ transitionDelay: 100 }}>
        <Main>
          <PromoInput
            success={success}
            setSuccess={setSuccess}
            setDisabled={setDisabled}
          />
        </Main>
      </Fade>
      <Fade in={true} timeout={700} style={{ transitionDelay: 150 }}>
        <Footer>
          <ButtonAccept
            disabled={disabled}
            onClick={() => {
              if (success) handleClose();
            }}
            type={success ? "button" : "submit"}
            form="promocode"
          >
            <SvgSelector svg={"button-accept-before"} />
            <SvgSelector svg={`button-accept${isMobile ? "-mobile" : ""}`} />
            <span>{success ? "Мур мяу" : "Применить"}</span>
          </ButtonAccept>
        </Footer>
      </Fade>
      {isMobile || <Back onClick={handleBack}>Вернуться к оплате</Back>}
    </>
  );
};

export const Back = styled.button`
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  padding: 0.28vw 0.56vw;
  @media ${breakpoints.desktopXl} {
    padding: 0.31vw 0.63vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.39vw 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.54vw 1.08vw;
  }
  @media ${breakpoints.mobile} {
    padding: 1.25vw 2.51vw;
    margin-top: 13.793vw;
  }
  transition: color 0.3s;
  color: var(--wh3);
  &:hover {
    color: var(--wh6);
  }
  &:active {
    color: var(--wh3);
  }
`;
