import { getCurrentState } from '@/services/utils';
import { createSlice } from "@reduxjs/toolkit";

const cardsSlice = createSlice({
  name: "cards",
  initialState: {
    data: [],
    count: 0,
    loading: false,
    error: "",
    activeCard: undefined,
    isBookmarked: false,
  },
  reducers: {
    fetchCardsSuccess(state, action) {
      state.data = [...getCurrentState(state.data), ...action.payload.results];
      state.count = action.payload.count;
    },
    fetchCardsLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCardsError(state, action) {
      state.error = action.payload;
    },
    setActiveCard(state, action) {
      state.activeCard = action.payload;
    },
    setCardBookmarkedById(state, action) {
      const dataChangedByBookmark = getCurrentState(state.data);
      state.data = dataChangedByBookmark.map((card) => {
        const bookmarked = card.is_bookmarked;
        return card.id === action.payload.id
          ? { ...card, is_bookmarked: !bookmarked }
          : card;
      });
      if (action.payload.dialog) {
        const newActiveCardBookmark = state.activeCard;
        const activeBookmarked = newActiveCardBookmark.is_bookmarked;
        state.activeCard = {
          ...newActiveCardBookmark,
          is_bookmarked: !activeBookmarked,
        };
      }
    },
    resetCardsToEmpty(state) {
      state.data = [];
    },
    showBookmarked(state, action) {
      state.isBookmarked = action.payload;
    },
  },
});

export const cardsReducer = cardsSlice.reducer;
export const {
  fetchCardsSuccess,
  fetchCardsLoading,
  fetchCardsError,
  setActiveCard,
  setCardBookmarkedById,
  resetCardsToEmpty,
  showBookmarked,
} = cardsSlice.actions;
