import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Dialog } from "@mui/material";
import styled from "styled-components";
import { Box, Help } from "../../loginTabs/components";
import { VALID_STATE } from "../../loginTabs/LoginTabs";

export const Desc = styled.p`
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
`;

export const Label = styled.span`
  color: var(--wh3);
  ${fonts.manrope7}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
export const LabelInput = styled.span`
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  padding-left: 8px;
`;

export const HeadSettings = styled.nav`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-items: center;
  margin-bottom: 24px;
  width: 100%;
  padding: 28px 24px 0;

  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.875vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.34vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.2vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 0;
    grid-template-columns: 1fr 2fr;
    width: 65.2vw;
    padding: 0;
  }
`;

export const Back = styled.button`
  justify-self: start;
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  transition: color 0.3s;
  & > svg {
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.9375vw;
      height: 0.9375vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.17vw;
      height: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.6vw;
      height: 1.6vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.75vw;
      height: 3.75vw;
    }
  }
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    color: var(--wh6);
    & path {
      stroke-opacity: 0.6;
    }
  }
  &:active {
    color: var(--wh3);
    & path {
      stroke-opacity: 0.3;
    }
  }
`;

export const TitleSettings = styled.div`
  display: flex;
  align-items: center;
  ${mixins.gap8}
  color: var(--wh3);
  ${fonts.manrope6}
  ${mixins.fs16}
  line-height: 100%;
  letter-spacing: -0.16px;
  & > svg {
    width: 20px;
    height: 20px;
    max-width: auto;
    max-height: auto;
    @media ${breakpoints.desktopXl} {
      width: 1.56vw;
      height: 1.56vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.95vw;
      height: 1.95vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.68vw;
      height: 2.68vw;
    }
    @media ${breakpoints.mobile} {
      width: 6.25vw;
      height: 6.25vw;
    }
  }
  @media ${breakpoints.mobile} {
    justify-self: end;
  }
`;

export const Main = styled.main`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;
  @media ${breakpoints.desktopXl} {
    gap: 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.95vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.68vw;
  }
  @media ${breakpoints.mobile} {
    gap: 6.25vw;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

// --- NAME

export const NameBlock = styled.div`
  display: flex;
  width: 83%;
  flex-direction: column;
  ${mixins.gap8}
  ${Label}, ${Desc} {
    padding-left: 8px;
  }
  @media ${breakpoints.mobile} {
    width: 80%;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  overflow-y: visible;
`;

export const InputName = styled.input`
  display: flex;
  width: 100%;
  padding: 11px 40px 15px 16px;
  flex-shrink: 0;
  border-radius: 12px;
  ${fonts.manrope5}
  ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.24px;
  background-color: var(--wh02);
  transition: background-color 0.3s, color 0.3s;
  text-overflow: ellipsis;
  color: var(--wh6);

  @media ${breakpoints.desktopXl} {
    padding: 0.86vw 3.1vw 1.17vw 1.17vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.075vw 3.9vw 1.47vw 1.47vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.48vw 5.38vw 2.018vw 2.018vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.45vw 12.54vw 4.7vw 4.7vw;
  }

  &::placeholder {
    transition: color 0.3s;
    color: var(--wh6);
  }
  &:hover {
    background-color: var(--wh04);
  }
  &:focus {
    &::placeholder {
      color: var(--wh12);
    }
  }
`;

export const ButtonSend = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  transition: opacity 0.3s;
  &:disabled {
    pointer-events: none;
    opacity: 0;
  }
  & > svg {
    width: 30%;
    height: 30%;
  }

  & path {
    transition: fill-opacity 0.3s;
  }
  &:hover {
    & path {
      fill-opacity: 0.3;
    }
  }
  &:active {
    & path {
      fill-opacity: 0.12;
    }
  }
  @media ${breakpoints.desktopXl} {
    width: 3.125vw;
  }
  @media ${breakpoints.tabletL} {
    width: 3.9vw;
  }
  @media ${breakpoints.tabletM} {
    width: 5.376vw;
  }
  @media ${breakpoints.mobile} {
    width: 12.5vw;
  }
`;

// --- AVA

export const AvaBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @media ${breakpoints.desktopXl} {
    gap: 0.74vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 0.92vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 1.32vw;
  }
  @media ${breakpoints.mobile} {
    gap: 5.016vw;
  }
`;

export const Avatar = styled.div`
  flex-shrink: 0;
  z-index: 9;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  box-shadow: 0px 0px 9px 0px #fff, 2px 2px 6px 0px rgba(95, 255, 140, 0.4),
    -2px -2px 7px 0px rgba(255, 98, 56, 0.44), 0px 0px 2px 0px #fff;
  background-repeat: no-repeat;
  background-size: cover;
  transition: filter 0.3s;
  ${({ avatar }) =>
    avatar &&
    `
    background-image: url(${avatar});
    `}

  @media ${breakpoints.desktopXl} {
    width: 2.8vw;
    height: 2.8vw;
  }
  @media ${breakpoints.tabletL} {
    width: 3.5vw;
    height: 3.5vw;
  }

  @media ${breakpoints.tabletM} {
    width: 4.8vw;
    height: 4.8vw;
  }

  @media ${breakpoints.mobile} {
    width: 11.25vw;
    height: 11.25vw;
  }
`;
export const FileWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:after {
    z-index: 10;
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 100%;
    background-color: rgba(20, 20, 20, 0.4);
    opacity: 0;
    transition: opacity 0.3s;
  }

  & > svg {
    width: 50%;
    z-index: 11;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
  }
  &:hover {
    & > ${Avatar} {
      filter: blur(2px);
      /* backdrop-filter: blur(1px); */
    }
    & > svg {
      opacity: 1;
    }
    &::after {
      opacity: 1;
    }
  }
  &:active {
    & > svg {
      transform: rotate(90deg);
    }
  }
`;

export const Delete = styled(Desc)`
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--wh6);
  }
  &:active {
    color: var(--wh3);
  }
  ${({ disabled }) =>
    disabled &&
    `pointer-events: none;
    color: var(--wh12);`}
`;

// -- ACCOUNTS

export const Accounts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mixins.gap8}

  ${Label}, ${Desc} {
    padding-left: 8px;
  }
`;
export const Social = styled.ul`
  border-radius: 16px;
  background-color: var(--wh02);
  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  @media ${breakpoints.desktopXl} {
    padding: 1.173vw 1.251vw;
    gap: 1.016vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.466vw 1.564vw;
    gap: 1.271vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.019vw 2.153vw;
    gap: 1.75vw;
  }
  @media ${breakpoints.mobile} {
    padding: 4.702vw 5.016vw;
    gap: 4.075vw;
  }
`;
export const Row = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  ${mixins.gap8}
  ${({ active }) => `opacity: ${active ? 0.6 : 0.3};`};
  max-width: 75%;

  & > span {
    color: #fff;
    ${fonts.manrope5}
    ${mixins.fs12}
    line-height: normal;
    letter-spacing: 0.24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    @media ${breakpoints.desktopXl} {
      width: 1.25vw;
      height: 1.25vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.56vw;
      height: 1.56vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.15vw;
      height: 2.15vw;
    }
    @media ${breakpoints.mobile} {
      width: 5vw;
      height: 5vw;
    }
  }
  @media ${breakpoints.mobile} {
    max-width: 72%;
  }
`;
export const Bind = styled.button`
  color: var(--wh3);
  text-align: right;
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  transition: color 0.3s;
  &:hover {
    color: var(--wh6);
  }
  &:active {
    color: var(--wh3);
  }
`;

// -- DANGER

export const Danger = styled.div`
  ${mixins.gap8}
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Label}, ${Desc} {
    padding-left: 8px;
  }
  @media ${breakpoints.mobile} {
    ${Desc} {
      min-height: 14.73vw;
    }
  }
`;

export const DeleteButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${mixins.gap8}
`;
export const DeleteButton = styled.button`
  text-align: center;
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.24px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--wh08);
  transition: background-color 0.3s;
  padding: 17px 0;
  @media ${breakpoints.desktopXl} {
    padding: 1.328vw 0;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.66vw 0;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.28vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 5.31vw 0;
  }

  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
`;

// ALERT

export const DialogAlert = styled(Dialog)`
  z-index: 1303 !important;
  & .MuiPaper-root {
    margin: 0;
    padding: 28px 32px 32px 24px;
    overflow-y: visible;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    border: 1px solid #23150d;
    border-radius: 32px;
    background-color: var(--orange04);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.32);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    position: relative;
    @media ${breakpoints.mobile} {
      gap: 2.5vw;
      padding: 22px 25px 25px 19px;
    }
    &::after {
      content: "";
      top: 0;
      height: 100%;
      left: 0;
      position: absolute;
      background-color: var(--orange02);
      animation: showBg 5s linear 1 forwards;
      border-radius: 32px 0 0 32px;
    }
    & span {
      color: var(--orange9);
      ${fonts.manrope5}
      ${mixins.fs14}
      line-height: 114%;
      letter-spacing: 0.28px;
      @media ${breakpoints.mobile} {
        font-size: 3.1vw;
      }
    }
  }

  & > .MuiBackdrop-root {
    background-color: transparent;
  }
  @keyframes showBg {
    0% {
      right: 100%;
    }
    100% {
      right: 0;
    }
  }
`;

// IMAIL INPUT

export const BoxEmail = styled(Box)`
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media ${breakpoints.mobile} {
      justify-content: center;
    }

    & > span {
      ${fonts.manrope7}
      ${mixins.fs20}
    letter-spacing: -0.2px;
      transition: color 0.2s, text-shadow 0.3s;
      overflow: visible;

      ${({ valid }) => {
        switch (valid) {
          case VALID_STATE.neutral:
            return `
          color: var(--wh9);
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
      `;
          case VALID_STATE.bad:
            return `
          color: var(--orange9);
      text-shadow: 0px 0px 32px rgba(231, 127, 69, 0.60);
      `;
          case VALID_STATE.good:
            return `
      color: var(--green9);
      text-shadow: 0px 0px 32px var(--green6);
      `;
        }
      }}
    }
  }
  & form {
    width: 100%;
  }
  @media ${breakpoints.mobile} {
    & ${Help} {
      margin: 0 auto;
    }
  }
`;
