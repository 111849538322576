import { createSlice } from "@reduxjs/toolkit";

const hbCardSlice = createSlice({
  name: "hbCard",
  initialState: {
    data: undefined,
    loading: false,
    error: "",
  },
  reducers: {
    fetchHbCardSuccess(state, action) {
      state.data = action.payload;
    },
    fetchHbCardLoading(state, action) {
      state.loading = action.payload;
    },
    fetchHbCardError(state, action) {
      state.error = action.payload;
    },
  },
});

export const hbCardReducer = hbCardSlice.reducer;
export const { fetchHbCardSuccess, fetchHbCardLoading, fetchHbCardError } =
  hbCardSlice.actions;
