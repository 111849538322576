import { UserDropdown } from "./UserDropdown";
import styled from "styled-components";
import { AuthorizedUserDropdown } from "./AuthorizedUserDropdown";
import { breakpoints } from "@/styles/variables/variables";
import SvgSelector from "@/shared/UI/SvgSelector";
import { useRouter } from "next/router";
import { useAccess } from "@/hooks/useAccess";
import { isUnknown } from "@/shared/constants/access";
import {
  ROUTES,
  isNotPromo,
  isOnlyFoundationSlug,
  isOnlyHandbookSlug,
} from "@/shared/constants/routes";
import Link from "next/link";

export const Header = ({ logoSvg }) => {
  const { pathname } = useRouter();

  const [access] = useAccess();

  return (
    <HeaderStyled
      data-aos={isNotPromo(pathname) ? "" : "fade"}
      data-aos-delay="800"
    >
      {/* <LeftDropdown /> */}
      {isOnlyHandbookSlug(pathname) ? (
        <LinkLogo href={ROUTES.HANDBOOK}>
          <SvgSelector svg={logoSvg} />
        </LinkLogo>
      ) : isOnlyFoundationSlug(pathname) ? (
        <LinkLogo href={ROUTES.FOUNDATION}>
          <SvgSelector svg={logoSvg} />
        </LinkLogo>
      ) : (
        <SvgSelector svg={logoSvg} />
      )}

      {isUnknown(access) ? (
        <UserDropdown isNotPromo={isNotPromo(pathname)} />
      ) : (
        <AuthorizedUserDropdown />
      )}
    </HeaderStyled>
  );
};

const LinkLogo = styled(Link)`
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
  & > svg {
    @media ${breakpoints.desktopXl} {
      height: 3.2vw;
      width: auto;
    }
    @media ${breakpoints.tabletL} {
      height: 4vw;
    }
    @media ${breakpoints.tabletM} {
      height: 5.5vw;
    }
    @media ${breakpoints.mobile} {
      height: 10.031vw;
      position: relative;
      z-index: 10;
    }
  }
`;

const HeaderStyled = styled.header`
  padding-top: 24px;
  text-align: center;
  position: relative;
  @media ${breakpoints.mobile} {
    padding-top: 5.016vw;
  }
  & > svg {
    @media ${breakpoints.desktopXl} {
      height: 3.2vw;
      width: auto;
    }
    @media ${breakpoints.tabletL} {
      height: 4vw;
    }
    @media ${breakpoints.tabletM} {
      height: 5.5vw;
    }
    @media ${breakpoints.mobile} {
      height: 10.031vw;
      position: relative;
      z-index: 10;
    }
  }
`;
