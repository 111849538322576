import SvgSelector from "@/shared/UI/SvgSelector";
import {
  Accounts,
  AvaBlock,
  Avatar,
  Back,
  ButtonSend,
  Danger,
  Delete,
  DeleteButton,
  DeleteButtonWrapper,
  Desc,
  DialogAlert,
  FileWrapper,
  HeadSettings,
  Info,
  InputName,
  InputWrapper,
  Label,
  LabelInput,
  Main,
  NameBlock,
  TitleSettings,
} from "./components";
import { useCallback, useEffect, useState } from "react";
import { DialogContent, Fade, Slide, Zoom } from "@mui/material";
import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import api from "@/services/api";
import { useDispatch, useSelector } from "react-redux";
import { setUserName } from "@/redux/fetchSlices/userSlice";
import { SocialConnect } from "./SocialConnect";
import { fetchUser } from "@/redux/thunks/fetchUser";

export const Settings = ({ user }) => {
  return (
    <DialogContent scroll="paper">
      <Main>
        <Fade in={true} timeout={500} style={{ transitionDelay: 30 }}>
          <Info>
            <NameBlock>
              <Label>Имя</Label>
              <Form userName={user.display_name} />
            </NameBlock>
            <AvaBlock>
              <Label>Ава</Label>
              <AvaInput />
            </AvaBlock>
          </Info>
        </Fade>
        <Fade in={true} timeout={500} style={{ transitionDelay: 70 }}>
          <Accounts>
            <Label>учётные записи</Label>
            <SocialConnect />
            <Desc>
              Для входа вы можете использовать любую <br /> из этих учетных
              записей
            </Desc>
          </Accounts>
        </Fade>
        <Fade in={true} timeout={500} style={{ transitionDelay: 100 }}>
          <Danger>
            <Label>Опасная зона</Label>
            <DeleteAccount />
          </Danger>
        </Fade>
      </Main>
    </DialogContent>
  );
};

// --- FORM

const Form = ({ userName }) => {
  const [label, setLabel] = useState(true);
  const [hideButton, setHideButton] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setHideButton(true);

    const formProps = Object.fromEntries(new FormData(e.target));
    api.patch(`/api/me/`, formProps).then(
      () => {
        dispatch(setUserName(formProps.display_name));
        e.target[0].blur();
        e.target.reset();
      },
      (error) => {
        console.log("произошла ошибка изменения имени: ", error);
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputName
            onChange={(e) => setHideButton(!Boolean(e.target.value))}
            maxLength={30}
            required
            name="display_name"
            placeholder={
              userName === undefined || userName?.trim().length === 0
                ? "Нобель Файк"
                : userName
            }
          />
          <ButtonSend
            type="submit"
            disabled={hideButton}
            onMouseOver={() => setLabel(false)}
            onMouseOut={() => setLabel(true)}
          >
            <SvgSelector svg={"tick"} />
          </ButtonSend>
        </InputWrapper>
      </form>
      <HoverTwoSpan active={label}>
        <LabelInput>Это имя будет отображаться в вашем аккаунте</LabelInput>
        <LabelInput>Сохранить изменения</LabelInput>
      </HoverTwoSpan>
    </>
  );
};

// --- AVA FILE

const maxSize = 1048576; //1Mb
const AvaInput = () => {
  const dispatch = useDispatch();
  const { avatar } = useSelector((state) => state.user.data);
  const [showAlert, setShowAlert] = useState(false);

  const handleDeleteAvatar = useCallback(() => {
    api.patch(`/api/me/`, { avatar: null }).then(
      () => {
        dispatch(fetchUser());
      },
      (error) => {
        console.log("произошла ошибка удаления аватара: ", error);
      }
    );
  }, []);

  const submitAvatar = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size >= maxSize) {
        setShowAlert(true);
        e.target.form.reset();
      } else {
        const formData = new FormData();
        formData.append("avatar", file);

        api.patch(`/api/me/`, formData).then(
          () => {
            e.target.form.reset();
            dispatch(fetchUser());
          },
          (error) => {
            console.log("произошла ошибка изменения аватара: ", error);
          }
        );
      }
    }
  }, []);

  return (
    <>
      <form id="avatar-form">
        <FileWrapper>
          <input
            required
            type="file"
            onChange={submitAvatar}
            accept="image/png, image/jpeg, image/jpg"
            hidden
          />
          <Avatar avatar={avatar ?? "/assets/avatar.jpg"} />
          <SvgSelector svg={"file-arrow"} />
        </FileWrapper>
      </form>
      <Delete disabled={avatar ? false : true} onClick={handleDeleteAvatar}>
        Удалить
      </Delete>

      <Alert open={showAlert} setClose={() => setShowAlert(false)} />
    </>
  );
};

// --- DELETE ACC

const DeleteAccount = () => {
  const [youSure, setYouSure] = useState(false);
  return youSure ? (
    <>
      <DeleteButtonWrapper>
        <Slide in={true} direction="left" unmountOnExit mountOnEnter>
          <span>
            <DeleteButton onClick={() => setYouSure(false)}>
              Галя, отмена
            </DeleteButton>
          </span>
        </Slide>
        <Slide in={true} direction="left" unmountOnExit mountOnEnter>
          <span>
            <DeleteButton onClick={handleDeleteAccount}>
              Да, удалить
            </DeleteButton>
          </span>
        </Slide>
      </DeleteButtonWrapper>
      <Desc>
        Вы уверены, что хотите удалить свой аккаунт, все данные и аннулировать
        все подписки?
      </Desc>
    </>
  ) : (
    <>
      <Slide in={true} direction="right" unmountOnExit mountOnEnter>
        <span>
          <DeleteButton onClick={() => setYouSure(true)}>
            Удалить аккаунт
          </DeleteButton>
        </span>
      </Slide>
      <Desc>
        Эта кнопка удалит ваш аккаунт, все подписки, данные и права доступа.
        Подумайте дважды, прежде чем жмать
      </Desc>
    </>
  );
};

export const HeadBlock = ({ setOpenSettings }) => (
  <HeadSettings>
    <Back onClick={() => setOpenSettings(false)}>
      <SvgSelector svg={"arrow-back"} />
      <span>Назад</span>
    </Back>
    <TitleSettings>
      <SvgSelector svg={"user-settings"} />
      <span>Настройки</span>
    </TitleSettings>
  </HeadSettings>
);

// --- alert

const Alert = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.setClose();
    }, 5000);
    return () => clearTimeout(timer);
  }, [props.open]);

  return (
    <DialogAlert
      onClose={props.setClose}
      {...props}
      id="alert-menu"
      TransitionComponent={Zoom}
    >
      <SvgSelector svg="alert-file" />
      <span>Максимальный размер файла — 1МБ</span>
    </DialogAlert>
  );
};

const handleDeleteAccount = () => {
  api
    .delete(`/api/me/`)
    .then((error) => {
      console.log("произошла ошибка удаления аккаунта: ", error);
    })
    .finally(() => {
      window.location.reload();
    });
};
