import { persistor, store } from "@/redux/store";
import ErrorBoundary from "@/shared/ErrorBoundary";
import "@/styles/globals.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

export default function App({ Component, pageProps }) {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </>
  );
}
