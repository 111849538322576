import { DialogSt } from "@/components/header/UserDropdown";
import { Box, Help } from "@/components/header/loginTabs/components";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const BoxD = styled(Box)`
  gap: 0 !important;
  background-color: var(--wh04);
  width: 488px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  padding-top: 36px;

  @media ${breakpoints.desktopXl} {
    padding-top: 2.815vw;
    width: 38.155vw;
  }
  @media ${breakpoints.tabletL} {
    padding-top: 3.519vw;
    width: 47.703vw;
  }
  @media ${breakpoints.tabletM} {
    padding-top: 4.845vw;
    width: 65.68vw;
  }
  @media ${breakpoints.mobile} {
    padding-top: 17.555vw;
    width: 100%;
    background-color: #141414;
  }

  & ${Help} {
    color: var(--wh3);
    &:hover {
      color: var(--wh6);
    }
    &:active {
      color: var(--wh3);
    }
    margin: 0;
    @media ${breakpoints.mobile} {
      margin: 0 auto;
    }
  }

  & .MuiDialogContent-root {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 23px 36px;
    @media ${breakpoints.desktopXl} {
      padding: 0 1.798vw 2.815vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0 2.248vw 3.519vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 0 3.096vw 4.845vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0 7.524vw 11.285vw;
      justify-content: center;
      height: auto;
      & > div {
        max-height: 100%;
      }
    }
  }
`;

export const Head = styled.header`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 8.777vw;
  }

  & > div {
    display: flex;
    align-items: center;
    ${mixins.gap12}

    & > span {
      color: var(--wh9);
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
      ${fonts.manrope7}
      ${mixins.fs20}
      line-height: 100%;
      letter-spacing: -0.2px;
    }
    & > svg {
      width: 24px;
      height: 24px;

      @media ${breakpoints.desktopXl} {
        width: 1.875vw;
        height: 1.875vw;
      }
      @media ${breakpoints.tabletL} {
        width: 2.34vw;
        height: 2.34vw;
      }
      @media ${breakpoints.tabletM} {
        width: 3.23vw;
        height: 3.23vw;
      }
      @media ${breakpoints.mobile} {
        width: 7.5vw;
        height: 7.5vw;
      }
    }
    @media ${breakpoints.mobile} {
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--wh4);
  margin-bottom: 44px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 3.44vw;
    gap: 1.251vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 4.301vw;
    gap: 1.564vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 5.922vw;
    gap: 2.153vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 13.793vw;
    gap: 5.016vw;
  }

  & > li {
    display: flex;
    ${mixins.gap12}
    ${fonts.manrope5}
    ${mixins.fs16}
    line-height: 125%;
    @media ${breakpoints.mobile} {
      gap: 1.254vw;
      font-size: 3.762vw;
      max-width: 94%;
      margin: 0 auto;
    }
  }
`;

export const ButtonAccept = styled.button`
  display: flex;
  flex-direction: column;
  padding: 16px 0 20px;
  margin-bottom: 12px;
  width: 100%;
  align-items: center;
  ${fonts.manrope7}
  ${mixins.fs20}
  line-height: 100%;
  letter-spacing: -0.2px;
  transition: color 0.3s;
  color: var(--wh9);
  & > span {
    z-index: 1;
  }

  @media ${breakpoints.desktopXl} {
    padding: 1.251vw 0 1.564vw;
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.564vw 0 1.955vw;
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.153vw 0 2.692vw;
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    padding: 6.27vw 0 7.524vw;
    font-size: 5.016vw;
    margin-bottom: 2.508vw;
    font-weight: 600;
  }

  position: relative;
  & > svg {
    position: absolute;
    &:first-of-type {
      pointer-events: none;
      width: 120%;
      height: 200%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: none;
      max-height: none;
      transition: opacity 0.3s;
    }
    &:last-of-type {
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      & path {
        fill: #ff3496;
      }
    }
  }

  & > svg:first-of-type {
    opacity: 0.8;
  }

  &:hover {
    & > svg:first-of-type {
      opacity: 1;
    }
  }
  &:active {
    & > svg:first-of-type {
      opacity: 0.8;
    }
  }

  &:disabled {
    pointer-events: none;
    color: var(--wh2);
    & > svg:first-of-type {
      opacity: 0;
    }
  }
`;

export const SwWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mixins.gap8}
  & > span {
    ${fonts.manrope5}
    ${mixins.fs12}
  color: var(--wh2);
    line-height: 117%;
    letter-spacing: 0.24px;
    @media ${breakpoints.mobile} {
      font-size: 3.135vw;
    }
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 7.524vw;
  }
`;
export const IOSSwitch = styled.button`
  margin-left: 12px;
  flex-shrink: 0;
  width: 32px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  transition: background-color 0.3s;
  position: relative;

  @media ${breakpoints.desktopXl} {
    border-radius: 1.564vw;
    margin-left: 0.938vw;
    width: 2.502vw;
    height: 1.407vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 1.955vw;
    margin-left: 1.173vw;
    width: 3.128vw;
    height: 1.76vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.692vw;
    margin-left: 1.615vw;
    width: 4.307vw;
    height: 2.423vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 6.27vw;
    margin-left: 3.762vw;
    width: 10.031vw;
    height: 5.643vw;
  }

  ${({ accept }) => `background-color: var(--wh${accept ? "3" : "06"});`}

  &:hover {
    ${({ accept }) => `background-color: var(--wh${
      accept ? "2" : "08"
    }); &::before {
      background-color: var(--wh${accept ? "4" : "3"});}`}
  }

  &::before {
    width: 14px;
    height: 14px;
    transform: translate(2px, -2px);
    content: "";
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    transition: background-color 0.3s, right 0.3s;
    ${({ accept }) =>
      `right: ${accept ? "13%" : "55%"};
    background-color: var(--wh${accept ? "6" : "2"});`}

    @media ${breakpoints.desktopXl} {
      transform: translate(0.156vw, -0.156vw);
      width: 1.095vw;
      height: 1.095vw;
    }
    @media ${breakpoints.tabletL} {
      transform: translate(0.196vw, -0.196vw);
      width: 1.369vw;
      height: 1.369vw;
    }
    @media ${breakpoints.tabletM} {
      transform: translate(0.269vw, -0.269vw);
      width: 1.884vw;
      height: 1.884vw;
    }
    @media ${breakpoints.mobile} {
      transform: translate(0.627vw, -0.627vw);
      width: 4.389vw;
      height: 4.389vw;
    }
  }
`;

export const DialogD = styled(DialogSt)`
  z-index: 1303 !important;
`;
