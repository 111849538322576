import { DialogSt } from "@/components/header/UserDropdown";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { Head } from "../search/popup/components";
import { Help, LoginLabel } from "@/components/header/loginTabs/components";
import { Label } from "@/components/promo/components";
import { ButtonAccept } from "../disclaimer/components";

export const WrFooter = styled.div`
  display: flex;
  gap: 1.25vw;
  align-items: center;
  justify-content: center;
`;
export const Pulsed = styled.div`
  position: relative;
  pointer-events: none;
  width: 52px;
  height: 52px;

  @media ${breakpoints.desktopXl} {
    width: 4.066vw;
    height: 4.066vw;
  }
  @media ${breakpoints.tabletL} {
    width: 5.083vw;
    height: 5.083vw;
  }
  @media ${breakpoints.tabletM} {
    width: 6.999vw;
    height: 6.999vw;
  }
  @media ${breakpoints.mobile} {
    width: 16.301vw;
    height: 16.301vw;
  }
  & > svg {
    width: 100%;
    height: 100%;
  }
  &::before {
    z-index: -1;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #e04890 0%,
      rgba(158, 50, 121, 0) 100%
    );
    animation: heartbeat 1.3s ease-in-out infinite forwards;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.9);
    }
    40% {
      transform: scale(1.7);
    }
    60% {
      transform: scale(2.3);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
`;
export const PayDialog = styled(DialogSt)``;

export const BoxP = styled.div`
  border-radius: 32px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: 1px solid var(--wh04);
  background-color: var(--wh04);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: var(--wh9);
  overflow: hidden;
  width: 432px;
  @media ${breakpoints.desktopXl} {
    width: 33.776vw;
  }
  @media ${breakpoints.tabletL} {
    width: 42.229vw;
  }
  @media ${breakpoints.tabletM} {
    width: 58.143vw;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    justify-content: center;
    background-color: #141414;
    align-items: center;
    overflow-y: auto;
  }
  & .MuiDialogContent-root {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px 24px 12px;
    @media ${breakpoints.desktopXl} {
      padding: 1.88vw 1.88vw 0.94vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 2.35vw 2.35vw 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 3.23vw 3.23vw 1.62vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0 7.52vw 3.76vw;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
    }
  }
`;
export const HeadP = styled(Head)`
  width: 100%;
  z-index: 1;
  padding: 0 2.5vw;
`;
export const SectionP = styled.section`
  max-height: 100%;
  text-align: center;
  & ${Help} {
    color: var(--wh3);
    &:hover {
      color: var(--wh6);
    }
    &:active {
      color: var(--wh3);
    }
    justify-self: end;
    @media ${breakpoints.mobile} {
      margin-top: 13.793vw;
    }
  }
`;
export const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  ${mixins.gap12};
  & > div:first-of-type {
    margin: 0;
  }
  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    @media ${breakpoints.mobile} {
      align-items: center;
    }
  }

  & ${Label} {
    color: var(--wh9);
    text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
    margin: 0;
    max-width: 100%;
    @media ${breakpoints.mobile} {
      font-size: 6.27vw;
    }
  }
  margin-bottom: 20px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.692vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 6.27vw;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }
`;
export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > span {
    ${fonts.manrope5};
    color: var(--wh3);
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.24px;
    @media ${breakpoints.desktopXl} {
      font-size: 1.876vw;
      line-height: 2.502vw;
      letter-spacing: 0.019vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 2.346vw;
      line-height: 3.128vw;
      letter-spacing: 0.023vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 3.23vw;
      line-height: 4.307vw;
      letter-spacing: 0.032vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 7.524vw;
      line-height: 10.031vw;
      letter-spacing: 0.075vw;
    }

    & > span {
      color: var(--wh9);
    }
  }
  padding: 9px 16px;
  border-radius: 15.603px;
  background-color: var(--wh02);
  @media ${breakpoints.desktopXl} {
    padding: 0.704vw 1.251vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.88vw 1.564vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.211vw 2.153vw;
  }
  @media ${breakpoints.mobile} {
    padding: 2.821vw 5.016vw;
  }

  & > svg {
    width: 24px;
    height: 24px;
    @media ${breakpoints.desktopXl} {
      width: 1.875vw;
      height: 1.875vw;
    }
    @media ${breakpoints.tabletL} {
      width: 2.34vw;
      height: 2.34vw;
    }
    @media ${breakpoints.tabletM} {
      width: 3.23vw;
      height: 3.23vw;
    }
    @media ${breakpoints.mobile} {
      width: 7.5vw;
      height: 7.5vw;
    }
  }
`;
export const PriceGif = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(22px);
  backdrop-filter: blur(22px);
  ${fonts.manrope7};
  color: var(--wh2);
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.8px;
  @media ${breakpoints.desktopXl} {
    font-size: 3.127vw;
  }
  @media ${breakpoints.tabletL} {
    font-size: 3.91vw;
  }
  @media ${breakpoints.tabletM} {
    font-size: 5.384vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 8.777vw;
  }

  & > span {
    color: var(--wh6);
  }
  border-radius: 45px;
  background-color: var(--wh02);
  padding: 18px;
  @media ${breakpoints.desktopXl} {
    padding: 1.407vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.76vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.423vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.448vw;
  }
`;
export const Main = styled.main`
  ${mixins.gap12};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.15vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 5.02vw;
  }

  & > ${LoginLabel} {
    color: var(--wh4);
    ${fonts.manrope5};
    padding-left: 16px;
    @media ${breakpoints.desktopXl} {
      padding-left: 1.251vw;
    }
    @media ${breakpoints.tabletL} {
      padding-left: 1.564vw;
    }
    @media ${breakpoints.tabletM} {
      padding-left: 2.153vw;
    }
    @media ${breakpoints.mobile} {
      padding-left: 5.016vw;
    }
  }
`;
export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  & form {
    display: none;
  }
  gap: 20px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.94vw;
    gap: 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.17vw;
    gap: 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.62vw;
    gap: 2.692vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.76vw;
    gap: 2.508vw;
  }
  & > p {
    display: flex;
    align-items: center;
    & > span {
      color: var(--wh3);
      text-align: center;
      ${fonts.manrope7};
      ${mixins.fs10};
      margin-right: 4px;
      @media ${breakpoints.desktopXl} {
        margin-right: 0.313vw;
      }
      @media ${breakpoints.tabletL} {
        margin-right: 0.391vw;
      }
      @media ${breakpoints.tabletM} {
        margin-right: 0.538vw;
      }
      @media ${breakpoints.mobile} {
        margin-right: 0;
      }
    }
    & > svg {
      width: 54px;
      height: 12px;
      @media ${breakpoints.desktopXl} {
        width: 4.222vw;
        height: 0.938vw;
      }
      @media ${breakpoints.tabletL} {
        width: 5.279vw;
        height: 1.173vw;
      }
      @media ${breakpoints.tabletM} {
        width: 7.268vw;
        height: 1.615vw;
      }
      @media ${breakpoints.mobile} {
        transform: translate(1.254vw, 0.627vw);
        width: 16.928vw;
        height: 3.762vw;
      }
    }
    @media ${breakpoints.mobile} {
      display: inline;
      text-align: center;
      max-width: 90%;
    }
  }
  & ${ButtonAccept} {
    margin-bottom: 0;
  }
  & > a {
    width: 100%;
  }
`;

export const BoxSuccess = styled.div`
  border-radius: 32px;
  background-color: rgba(20, 20, 20, 0.6);
  /* backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px); */
  width: 460px;

  @media ${breakpoints.desktopXl} {
    width: 35.966vw;
  }
  @media ${breakpoints.tabletL} {
    width: 44.966vw;
  }
  @media ${breakpoints.tabletM} {
    width: 61.911vw;
  }
  @media ${breakpoints.mobile} {
    background-color: #141414;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & ${ButtonAccept} {
    margin-bottom: 0;
  }
  & > div {
    & > ${Price} {
      border-radius: 45px;
      box-shadow: 0px 0px 5.25px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(22.5px);
    }
    & > a {
      width: 70%;
      @media ${breakpoints.mobile} {
        width: 100%;
      }
    }
    padding: 14px 14px 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${breakpoints.desktopXl} {
      padding: 1.095vw 1.095vw 4.378vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 1.369vw 1.369vw 5.474vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 1.884vw 1.884vw 7.537vw;
    }
    @media ${breakpoints.mobile} {
      padding: 4.389vw;
      min-height: 100%;
      height: auto;
    }
  }
`;

export const Circle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
  ${({ hover }) => `opacity: ${hover ? 0.28 : 0.16};`};
  background: radial-gradient(
    50% 50% at 50% 50%,
    #e04890 0%,
    rgba(158, 50, 121, 0) 100%
  );
  width: 756px;
  height: 756px;
  @media ${breakpoints.desktopXl} {
    width: 59.109vw;
    height: 59.109vw;
  }
  @media ${breakpoints.tabletL} {
    width: 73.9vw;
    height: 73.9vw;
  }
  @media ${breakpoints.tabletM} {
    width: 101.75vw;
    height: 101.75vw;
  }
  @media ${breakpoints.mobile} {
    width: 236.991vw;
    height: 236.991vw;
  }
`;
export const AntilopaGif = styled.div`
  position: relative;
  height: 264px;
  width: 100%;
  margin-bottom: 52px;

  @media ${breakpoints.desktopXl} {
    height: 20.641vw;
    margin-bottom: 4.066vw;
  }
  @media ${breakpoints.tabletL} {
    height: 25.806vw;
    margin-bottom: 5.083vw;
  }
  @media ${breakpoints.tabletM} {
    height: 35.532vw;
    margin-bottom: 6.999vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 13.166vw;
    height: 55.799vw;
  }

  & > video {
    border-radius: 18px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

export const Title = styled.p`
  color: var(--wh6);
  text-align: center;
  ${fonts.manrope6};
  ${mixins.fs20};
  line-height: 100%;
  letter-spacing: -0.2px;
  margin-bottom: 12px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 3.762vw;
  }
`;
export const Title2 = styled.p`
  color: var(--wh6);
  text-align: center;
  line-height: 115%;
  ${fonts.manrope5};
  ${mixins.fs14};
  margin: 0;
  letter-spacing: 0.28px;
  margin-bottom: 32px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 2.502vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 3.128vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 4.307vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.762vw;
    margin-bottom: 7.524vw;
  }
`;
