import { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, Fade, Slide, useMediaQuery } from "@mui/material";
import SvgSelector from "@/shared/UI/SvgSelector";
import { UserContent } from "./userContent/userContent";
import { breakpoints } from "@/styles/variables/variables";
import { Artel, CloseButton, PopupHeader } from "./loginTabs/components";
import { HeadBlock } from "./userContent/settings/settings";
import { useSelector } from "react-redux";
import { Bookmark } from "./Bookmark";
import { Payment } from "../index-page/payment/Payment";
import { useAccess } from "@/hooks/useAccess";
import { isOverdue } from "@/shared/constants/access";
import { useRouter } from "next/router";
import { isLibrary } from "@/shared/constants/routes";

export const AuthorizedUserDropdown = () => {
  const [access] = useAccess();
  const { pathname } = useRouter();

  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);
  const { data: user } = useSelector((state) => state.user);

  const handleClose = () => {
    setOpen(false);
    openSettings && setOpenSettings(false);
  };

  useEffect(() => {
    if (isLibrary(pathname) && isOverdue(access)) setOpenPayment(true);
  }, [access]);

  return (
    <>
      <AvatarWr>
        <Avatar
          withHover={true}
          onClick={() => setOpen(true)}
          avatar={user.avatar ?? "/assets/avatar.jpg"}
        />
        <Bookmark />
      </AvatarWr>
      <DialogSt
        fullScreen={isMobile}
        onClose={handleClose}
        id="authorized-user-menu"
        TransitionComponent={Slide}
        TransitionProps={{ direction: isMobile ? "up" : "left" }}
        open={open}
      >
        {isMobile && (
          <Fade in={isMobile} timeout={700}>
            <PopupHeader openSettings={openSettings}>
              {openSettings ? (
                <HeadBlock setOpenSettings={setOpenSettings} />
              ) : (
                <Artel>
                  <SvgSelector svg={"logo-artel"} />
                </Artel>
              )}
              <CloseButton aria-label="Close" onClick={handleClose}>
                <SvgSelector svg={"close-popup"} />
              </CloseButton>
            </PopupHeader>
          </Fade>
        )}
        {user && (
          <UserContent
            handleOpenPaymentPopup={() => setOpenPayment(true)}
            handlecloseUserPopup={() => setOpen(false)}
            user={user}
            settings={[openSettings, setOpenSettings]}
          />
        )}
      </DialogSt>
      <Payment open={openPayment} setOpen={setOpenPayment} />
    </>
  );
};

const DialogSt = styled(Dialog)`
  z-index: 1302 !important;
  & .MuiDialog-container {
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 48px;
    padding-top: 60px;
    @media ${breakpoints.mobile} {
      padding: 0;
    }
  }
  & .MuiDialogContent-root {
    @media ${breakpoints.mobile} {
      padding-top: 0;
    }
  }
  & .MuiPaper-root {
    box-shadow: none;
    margin: 0;
    background-color: transparent;
    overflow-y: hidden;
  }
  & .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.65);
  }
`;

export const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  box-shadow: 0px 0px 9px 0px #fff, 2px 2px 6px 0px rgba(95, 255, 140, 0.4),
    -2px -2px 7px 0px rgba(255, 98, 56, 0.44), 0px 0px 2px 0px #fff;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 3px;
  transition: box-shadow 0.3s;
  ${({ avatar }) =>
    avatar &&
    `
    background-image: url(${avatar});
    `}
  ${({ withHover }) =>
    withHover &&
    `
    cursor: pointer;
    &:hover {
    box-shadow: 0px 0px 16px 0px #fff, 2px 2px 12px 0px rgba(95, 255, 140, 0.4),
      -2px -2px 12px 0px rgba(255, 98, 56, 0.44), 0px 0px 5px 0px #fff;
        @media ${breakpoints.mobile} {
          box-shadow: 0px 0px 9px 0px #FFF, 2px 2px 7px 0px rgba(95, 255, 140, 0.40), -2px -2px 7px 0px rgba(255, 98, 56, 0.44), 0px 0px 2px 0px #FFF;
        }
    }
    `}

  @media ${breakpoints.desktopXl} {
    margin-right: 0.235vw;
    width: 2.8vw;
    height: 2.8vw;
  }
  @media ${breakpoints.tabletL} {
    margin-right: 0.293vw;
    width: 3.5vw;
    height: 3.5vw;
  }

  @media ${breakpoints.tabletM} {
    margin-right: 0.404vw;
    width: 4.8vw;
    height: 4.8vw;
  }

  @media ${breakpoints.mobile} {
    margin-right: 0;
    width: 11.25vw;
    height: 11.25vw;
  }
`;

const AvatarWr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  z-index: 9;
  position: fixed;
  right: 19px;
  gap: 16px;
  top: 24px;
  @media ${breakpoints.desktopXl} {
    top: 1.876vw;
    gap: 1.251vw;
    right: 1.486vw;
  }
  @media ${breakpoints.tabletL} {
    top: 2.346vw;
    gap: 1.564vw;
    right: 1.857vw;
  }
  @media ${breakpoints.tabletM} {
    top: 3.23vw;
    gap: 2.153vw;
    right: 2.557vw;
  }
  @media ${breakpoints.mobile} {
    gap: 5.016vw;
    top: 3.762vw;
    right: 2.508vw;
  }
`;
