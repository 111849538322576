import SvgSelector from "@/shared/UI/SvgSelector";
import { Bind, Left, Row, Social } from "./components";
import { EmailPopup } from "./EmailPopup";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { connectTelegram, disconnectTelegram } from "@/services/tgBot";
import api from "@/services/api";

// const social = [
//   { svg: "email", label: "email@gmail.com", active: false, single: false },
//   { svg: "tg", label: "@andrepavlushin", active: true, single: false },
//   { svg: "ya", label: "Яндекс", active: false, single: false },
//   { svg: "vk", label: "ВКонтакте", active: false, single: false },
//   { svg: "google", label: "Гугл", active: false, single: false },
//   { svg: "mailru", label: "Мейл.ру", active: false, single: false },
//   { svg: "fb", label: "Фейсбук", active: false, single: false },
// ];

export const SocialConnect = () => {
  const { auth_connections } = useSelector((state) => state.user.data);
  const checkedConnections = useMemo(
    () => checkNumOfActiveConnections(auth_connections),
    [auth_connections]
  );
  return (
    <Social>
      <ItemEmail
        single={checkedConnections.single === "email"}
        data={checkedConnections.email}
      />
      <Item
        handleClick={
          checkedConnections.telegram ? disconnectTelegram : connectTelegram
        }
        single={checkedConnections.single === "telegram"}
        label={"Телеграм"}
        svg={"tg"}
        data={checkedConnections.telegram}
      />
    </Social>
  );
};

// --- ACCOUNTS ITEM

const Item = ({ label, svg, data, single, handleClick }) => {
  return (
    <Row>
      <Left active={data}>
        <SvgSelector svg={`accounts-${svg}`} />
        <span>{data ?? label}</span>
      </Left>
      {single || <BindButton handleClick={handleClick} data={data} />}
    </Row>
  );
};

const ItemEmail = ({ data, single }) => {
  const [open, setOpen] = useState(false);

  return (
    <Row>
      <Left active={data}>
        <SvgSelector svg={"accounts-email"} />
        <span>{data ?? "Электропочта"}</span>
      </Left>

      {single || (
        <BindButton
          handleClick={() => {
            data ? disconnectEmail() : setOpen(true);
          }}
          data={data}
        />
      )}
      <EmailPopup open={open} setOpen={setOpen} />
    </Row>
  );
};
const BindButton = ({ handleClick, data }) => {
  return <Bind onClick={handleClick}>{data ? "Отвязать" : "Привязать"}</Bind>;
};
const checkNumOfActiveConnections = (data) => {
  const activeConnections = Object.keys(data).filter((key) => data[key]);

  if (activeConnections.length === 1) {
    return Object.assign({ single: activeConnections[0] }, data);
  } else {
    return data;
  }
};

const disconnectEmail = async () => {
  try {
    const res = await api.post(`/api/auth/email/disconnect/`);

    if (res.status === 204) {
      window.location.reload();
    }
  } catch (error) {
    console.log("произошла ошибка отвязки: ", error);
  }
};
