import { Tooltip } from "@mui/material";
import { TooltipText } from "./card-components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { useState } from "react";
import Markdown from "react-markdown";
import styled from "styled-components";

export const FontTooltip = ({ text }) => {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      placement="top-start"
      open={open}
      onClick={() => setOpen(true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={
        <TooltipText>
          <Markdown components={{ p: "span" }}>{text}</Markdown>
          <SvgSelector svg={"tooltip-arrow"} />
        </TooltipText>
      }
    >
      <Button>
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3329 6.96606C12.3329 9.72749 10.0943 11.9661 7.33287 11.9661C4.57144 11.9661 2.33287 9.72749 2.33287 6.96606C2.33287 4.20464 4.57144 1.96607 7.33287 1.96607C10.0943 1.96607 12.3329 4.20464 12.3329 6.96606ZM7.04115 10.5161C7.37115 10.5161 8.02115 10.1161 8.66115 9.40607V8.90534C8.31115 9.27534 7.96115 9.59534 7.56115 9.82534C7.66003 9.326 7.75646 8.94416 7.85287 8.56243C7.95147 8.17205 8.05003 7.78178 8.15115 7.26606C8.20115 7.02606 8.25115 6.72606 8.25115 6.54606C8.25115 6.13607 8.06115 5.87606 7.56115 5.87606C7.14115 5.87606 6.66115 6.16606 5.97115 6.96606L5.87118 7.59617C6.19118 7.22617 6.61118 6.80617 6.97118 6.63617L6.45115 9.24607L6.43471 9.33525C6.39867 9.52938 6.36115 9.73148 6.36115 9.89606C6.36115 10.3261 6.59115 10.5161 7.04115 10.5161ZM7.25115 4.37607C7.25115 3.91606 7.65115 3.44606 8.13115 3.44606C8.52115 3.44606 8.77115 3.72607 8.77115 4.09607C8.77115 4.58606 8.33115 5.01606 7.87115 5.01606C7.52115 5.01606 7.25115 4.73607 7.25115 4.37607Z"
            fill="white"
            fillOpacity={open ? "0.4" : "0.2"}
            style={{ transition: "fill-opacity 0.3s" }}
          />
        </svg>
      </Button>
    </Tooltip>
  );
};

const Button = styled.button`
  width: 15px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
