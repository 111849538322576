import { createSlice } from "@reduxjs/toolkit";

const teamSlice = createSlice({
  name: "team",
  initialState: {
    data: {
      fonts: null,
      artpreparation: null,
      handbook: null,
      campus: null,
    },
    loading: false,
    error: "",
  },
  reducers: {
    fetchTeamSuccess(state, action) {
      const { team, key } = action.payload;
      state.data[key] = team;
    },
    fetchTeamLoading(state, action) {
      state.loading = action.payload;
    },
    fetchTeamError(state, action) {
      state.error = action.payload;
    },
  },
});

export const teamReducer = teamSlice.reducer;
export const { fetchTeamSuccess, fetchTeamLoading, fetchTeamError } =
  teamSlice.actions;
