import { createSlice } from "@reduxjs/toolkit";

const searchInputSlice = createSlice({
  name: "searchInput",
  initialState: {
    searchOpen: false,
    filterSearch: undefined,
  },
  reducers: {
    setFilterSearch: (state, action) => {
      state.filterSearch = action.payload;
    },
    resetFilterSearch: (state) => {
      state.filterSearch = undefined;
    },
    toggleSearchOpen: (state, action) => {
      state.searchOpen = action.payload;
      state.filterSearch = undefined;
    },
  },
});

export const searchInputReducer = searchInputSlice.reducer;
export const { setFilterSearch, resetFilterSearch, toggleSearchOpen } =
  searchInputSlice.actions;
