import { createSlice } from "@reduxjs/toolkit";

const campusSlice = createSlice({
  name: "campus",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },
  reducers: {
    fetchCampusSuccess(state, action) {
      state.data = action.payload;
    },
    fetchCampusLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCampusError(state, action) {
      state.error = action.payload;
    },
  },
});

export const campusReducer = campusSlice.reducer;
export const { fetchCampusSuccess, fetchCampusLoading, fetchCampusError } =
  campusSlice.actions;
