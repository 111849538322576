import { Notification } from "@/components/Notification";
import {
  Box,
  Head,
  NotButton,
  NotFooter,
  NotHead,
  NotWrapper,
  SettingsButtons,
  Social,
  Subscribes,
  Title,
} from "./components";
import { Avatar } from "../AuthorizedUserDropdown";
import SvgSelector from "@/shared/UI/SvgSelector";
import { social } from "@/shared/constants/social";
import { DialogContent, Fade, useMediaQuery } from "@mui/material";
import { HeadBlock, Settings } from "./settings/settings";
import { breakpoints } from "@/styles/variables/variables";
import api from "@/services/api";
import { DaysButton } from "./DaysButton";

export const UserContent = ({
  settings,
  user,
  handlecloseUserPopup,
  handleOpenPaymentPopup,
}) => {
  const [openSettings, setOpenSettings] = settings;

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <Box>
      {openSettings ? (
        <>
          {isMobile || <HeadBlock setOpenSettings={setOpenSettings} />}
          <Settings user={user} />
        </>
      ) : (
        <>
          <DialogContent scroll="paper">
            <Fade in={true} timeout={700} style={{ transitionDelay: 70 }}>
              <Head>
                <Avatar avatar={user.avatar ?? "/assets/avatar.jpg"} />
                <h2>
                  {user.display_name === undefined ||
                  user.display_name?.trim().length === 0
                    ? "Нобель Файк"
                    : user.display_name}
                </h2>
              </Head>
            </Fade>
            <Notification mb={28} storageKey={"quick-login"}>
              <Fade in={true} timeout={700} style={{ transitionDelay: 80 }}>
                <NotWrapper>
                  <NotHead>
                    <h2>Быстрый вход</h2>
                    <div>НЬЮ</div>
                  </NotHead>
                  <main>
                    Привяжи в настройках аккаунта свой телеграм для быстрого
                    входа
                  </main>
                  <NotFooter>
                    <ul>
                      {social.map((link, i) => (
                        <Social key={i}>
                          <SvgSelector svg={`login-${link.type}`} />
                        </Social>
                      ))}
                    </ul>
                    <NotButton onClick={() => setOpenSettings(true)}>
                      Привязать
                    </NotButton>
                  </NotFooter>
                </NotWrapper>
              </Fade>
            </Notification>

            <Fade in={true} timeout={700} style={{ transitionDelay: 210 }}>
              <Subscribes>
                <Title>Подписки</Title>
                {user?.subscriptions && (
                  <DaysButton
                    handlecloseUserPopup={handlecloseUserPopup}
                    handleOpenPaymentPopup={handleOpenPaymentPopup}
                    dateEnd={
                      user.subscriptions.find(
                        (subs) => subs?.product === "foundation"
                      )?.date_end
                    }
                  />
                )}

                <SettingsButtons>
                  <button onClick={() => setOpenSettings(true)}>
                    <SvgSelector svg={"user-settings"} />
                    <span>Настройки</span>
                  </button>
                  <button onClick={handleLogout}>
                    <SvgSelector svg={"user-settings-logout"} />
                    <span>Выйти</span>
                  </button>
                </SettingsButtons>
              </Subscribes>
            </Fade>
          </DialogContent>
        </>
      )}
    </Box>
  );
};

const handleLogout = () => {
  api
    .post(`/logout/`)
    .then((error) => {
      console.log("произошла ошибка выхода из аккаунта: ", error);
    })
    .finally(() => window.location.reload());
};
