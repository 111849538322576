import { ButtonDownload } from "./card-components";

export const ButtonDownloadOrLock = ({ downloadUrl = "" }) => {
  return (
    <ButtonDownload>
      <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
        Скачать
      </a>
    </ButtonDownload>
  );
};
