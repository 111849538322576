import { breakpoints } from "@/styles/variables/variables";
import styled from "styled-components";
import {
  ButtonDownload,
  CardFooter,
  CardMain,
  Circle,
} from "./card-components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { FlexContainer } from "../CardsContainer";
import { useIndexByQuery } from '@/hooks/useIndexByQuery';

export const CardsLocked = () => {
  const { index } = useIndexByQuery();

  return (
    <FlexContainer>
      {Array.from(Array(index)).map((el) => (
        <CardLocked key={el} />
      ))}
    </FlexContainer>
  );
};

export const CardLocked = () => (
  <Wrapper>
    <header></header>
    <CardMain>
      {[1, 2, 3].map((i) => (
        <span key={i}></span>
      ))}
      <ButtonDownload>
        <SvgSelector svg={"card-lock-close"} />
      </ButtonDownload>
    </CardMain>
    <CardFooter />
    <Circle color={"rgba(255, 255, 255, 0.24)"} />
    <Circle color={"rgba(255, 255, 255, 0.24)"} />
  </Wrapper>
);

const Wrapper = styled.section`
  border-radius: 24px;
  border: 1.7px solid var(--wh04);
  padding: 7px 7px 10px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: 6px;
  grid-template-rows: 1fr 0.95fr 12px;
  height: 380px;
  position: relative;
  overflow: hidden;
  width: 256px;

  @media ${breakpoints.desktopXl} {
    grid-template-rows: 1fr 0.95fr 0.9375vw;
    padding: 0.546vw 0.546vw 0.78vw;
    gap: 0.468vw;
    width: 20.016vw;
    height: 29.69vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.68vw 0.68vw 0.98vw;
    grid-template-rows: 1fr 0.95fr 1.17vw;
    gap: 0.585vw;
    width: 25.024vw;
    height: 37.15vw;
  }
  @media ${breakpoints.tabletM} {
    grid-template-rows: 1fr 0.95fr 1.6vw;
    padding: 0.94vw 0.94vw 1.34vw;
    gap: 0.8vw;
    width: 34.455vw;
    height: 51.144vw;
  }
  @media ${breakpoints.mobile} {
    grid-template-rows: 1fr 0.95fr 3.75vw;
    gap: 1.875vw;
    padding: 2.19vw 2.19vw 3.125vw;
    width: 80.251vw;
    height: 118.75vw;
  }
  & > header {
    height: 99%;
    width: 100%;
    border-radius: 24px;
    background-color: var(--wh02);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
  & > main {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    & > span {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: var(--wh02);
      &:nth-child(3) {
        grid-column: span 2;
      }
    }
    & > button {
      grid-column: span 2;
      width: 100%;
      height: 36.2px;
      background-color: var(--wh02) !important;
      pointer-events: none;
      @media ${breakpoints.desktopXl} {
        height: 2.83vw;
      }
      @media ${breakpoints.tabletL} {
        height: 3.539vw;
      }
      @media ${breakpoints.tabletM} {
        height: 4.872vw;
      }
      @media ${breakpoints.mobile} {
        height: 11.348vw;
      }
      & > svg {
        height: 38%;
        width: auto;
      }
    }
  }
  & > footer {
    width: 65%;
    border-radius: 8px;
    background-color: var(--wh02);
  }
`;
