import SvgSelector from "@/shared/UI/SvgSelector";
import { useState } from "react";
import { ExpandButtonStyled } from "../card-components";
import { useDispatch } from "react-redux";
import { setActiveCard } from "@/redux/fetchSlices/cardsSlice";

export const ExpandButton = ({ card }) => {
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();

  return (
    <ExpandButtonStyled
      onClick={() => dispatch(setActiveCard(card))}
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setTimeout(() => setClicked(false), 150)}
    >
      <SvgSelector svg={"card-btn-bg"} />
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path
          transform={clicked ? "translate(-1, 1)" : ""}
          d="M9.37441 13.3383L2.51727 13.3383C1.57049 13.3383 0.802981 12.5708 0.802981 11.624L0.802979 4.76685"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
        />
        <path
          transform={clicked ? "translate(1, -1)" : ""}
          d="M4.23145 1.33826L10.8029 1.33826C11.9074 1.33826 12.8029 2.23369 12.8029 3.33826L12.8029 9.90969L12.8029 3.05255C12.8029 2.10577 12.0354 1.33826 11.0886 1.33826L4.23145 1.33826Z"
          stroke="white"
          strokeOpacity="0.9"
          strokeWidth="1.5"
        />
      </svg>
    </ExpandButtonStyled>
  );
};
