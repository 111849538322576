import styled from "styled-components";

export const HoverTwoSpan = ({ children, active }) => {
  return <Spans active={active}>{children}</Spans>;
};

export const Spans = styled.div`
  display: grid;
  pointer-events: none;
  grid-template-columns: auto auto;
  ${({ active }) =>
    `
      & > span{
        &:first-child{
            opacity: ${active ? 1 : 0};
            transform: translateY(${active ? "0" : "30px"});
        } 
        &:nth-child(2n){
            opacity: ${active ? 0 : 1};
            transform: translateY(${active ? "-30px" : "0"});
        }
        transition: transform 0.3s, opacity 0.3s;
        grid-column: 1;
        grid-row: 1;
            }
            `};
`;
