import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

// -- header
export const CardHeader = styled.header`
  border-radius: 24px;
  height: 171px;
  ${({ image }) => image && `background-image: url(${image});`}
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  padding: 9px 17px 13px 13px;
  display: grid;

  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  transition: background-size 0.3s;
  @media ${breakpoints.desktopXl} {
    height: 13.359vw;
    padding: 0.7vw 1.328vw 1.01vw 1.01vw;
  }
  @media ${breakpoints.tabletL} {
    height: 16.7vw;
    padding: 0.88vw 1.66vw 1.269vw 1.269vw;
  }
  @media ${breakpoints.tabletM} {
    height: 22.98vw;
    padding: 1.21vw 2.28vw 1.747vw 1.747vw;
  }
  @media ${breakpoints.mobile} {
    height: 53.44vw;
    padding: 2.81vw 5.31vw 4.06vw 4.06vw;
  }
`;

export const FontName = styled.div`
  overflow: hidden;
  text-align: center;
  ${mixins.fs10}
  ${fonts.manrope7}
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: normal;
`;
export const FontSample = styled.span`
  align-self: center;
  line-height: normal;
  text-align: center;
  ${({ fs }) => fs && `font-size: ${fs}px;`};
  ${({ ls }) => ls && `letter-spacing: ${ls}px;`};
  ${({ yoffset }) => yoffset && `transform: translateY(${yoffset}px);`};
  padding-bottom: 15px;
  width: 100%;
  grid-row: 2;

  @media ${breakpoints.desktopXl} {
    padding-bottom: 1.17vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.078}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.078}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.078}vw);`};
  }
  @media ${breakpoints.tabletL} {
    padding-bottom: 1.46vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.09775}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.09775}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.09775}vw);`};
  }
  @media ${breakpoints.tabletM} {
    padding-bottom: 2.016vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.1346}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.1346}vw`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.1346}vw);`};
  }
  @media ${breakpoints.mobile} {
    padding-bottom: 4.68vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.31348}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.31348}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.31348}vw);`};
  }
`;
export const CardHeaderBottom = styled.div`
  align-self: end;
  grid-row: 3;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  /* overflow: hidden; */
  position: relative;
`;
export const HeaderCurrency = styled.div`
  padding: 3.5px 6px 5.5px;
  ${mixins.fs10};
  ${fonts.manrope5};
  letter-spacing: 0.2px;
  border-radius: 9px;
  background-color: var(--wh04);
  -webkit-backdrop-filter: blur(26px);
  backdrop-filter: blur(26px);

  @media ${breakpoints.mobile} {
    padding: 1.7vw;
    border-radius: 4vw;
  }
`;
export const HeaderCurrencyNumber = styled.span`
  line-height: normal;
  position: absolute;
  bottom: 0;
  right: 0;

  ${({ fs }) => fs && `font-size: ${fs}px;`};
  ${({ ls }) => ls && `letter-spacing: ${ls}px;`};
  ${({ yoffset }) => yoffset && `transform: translateY(${yoffset}px);`};

  @media ${breakpoints.desktopXl} {
    ${({ fs }) => fs && `font-size: ${fs * 0.078}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.078}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.078}vw);`};
  }
  @media ${breakpoints.tabletL} {
    ${({ fs }) => fs && `font-size: ${fs * 0.09775}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.09775}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.09775}vw);`};
  }
  @media ${breakpoints.tabletM} {
    ${({ fs }) => fs && `font-size: ${fs * 0.1346}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.1346}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.1346}vw);`};
  }
  @media ${breakpoints.mobile} {
    ${({ fs }) => fs && `font-size: ${fs * 0.31348}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.31348}vw;`};
    ${({ yoffset }) =>
      yoffset && `transform: translateY(${yoffset * 0.31348}vw);`};
  }
`;

// main
export const CardMain = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 24px 88px;
  gap: 8px 4px;
  color: var(--wh6);
  @media ${breakpoints.desktopXl} {
    grid-template-rows: 1.875vw 6.87vw;
    gap: 0.625vw 0.31vw;
  }
  @media ${breakpoints.tabletL} {
    grid-template-rows: 2.34vw 8.59vw;
    gap: 0.78vw 0.39vw;
  }
  @media ${breakpoints.tabletM} {
    grid-template-rows: 3.22vw 11.83vw;
    gap: 1.075vw 0.54vw;
  }
  @media ${breakpoints.mobile} {
    grid-template-rows: 7.5vw 27.5vw;
    gap: 2.5vw 1.25vw;
  }
`;

export const TypefaceCount = styled.span`
  border-radius: 8px;
  background-color: var(--wh04);
  -webkit-backdrop-filter: blur(26px);
  backdrop-filter: blur(26px);
  ${mixins.fs10};
  ${fonts.manrope5}
  line-height: 120%;
  letter-spacing: 0.2px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* @media ${breakpoints.mobile} {
    padding: 1.7vw;
    border-radius: 4vw;
  } */
`;

export const Textarea = styled.textarea`
  resize: none;
  grid-column: span 2;
  padding: 8px;
  border-radius: 8px;
  color: var(--wh6);
  background-color: var(--wh04);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  transition: background-color 0.3s;
  height: 100%;
  &::placeholder {
    transition: color 0.3s;
  }
  &:hover {
    background-color: var(--wh08);
  }
  &:focus {
    &::placeholder {
      color: var(--wh12);
    }
  }

  ${({ preview_case }) =>
    `text-transform: ${
      preview_case === "upper"
        ? "uppercase"
        : preview_case === "lower"
        ? "lowercase"
        : "none"
    };`};

  ${({ fs }) => fs && `font-size: ${fs}px;`};
  ${({ ls }) => ls && `letter-spacing: ${ls}px;`};
  ${({ lh }) => lh && `line-height: ${lh}px;`};
  @media ${breakpoints.desktopXl} {
    padding: 0.625vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.078}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.078}vw;`};
    ${({ lh }) => lh && `line-height: ${lh * 0.078}vw;`};
  }
  @media ${breakpoints.tabletL} {
    padding: 0.78vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.098}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.098}vw;`};
    ${({ lh }) => lh && `line-height: ${lh * 0.098}vw;`};
  }
  @media ${breakpoints.tabletM} {
    padding: 1.075vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.1346}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.1346}vw;`};
    ${({ lh }) => lh && `line-height: ${lh * 0.1346}vw;`};
  }
  @media ${breakpoints.mobile} {
    padding: 2.5vw;
    ${({ fs }) => fs && `font-size: ${fs * 0.31348}vw;`};
    ${({ ls }) => ls && `letter-spacing: ${ls * 0.31348}vw;`};
    ${({ lh }) => lh && `line-height: ${lh * 0.31348}vw;`};
  }
`;
export const ButtonDownload = styled.button`
  text-align: center;
  ${mixins.fs12};
  line-height: 130%;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  ${fonts.manrope7}
  background-color: var(--wh04);
  color: var(--wh4);
  transition: background-color 0.3s;
  margin-top: 2px;
  @media ${breakpoints.desktopXl} {
    margin-top: 0.156vw;
  }
  @media ${breakpoints.tabletL} {
    margin-top: 0.196vw;
  }
  @media ${breakpoints.tabletM} {
    margin-top: 0.269vw;
  }
  @media ${breakpoints.mobile} {
    margin-top: 0.627vw;
  }
  & > a {
    width: 100%;
    height: 100%;
    display: block;
    padding: 9px 0 11px;
    @media ${breakpoints.desktopXl} {
      padding: 0.7vw 0 0.86vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0.879vw 0 1.07vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 1.21vw 0 1.48vw;
    }
    @media ${breakpoints.mobile} {
      padding: 2.81vw 0 3.44vw;
    }
  }
  /* & svg > path {
    transition: fill-opacity 0.3s;
  }
  & svg > path:first-child {
    transition: transform 0.5s, fill-opacity 0.3s;
    transform-origin: 55% bottom;
  } */
  &:hover {
    background-color: var(--wh08);
    /* & svg > path {
      fill-opacity: 0.6;
    } */
  }
  &:active,
  &:focus {
    background-color: var(--wh04);
    /* & svg > path:first-child {
      transform: rotateY(180deg);
    } */
  }
`;

// footer
export const CardFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5px;
  height: 12px;
  @media ${breakpoints.desktopXl} {
    height: 0.9375vw;
  }
  @media ${breakpoints.tabletL} {
    height: 1.17vw;
  }
  @media ${breakpoints.tabletM} {
    height: 1.6vw;
  }
  @media ${breakpoints.mobile} {
    height: 3.75vw;
  }
  & > button {
    flex-shrink: 0;
  }
`;

export const TooltipText = styled.p`
  padding: 8px 14px 12px;
  border-radius: 8px;
  border: 0.5px solid var(--wh04);
  background-color: var(--wh08);
  color: var(--wh6);
  ${fonts.manrope5}
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 0.2px;
  width: min-content;

  position: relative;
  display: flex;
  flex-direction: column;
  ${mixins.gap8}
  & > svg {
    position: absolute;
    bottom: -0.5px;
    left: 0;
    transform: translate(21px, 100%);
  }
`;

export const Author = styled.a`
  color: var(--wh2);
  ${mixins.fs10};
  ${fonts.manrope5}
  line-height: 120%;
  letter-spacing: 0.2px;
  transition: color 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 185px;
  @media ${breakpoints.desktopXl} {
    max-width: 14.464vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 18.084vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 24.899vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 57.994vw;
  }
  &:hover {
    color: var(--wh4);
  }
  &:active {
    color: var(--wh2);
  }
`;

//  --- card

export const Circle = styled.div`
  pointer-events: none;
  position: absolute;
  opacity: 0.3;
  ${({ color }) =>
    color &&
    `
  background: radial-gradient(
    50% 50% at 50% 50%,
    ${color} 0%,
    rgba(29, 146, 160, 0) 100%
    );
    `}
  opacity: 0.3;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  &:first-of-type {
    top: -19%;
    width: 127%;
    height: 85%;
  }
  &:last-of-type {
    width: 142%;
    height: 96%;
    bottom: -58%;
  }
`;

const ButtonHeader = styled.button`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  transition: opacity 0.3s;
  width: 24px;
  height: 24px;
  @media ${breakpoints.desktopXl} {
    top: 1.56vw;
    width: 1.875vw;
    height: 1.875vw;
  }
  @media ${breakpoints.tabletL} {
    top: 1.95vw;
    width: 2.34vw;
    height: 2.34vw;
  }
  @media ${breakpoints.tabletM} {
    top: 2.68vw;
    width: 3.23vw;
    height: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    top: 6.25vw;
    width: 7.5vw;
    height: 7.5vw;
  }
  & > svg {
    position: absolute;
    overflow: visible;
    & path {
      transition: fill-opacity 0.3s;
    }
    &:first-child {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
    }
    &:last-child {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    & > svg {
      &:first-child {
        transform: scale(1.1);
        & path {
          fill-opacity: 0.2;
        }
      }
    }
  }
  &:active {
    & > svg {
      &:first-child {
        transform: scale(1);
      }
    }
  }
`;

export const ExpandButtonStyled = styled(ButtonHeader)`
  left: 18px;
  @media ${breakpoints.desktopXl} {
    left: 1.41vw;
  }
  @media ${breakpoints.tabletL} {
    left: 1.76vw;
  }
  @media ${breakpoints.tabletM} {
    left: 2.4vw;
  }
  @media ${breakpoints.mobile} {
    left: 5.6vw;
  }

  & > svg {
    pointer-events: none;
  }

  & > svg:last-child > path {
    transition: transform 0.15s;
  }

  &:active {
    & > svg {
      &:first-child {
        transform: scale(1);
      }
    }
  }

  @keyframes reductionTop {
    100% {
      transform: translate(-1px, 1px);
    }
  }
  @keyframes reductionBottom {
    100% {
      transform: translate(1px, -1px);
    }
  }
`;
export const FavButtonStyled = styled(ButtonHeader)`
  ${({ isfav }) => isfav && `opacity: 1 !important;`}

  right: 18px;
  @media ${breakpoints.desktopXl} {
    right: 1.41vw;
  }
  @media ${breakpoints.tabletL} {
    right: 1.76vw;
  }
  @media ${breakpoints.tabletM} {
    right: 2.4vw;
  }
  @media ${breakpoints.mobile} {
    right: 5.6vw;
  }

  & > svg:last-child > path {
    ${(props) => props.isfav && `fill-opacity: ${props.dialog ? 0.6 : 0.9};`}
  }

  &:active {
    & > svg:last-child > path {
      ${({ isfav }) => isfav && `fill-opacity: 0;`}
    }
  }

  ${({ dialog }) =>
    dialog &&
    `opacity: 1;
    pointer-events: all;
    width: 42px;
    height: 42px;
    bottom: 0;
    left: 4px;
    top: initial;

    &:hover {
      & > svg {
        &:first-child {
          & path {
            fill-opacity: 0.04;
          }
        }
      }
    }
    &:active {
      & > svg {
        &:first-child {
          & path {
            fill-opacity: 0.08;
          }
        }
      }
    }

    @media ${breakpoints.desktopXl} {
      width: 3.284vw;
      height: 3.284vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.106vw;
      height: 4.106vw;
    }
    @media ${breakpoints.tabletM} {
      width: 5.653vw;
      height: 5.653vw;
    }
    @media ${breakpoints.mobile} {
      width: 13.166vw;
      height: 13.166vw;
    }
    & > svg:last-child {
      width: 31%;
      height: 33%;
      & path {
        stroke-opacity: 0.6;
      }
    };`}
`;

export const New = styled.span`
  position: absolute;
  z-index: 12;
  top: -5px;
  left: -5px;
  width: 44px;
  height: 22px;
  transition: opacity 0.3s;
  & > svg {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
  }
  @media ${breakpoints.desktopXl} {
    width: 3.44vw;
    height: 1.72vw;
  }
  @media ${breakpoints.tabletL} {
    width: 4.3vw;
    height: 2.15vw;
  }
  @media ${breakpoints.tabletM} {
    width: 5.92vw;
    height: 2.96vw;
  }
  @media ${breakpoints.mobile} {
    width: 13.79vw;
    height: 6.9vw;
  }
`;

export const CardStyled = styled.section`
  animation: showCard 0.5s ease 1 forwards;
  position: relative;
  background-color: transparent;
  color: var(--wh9);
  border: 1.2px
    ${({ published }) =>
      published ? "solid var(--wh04)" : "dashed var(--wh4)"};
  font-family: ${({ fontname }) => fontname && fontname};
  ${({ disableInBookmarkMode }) =>
    `opacity: ${disableInBookmarkMode ? 0.5 : 1};`};

  font-style: normal;
  border-radius: 32px;
  flex-shrink: 0;
  width: 256px;
  @media ${breakpoints.desktopXl} {
    width: 20.016vw;
  }
  @media ${breakpoints.tabletL} {
    width: 25.024vw;
  }
  @media ${breakpoints.tabletM} {
    width: 34.455vw;
  }
  @media ${breakpoints.mobile} {
    width: 80.251vw;
  }

  &:hover {
    ${Circle} {
      opacity: 0.6;
    }
    ${CardHeader} {
      background-size: 115% 115%;
    }
    ${ButtonHeader} {
      opacity: 1 !important;
      pointer-events: all !important;
    }
    ${New} {
      opacity: 0;
    }
  }

  @keyframes showCard {
    from {
      transform: scale(0.97);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const CardCirclesWrapper = styled.div`
  position: relative;
  border-radius: 32px;
  padding: 7px 7px 10px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;

  @media ${breakpoints.desktopXl} {
    padding: 0.546vw 0.546vw 0.78vw;
    gap: 0.468vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.68vw 0.68vw 0.98vw;
    gap: 0.585vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.94vw 0.94vw 1.34vw;
    gap: 0.8vw;
  }
  @media ${breakpoints.mobile} {
    padding: 2.19vw 2.19vw 3.125vw;
    gap: 1.875vw;
  }

  @keyframes showCard {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const Loader = styled.div`
  padding: 64px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  & > button {
    color: var(--wh6);
    ${fonts.manrope5}
    ${mixins.fs12}
    line-height: 117%;
    letter-spacing: 0.24px;
    padding: 10px 22px 12px;
    border-radius: 21px;
    background-color: var(--wh02);
    transition: background-color 0.3s;
    &:hover {
      background-color: var(--wh04);
    }
    &:active {
      background-color: var(--wh08);
    }
  }
`;
