import React, { useState } from "react";
import {
  Desc,
  GridWrapper,
  Item,
  ItemE,
  SpanRole,
  SpanRoleEmail,
  Title,
  Wrap8,
  Wrap8Box,
} from "./components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { Fade } from "@mui/material";
import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import { helpLink } from "@/shared/constants/links";

export const HelpSupport = () => (
  <Fade in={true} timeout={700}>
    <Wrap8>
      <Title>Поддержка</Title>
      <Wrap8Box>
        <ItemTg />
        <ItemEmail />
      </Wrap8Box>
      <Desc>Отвечаем с 10 до 19 мск, каждый божий день</Desc>
    </Wrap8>
  </Fade>
);
const email = "hi@artpavlushin.ru";
const ItemEmail = () => {
  const [state, setstate] = useState(0);

  const copy = () => {
    if (state !== 2) {
      navigator.clipboard.writeText(email);
      setstate(2);
      setTimeout(() => {
        setstate(0);
      }, 4000);
    }
  };

  return (
    <ItemE
      gap={8}
      animated={state === 2}
      onMouseOver={() => {
        if (state !== 2) setstate(1);
      }}
      onMouseOut={() => {
        if (state !== 2) setstate(0);
      }}
      onMouseUp={copy}
    >
      <div>
        <SvgSelector svg={"social-email"} />
        <p>Электропочта</p>
      </div>
      <GridWrapper>
        <SpanRoleEmail active={state === 0}>{email}</SpanRoleEmail>
        <SpanRoleEmail active={state === 1}>Скопировать</SpanRoleEmail>
        <SpanRoleEmail active={state === 2}>Скопировано</SpanRoleEmail>
      </GridWrapper>
    </ItemE>
  );
};

const name = "@SupportBot";
const ItemTg = () => {
  const [state, setstate] = useState(true);

  return (
    <Item
      gap={8}
      href={helpLink}
      target="_blank"
      rel="noopener noreferrer"
      onMouseOver={() => setstate(false)}
      onMouseOut={() => setstate(true)}
    >
      <div>
        <SvgSelector svg={"social-telegram"} />
        <p>Телеграм</p>
      </div>
      <HoverTwoSpan active={state}>
        <SpanRole>{name}</SpanRole>
        <SpanRole>Написать</SpanRole>
      </HoverTwoSpan>
    </Item>
  );
};
