import api from "./api";

export const putCardtoFav = (isFav = false, id) => {
  try {
    isFav
      ? api.delete(`api/fonts/fonts/${id}/bookmark`)
      : api.put(`api/fonts/fonts/${id}/bookmark`);
  } catch (error) {
    console.log("error: ", error);
  }
};
