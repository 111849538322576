import { useAccess } from "@/hooks/useAccess";
import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import SvgSelector from "@/shared/UI/SvgSelector";
import { isOverdue } from "@/shared/constants/access";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import moment from "moment";
import { useMemo, useState } from "react";
import styled from "styled-components";

export const DaysButton = ({
  dateEnd,
  handlecloseUserPopup,
  handleOpenPaymentPopup,
}) => {
  const [hovered, setHovered] = useState(false);

  const [access] = useAccess();

  const date = useMemo(() => getRemainDate(dateEnd), [dateEnd]);

  return (
    <Days
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        handlecloseUserPopup();
        handleOpenPaymentPopup();
      }}
    >
      <SvgSelector svg={"logo-fonts"} />
      <div>
        {isOverdue(access) ? (
          <span>Оплатить</span>
        ) : (
          <HoverTwoSpan active={hovered}>
            <span>Продлить</span>
            <span>{date}</span>
          </HoverTwoSpan>
        )}
        <SvgSelector svg={"user-fonts-button-arrow"} />
      </div>
    </Days>
  );
};

const getRemainDate = (dateEnd) => {
  const dateA = moment(dateEnd);
  const dateB = moment();

  const years = dateA.diff(dateB, "years", true);
  const months = dateA.diff(dateB, "months", true);
  const days = dateA.diff(dateB, "days");

  if (years > 1) {
    return Intl.NumberFormat("ru", {
      style: "unit",
      unit: "year",
      unitDisplay: "long",
    }).format(Math.round(years));
  } else if (months > 1) {
    return Intl.NumberFormat("ru", {
      style: "unit",
      unit: "month",
      unitDisplay: "long",
    }).format(Math.round(months));
  } else if (days > 0) {
    return Intl.NumberFormat("ru", {
      style: "unit",
      unit: "day",
      unitDisplay: "long",
    }).format(days);
  } else {
    return "Последний день";
  }
};

const Days = styled.button`
  padding: 14px 16px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  margin-bottom: 16px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.25vw;
    padding: 1.23vw 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.56vw;
    padding: 1.54vw 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.15vw;
    padding: 2.12vw 2.15vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 5vw;
    padding: 4.38vw 5vw;
  }

  & > svg {
    width: 69px;
    height: 20px;
    & path {
      fill-opacity: 0.4;
    }
    @media ${breakpoints.desktopXl} {
      height: 1.56vw;
      width: 5.39vw;
    }
    @media ${breakpoints.tabletL} {
      height: 1.95vw;
      width: 6.738vw;
    }
    @media ${breakpoints.tabletM} {
      height: 2.68vw;
      width: 9.27vw;
    }
    @media ${breakpoints.mobile} {
      height: 6.25vw;
      width: 21.56vw;
    }
  }
  background-color: var(--wh02);
  &:hover {
    background-color: var(--wh04);
  }
  &:active {
    background-color: var(--wh08);
  }
  & > div {
    color: var(--wh6);
    text-align: right;
    ${fonts.manrope5}
    ${mixins.fs10};
    line-height: 120%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    ${mixins.gap8}
  }
`;
