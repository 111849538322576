import { useEffect, useState } from "react";
import { FavButton } from "../FavButton";
import { FontTooltip } from "../Tooltip";
import { Author, CardFooter, FontName } from "../card-components";
import { FontItem } from "./FontItem";
import { Box, FontList, Head, Main, Settings } from "./components";
import { ButtonDownloadOrLock } from "../ButtonDownload";
import SvgSelector from "@/shared/UI/SvgSelector";
import { SwitchUpperCase } from "./SwitchUpperCase";
import { SizeRange } from "./SizeRange";
import { CloseButton, Input, InputWrapper } from "@/components/search/components";

export const CardOpen = ({ data }) => {
  const [text, setText] = useState(
    data?.preview_text || "Здесь можно примерить свой текст, если нужно."
  );

  const [upper, setUpper] = useState(data?.preview_case === "upper" || false);
  const [size, setSize] = useState(48);

  useEffect(() => {
    if (data && data.variations) {
      data.variations.forEach((font) => {
        const typeface = new FontFace(
          `${data.name} ${font.name}`,
          `url(${font.preview_file})`
        );
        document.fonts.add(typeface);
      });
    }
  }, []);

  return (
    <Box>
      <Head>
        <FavButton dialog={true} fav={data?.is_bookmarked} cardId={data?.id} />
        <FontName>{data?.name}</FontName>
        <CardFooter>
          <FontTooltip
            text={
              data?.is_semi_free
                ? "SemiFree (Личн) — шрифт для персонального использования, разрешён для учебных, личных и некоммерческих проектов"
                : "Free (Фри) — полностью бесплатный шрифт, разрешён для использования в любых проектах без ограничений"
            }
          />
          <Author
            href={data?.author_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.isFree || "Semi"}Free by {data?.author_name}
          </Author>
        </CardFooter>
      </Head>
      <Main>
        <Settings>
          <InputWrapper>
            <SvgSelector svg={"open-card-tt"} />
            <Input
              value={text}
              onChange={(e) => setText(e.target.value)}
              type="text"
              name="try-text"
              id="try-input"
              placeholder={data?.preview_text}
            />
            <CloseButton show={text !== ""} onClick={() => setText("")}>
              <SvgSelector svg={"close-search"} />
            </CloseButton>
          </InputWrapper>
          <SizeRange value={size} setValue={setSize} />
          <SwitchUpperCase
            text_case={data?.preview_case}
            upper={upper}
            setUpper={setUpper}
          />
          <ButtonDownloadOrLock downloadUrl={data?.download_url} />
        </Settings>
        <FontList>
          <div>
            {data?.variations.map((font) => (
              <FontItem
                key={font.id}
                fontsize={size}
                upper={upper}
                text_case={data?.preview_case}
                title={font.name}
                fontfamily={`${data.name} ${font.name}`}
                text={text}
              />
            ))}
          </div>
        </FontList>
      </Main>
    </Box>
  );
};
