import api from "@/services/api";
import {
  fetchTeamError,
  fetchTeamLoading,
  fetchTeamSuccess,
} from "../fetchSlices/teamSlice";

export const fetchTeam = (key) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTeamLoading(true));
      const res = await api.get(`api/${key ?? "fonts"}/team`, {
        params: {
          product: "foundation",
        },
      });
      dispatch(fetchTeamLoading(false));

      if (res.status === 200) {
        dispatch(fetchTeamSuccess({ team: res.data, key }));
      } else {
        dispatch(fetchTeamError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchTeamError(error.message));
      console.log(error);
    }
  };
};
