import Spinner from "@/shared/UI/Spinner";
import { isPromo } from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Team } from "./team/Team";
import { ArtelPlus } from "./artelplus/ArtelPlus";

export const Footer = () => {
  const { pathname } = useRouter();
  const { loading } = useSelector((state) => state.cards);
  return (
    <FooterSt animate={isPromo(pathname)}>
      {isPromo(pathname) || <ArtelPlus />}
      <Team />
      {loading && <Spinner />}
    </FooterSt>
  );
};

const FooterSt = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 27px 0;
  z-index: 1;

  ${({ animate }) =>
    animate && `opacity: 0; animation: showFooter 800ms ease 800ms 1 forwards;`}
  @media ${breakpoints.desktopXl} {
    padding: 2.111vw 0;
  }
  @media ${breakpoints.tabletL} {
    padding: 2.639vw 0;
  }
  @media ${breakpoints.tabletM} {
    padding: 3.634vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 5.016vw 0;
  }

  & > svg {
    @media ${breakpoints.desktopXl} {
      height: 2.3vw;
      width: auto;
    }
    @media ${breakpoints.tabletL} {
      height: 2.8vw;
    }

    @media ${breakpoints.tabletM} {
      height: 3.8vw;
    }
    @media ${breakpoints.mobile} {
      height: 8.8vw;
    }
  }

  @keyframes showFooter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
