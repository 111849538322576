import Layout from "@/components/Layout";
import { Desc } from "@/components/header/userContent/settings/components";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { Component } from "react";
import styled from "styled-components";
import { helpLink } from './constants/links';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // setTimeout(() => {
    //   this.setState({ hasError: true });
    // }, 1000);

    if (hasError) {
      return <ErrorFallback />;
    }

    return children;
  }
}

export default ErrorBoundary;

const ErrorFallback = () => (
  <Layout logoSvg={"logo-error"}>
    <ErrorWrapper>
      <Desc>
        На сайте произошла <br /> какая-то непредвиденная фигня.
        <br />
        Попробуйте перезагрузить страницу или вернуться на главную
      </Desc>
      <a href={helpLink} target="_blank" rel="noopener noreferrer">
        Поддержка
      </a>
    </ErrorWrapper>
  </Layout>
);

const ErrorWrapper = styled.div`
  text-align: center;
  padding-top: 30vh;
  max-width: 20%;
  ${mixins.fs14}
  @media ${breakpoints.mobile} {
    max-width: 55%;
  }
  & ${Desc} {
    margin-bottom: 24px;
    @media ${breakpoints.mobile} {
      margin-bottom: 7.524vw;
    }
  }

  & > a {
    ${mixins.fs10}
    transition: color 0.3s;
    color: var(--wh12);
    &:hover {
      color: var(--wh2);
    }
    &:active {
      color: var(--wh3);
    }
  }
`;
