import { createSlice } from "@reduxjs/toolkit";

const campusPageSlice = createSlice({
  name: "campusPage",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },
  reducers: {
    fetchCampusPageSuccess(state, action) {
      state.data = action.payload;
    },
    fetchCampusPageLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCampusPageError(state, action) {
      state.error = action.payload;
    },
  },
});

export const campusPageReducer = campusPageSlice.reducer;
export const {
  fetchCampusPageSuccess,
  fetchCampusPageLoading,
  fetchCampusPageError,
} = campusPageSlice.actions;
