import { DialogSt } from "@/components/header/UserDropdown";
import { Box } from "@/components/header/userContent/components";
import { Head } from "@/components/index-page/search/popup/components";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const ArtelPlusDialog = styled(DialogSt)`
  & .MuiPaper-root {
    @media ${breakpoints.mobile} {
      overflow: visible;
    }
  }
  & .MuiDialog-container {
    align-items: flex-end;
    justify-content: flex-start;
    padding-left: 55px;
    padding-bottom: 55px;
    @media ${breakpoints.desktopXl} {
      padding-left: 4.3vw;
      padding-bottom: 4.3vw;
    }
    @media ${breakpoints.tabletL} {
      padding-left: 5.376vw;
      padding-bottom: 5.376vw;
    }
    @media ${breakpoints.tabletM} {
      padding-left: 7.402vw;
      padding-bottom: 7.402vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0;
    }
  }
`;

export const HeadA = styled(Head)`
  color: var(--wh3);
  ${fonts.manrope6}
  ${mixins.fs16}
  letter-spacing: -0.16px;
  width: 100%;
  padding: 24px 24px 0;
  margin-bottom: 16px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.25vw;
    padding: 1.88vw 1.88vw 0;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.56vw;
    padding: 2.35vw 2.35vw 0;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.15vw;
    padding: 3.23vw 3.23vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 7.52vw;
    margin: 0;
    align-items: center;
  }
`;

export const Title = styled.h3`
  color: var(--wh3);
  ${fonts.manrope7}
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-self: flex-start;
  padding: 0 8px;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.63vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 1.08vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 2.5vw;
  }
`;

export const Main = styled.main`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 20px;
  @media ${breakpoints.desktopXl} {
    gap: 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.96vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.69vw;
  }
  @media ${breakpoints.mobile} {
    gap: 6.27vw;
    padding-bottom: 20vw;
    max-height: 100%;
    justify-content: center;
  }
`;

export const BoxA = styled(Box)`
  height: 381px;
  background-color: var(--wh04);

  @media ${breakpoints.desktopXl} {
    height: 29.79vw;
  }
  @media ${breakpoints.tabletL} {
    height: 37.24vw;
  }
  @media ${breakpoints.tabletM} {
    height: 51.28vw;
  }
  @media ${breakpoints.mobile} {
    height: 100%;
    margin-top: 0;
    position: relative;
    overflow: visible;
  }
  & .MuiDialogContent-root {
    width: 100%;
    padding: 0 24px 24px;
    @media ${breakpoints.desktopXl} {
      padding: 0 1.88vw 1.88vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0 2.35vw 2.35vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 0 3.23vw 3.23vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0 7.52vw 7.52vw;
    }
  }
`;

export const Desc = styled.p`
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  padding-left: 8px;
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.63vw;
    padding-left: 0.63vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 0.78vw;
    padding-left: 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    padding-left: 1.08vw;
    margin-bottom: 1.08vw;
  }
  @media ${breakpoints.mobile} {
    padding-left: 2.51vw;
    margin-bottom: 2.51vw;
  }
`;
export const HoverLink = styled.span`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
  & > svg {
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.9375vw;
      height: 0.9375vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.17vw;
      height: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.6vw;
      height: 1.6vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.75vw;
      height: 3.75vw;
    }
    & path {
      fill-opacity: 0.3;
    }
  }
`;

export const Wrap8 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${mixins.gap8}
  width: 100%;
  & ~ svg {
    width: 45px;
    height: 24px;
    margin-top: 12px;
    @media ${breakpoints.desktopXl} {
      width: 3.518vw;
      height: 1.876vw;
      margin-top: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.399vw;
      height: 2.346vw;
      margin-top: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      width: 6.057vw;
      height: 3.23vw;
      margin-top: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 5vw;
      width: 14.107vw;
      height: 7.524vw;
    }
  }
`;
export const Wrap8Box = styled.div`
  border-radius: 18px;
  width: 100%;
  background-color: var(--wh02);
  padding: 4px 0;
  overflow: hidden;

  @media ${breakpoints.desktopXl} {
    border-radius: 1.41vw;
    padding: 0.313vw 0;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 1.76vw;
    padding: 0.391vw 0;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.42vw;
    padding: 0.538vw 0;
  }
  @media ${breakpoints.mobile} {
    border-radius: 5.64vw;
    padding: 1.254vw 0;
  }
  ${({ disablePadding }) => disablePadding && `padding:0 !important;`}
`;

export const Help = styled.a`
  position: absolute;
  transition: color 0.3s;
  color: var(--wh3);
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  color: var(--wh3);
  &:hover {
    color: var(--wh6);
  }
  &:active {
    color: var(--wh3);
  }
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 4px;
  bottom: 16px;
  @media ${breakpoints.desktopXl} {
    bottom: 1.25vw;
    padding: 0.63vw 0.31vw;
  }
  @media ${breakpoints.tabletL} {
    bottom: 1.56vw;
    padding: 0.78vw 0.39vw;
  }
  @media ${breakpoints.tabletM} {
    bottom: 2.15vw;
    padding: 1.08vw 0.54vw;
  }
  @media ${breakpoints.mobile} {
    bottom: 5.02vw;
    padding: 2.51vw 1.25vw;
  }
`;

export const BackButton = styled.button`
  text-align: left;
  ${fonts.artel4}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  color: var(--wh3);
  display: flex;
  align-items: center;
  align-self: center;
  width: fit-content;
  & > svg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.94vw;
      height: 0.94vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.17vw;
      height: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.62vw;
      height: 1.62vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.76vw;
      height: 3.76vw;
    }
  }
  transition: color 0.3s;
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    color: var(--wh6);
    & path {
      stroke-opacity: 0.6;
    }
  }
  &:active {
    color: var(--wh3);
    & path {
      stroke-opacity: 0.3;
    }
  }
`;

export const ArtelPlusButton = styled.button`
  position: absolute;
  left: 0;
  height: 100%;
  width: 18.81vw;
  bottom: 50%;
  transform: translate(-100%, 50%);
  z-index: 1;
  &::before {
    background-color: #141414;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55%;
    z-index: -1;
  }
  & > svg {
    transform: translateY(1.25vw);
    width: 100%;
    height: 100%;
  }
`;
