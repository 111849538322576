import { breakpoints } from "@/styles/variables/variables";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100%;
  width: 100%;
  max-width: 1360px;

  @media ${breakpoints.desktopXl} {
    max-width: 85vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 79vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 72vw;
  }
  @media ${breakpoints.mobile} {
    margin-left: 3.762vw;
    max-width: 80vw;
  }
`;

export default Wrapper;
