import { createSlice } from "@reduxjs/toolkit";

const hbTagsSlice = createSlice({
  name: "hbTags",
  initialState: {
    checked: [],
    related: [],
    sections: [],
    popular: [],
    loading: false,
    error: "",
    indexOfOverflow: 0,
  },
  reducers: {
    fetchHbCuratedTagsSuccess(state, action) {
      state.sections = action.payload.sections;
      state.popular = action.payload.popular;
    },
    fetchHbRelatedTagsSuccess(state, action) {
      state.related = action.payload;
    },
    fetchHbTagsLoading(state, action) {
      state.loading = action.payload;
    },
    fetchHbTagsError(state, action) {
      state.error = action.payload;
    },
    deleteHbCheckedTag(state, action) {
      state.checked = state.checked.filter((el) => el.id !== action.payload.id);
    },
    addHbCheckedTag(state, action) {
      state.checked.push(action.payload);
    },
    resetHbCheckedTags(state) {
      state.checked = [];
    },
    setIndexOfOverflow(state, action) {
      state.indexOfOverflow = Number(action.payload);
    },
  },
});

export const hbTagsReducer = hbTagsSlice.reducer;
export const {
  setIndexOfOverflow,
  fetchHbCuratedTagsSuccess,
  fetchHbTagsLoading,
  fetchHbRelatedTagsSuccess,
  fetchHbTagsError,
  resetHbCheckedTags,
  addHbCheckedTag,
  deleteHbCheckedTag,
} = hbTagsSlice.actions;
