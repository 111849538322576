import SvgSelector from "@/shared/UI/SvgSelector";
import { New } from "./card-components";
import moment from "moment";

const dateLessWeek = (date) => {
  const publishedDate = moment(date);
  const now = moment();
  const days = now.diff(publishedDate, "days");
  return days < 8;
};

export const IsNew = ({ date }) => {
  return (
    dateLessWeek(date) && (
      <New>
        <SvgSelector svg={"new"} />
      </New>
    )
  );
};
