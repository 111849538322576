import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Fade } from "@mui/material";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AvatarS, Title, Wrap8 } from "./components";
import { getTeamKey } from "./getTeamKey";

const convertMemberAvatars = (data) => {
  if (data) {
    let groups = [];
    data.forEach((group) => {
      groups = groups.concat(group.members);
    });
    return [...new Map(groups.map((item) => [item.user, item])).values()]
      .map((el) => el.avatar)
      .slice(0, 6);
  }
};

export const RolesButton = ({ setOpenTeam }) => {
  const [hover, setHover] = useState(false);
  const { pathname } = useRouter();
  const data = useSelector((state) => state.team.data[getTeamKey(pathname)]);

  const avatars = useMemo(() => convertMemberAvatars(data), [data]);

  return (
    <Fade in={true} timeout={700}>
      <Wrap8>
        <Title>В главных ролях</Title>
        <Button
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setOpenTeam(true)}
        >
          <Avatars>
            {avatars?.map((avatar, i) => (
              <Avatar
                hover={hover}
                index={i}
                key={i}
                avatar={avatar ?? "/assets/avatar.jpg"}
              />
            ))}
          </Avatars>
          <div>
            <span>Посмотреть всех</span>
            <SvgSelector svg={"user-fonts-button-arrow"} />
          </div>
        </Button>
      </Wrap8>
    </Fade>
  );
};

const Avatars = styled.ul`
  display: flex;
`;

const Avatar = styled(AvatarS)`
  transition: transform 0.3s;
  ${({ hover, index }) =>
    hover
      ? `transform: translateX(calc((-15%) * ${index}));`
      : `transform: translateX(calc((-25%) * ${index}));`}
  ${({ index }) => `z-index: ${10 - index};`}
`;
const Button = styled.button`
  padding: 14px 16px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.63vw;
    padding: 1.23vw 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 0.78vw;
    padding: 1.54vw 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.08vw;
    padding: 2.13vw 2.15vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 2.51vw;
    padding: 4.39vw 5.02vw;
  }

  & > svg {
    width: 69px;
    height: 20px;
    & path {
      fill-opacity: 0.4;
    }
    @media ${breakpoints.desktopXl} {
      height: 1.56vw;
      width: 5.39vw;
    }
    @media ${breakpoints.tabletL} {
      height: 1.95vw;
      width: 6.738vw;
    }
    @media ${breakpoints.tabletM} {
      height: 2.68vw;
      width: 9.27vw;
    }
    @media ${breakpoints.mobile} {
      height: 6.25vw;
      width: 21.56vw;
    }
  }
  background-color: var(--wh02);
  &:hover {
    background-color: var(--wh04);
  }
  &:active {
    background-color: var(--wh08);
    & ${Avatar} {
      transform: none !important;
    }
  }
  & > div {
    color: var(--wh3);
    text-align: right;
    ${fonts.artel4}
    ${mixins.fs10};
    line-height: 120%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    ${mixins.gap8}
  }
`;
