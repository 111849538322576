import api from "@/services/api";
import {
  fetchUserError,
  fetchUserLoading,
  fetchUserSuccess,
} from "../fetchSlices/userSlice";

export const fetchUser = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserLoading(true));
      const res = await api.get("api/me/");
      dispatch(fetchUserLoading(false));

      if (res.status === 200) {
        dispatch(fetchUserSuccess(res.data));
      } else {
        dispatch(fetchUserError(res.statusText));
      }
    } catch (error) {
      dispatch(fetchUserError(error.message));
      console.log(error);
    }
  };
};
