import styled from "styled-components";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { Desc } from "@/components/header/userContent/settings/components";

export const CardNotFound = ({ bookmarkMode = false, isHandbook = false }) => (
  <WrapperNotFound isHandbook={isHandbook}>
    <Desc>
      {bookmarkMode ? "Вы пока ничего не добавили" : "Такого у нас нет"}
    </Desc>
    <img
      src={`/assets/not-found/${isHandbook ? "handbook" : "library"}.svg`}
      alt="Not Found"
    />
  </WrapperNotFound>
);

const WrapperNotFound = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  @media ${breakpoints.desktopXl} {
    gap: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    gap: 7.524vw;
  }

  & > img {
    object-fit: contain;
    height: 380px;
    width: 256px;
    margin: 0 auto;
    @media ${breakpoints.desktopXl} {
      width: 20.016vw;
      height: 29.711vw;
    }
    @media ${breakpoints.tabletL} {
      width: 25.024vw;
      height: 37.146vw;
    }
    @media ${breakpoints.tabletM} {
      width: 34.455vw;
      height: 51.144vw;
      width: 100%;
    }
    @media ${breakpoints.mobile} {
      width: 80.251vw;
      height: 119.122vw;
    }
    ${({ isHandbook }) =>
      isHandbook &&
      `
      height: 468px;
      @media ${breakpoints.desktopXl} {
        height: 36.591vw;
      }
      @media ${breakpoints.tabletL} {
        height: 45.748vw;
      }
      @media ${breakpoints.tabletM} {
        height: 62.988vw;
      }
      @media ${breakpoints.mobile} {
        height: 146.708vw;
      }
    `}
  }
  & > ${Desc} {
    ${mixins.fs12}
  }
`;
