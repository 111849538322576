import { showBookmarked } from "@/redux/fetchSlices/cardsSlice";
import { showHbBookmarked } from "@/redux/fetchSlices/hbCardsSlice";
import { resetFilterSearch, toggleSearchOpen } from "@/redux/searchInputSlice";
import { ButtonWithLabel } from "@/shared/UI/ButtonWithLabel";
import { isFoundation, isHandbook, isLibrary } from "@/shared/constants/routes";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

export const Bookmark = () => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { isBookmarked } = useSelector((state) =>
    isLibrary(pathname) ? state.cards : state.hbCards
  );

  return (
    (isLibrary(pathname) || isHandbook(pathname) || isFoundation(pathname)) && (
      <ButtonWithLabel
        location="tr"
        handleClick={() => {
          dispatch(
            isLibrary(pathname)
              ? showBookmarked(!isBookmarked)
              : showHbBookmarked(!isBookmarked)
          );
          dispatch(resetFilterSearch());
          dispatch(toggleSearchOpen(false));
        }}
        svg={"header-fav"}
        open={isBookmarked}
      >
        Избранное
      </ButtonWithLabel>
    )
  );
};
