export const ACCESS = {
  FULL: "full", // полный доступ
  OVERDUE: "overdue", // подписка просрочена и не оплачена
  UNKNOWN: "unknown", // незарегестрированный юзер
  LOADING: "loading", // незарегестрированный юзер
};

export const isFull = (access) => access === ACCESS.FULL;
export const isOverdue = (access) => access === ACCESS.OVERDUE;
export const isUnknown = (access) => access === ACCESS.UNKNOWN;
