import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";
import { SizeRangeS, UpWrapper } from "./components";
import SvgSelector from "@/shared/UI/SvgSelector";

export const SizeRange = ({ value, setValue }) => {
  const handleSliderChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const val = event.target.value;

    if (val === "") {
      setValue("");
    } else if (val[0] === "0") {
      setValue(val.slice(1));
    } else if (val > 200) {
      setValue(200);
    } else if (isNaN(Number(val))) {
      setValue(32);
    } else {
      setValue(Number(val));
    }
  };

  const handleKeyPress = (e) => {
    if (e.code === "ArrowUp") {
      setValue(value + 1);
    } else if (e.code === "ArrowDown") {
      setValue(value - 1);
    }
  };

  return (
    <SizeRangeS>
      <UpWrapper>
        <SvgSelector svg={"open-card-range"} />
        <Input
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          InputProps={{
            step: 1,
            min: 1,
            max: 200,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
        />
      </UpWrapper>
      <Slider
        value={typeof value === "number" ? value : 0}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        max={200}
        min={1}
      />
    </SizeRangeS>
  );
};
