import { configureStore } from "@reduxjs/toolkit";
import { prevSearchReducer } from "./prevSearchSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { searchInputReducer } from "./searchInputSlice";
import { cardsReducer } from "./fetchSlices/cardsSlice";
import { filterReducer } from "./filterSlice";
import { filtersReducer } from "./fetchSlices/filtersSlice";
import { userReducer } from "./fetchSlices/userSlice";
import { alertRetryReducer } from "./alertRetrySlice";
import { hbCardReducer } from "./fetchSlices/hbCardSlice";
import { teamReducer } from "./fetchSlices/teamSlice";
import { hbCardsReducer } from "./fetchSlices/hbCardsSlice";
import { hbTagsReducer } from "./fetchSlices/hbTagsSlice";
import { campusReducer } from "./fetchSlices/campusSlice";
import { campusPageReducer } from './fetchSlices/campusPageSlice';

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, prevSearchReducer);

export const store = configureStore({
  reducer: {
    prevSearch: persistedReducer,
    searchInput: searchInputReducer,
    cards: cardsReducer,
    filter: filterReducer,
    team: teamReducer,
    availableFilters: filtersReducer,
    user: userReducer,
    alertRetry: alertRetryReducer,
    hbCard: hbCardReducer,
    hbCards: hbCardsReducer,
    hbTags: hbTagsReducer,
    campus: campusReducer,
    campusPage: campusPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
