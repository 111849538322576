import { getCurrentState } from "@/services/utils";
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    loading: false,
    error: "",
  },
  reducers: {
    setUserSubscriptions(state, action) {
      const prevState = state.data;
      prevState.subscriptions = action.payload.subscriptions;

      state.data = prevState;
    },
    fetchUserSuccess(state, action) {
      state.data = action.payload;
    },
    fetchUserLoading(state, action) {
      state.loading = action.payload;
    },
    fetchUserError(state, action) {
      state.error = action.payload;
    },
    setUserName(state, action) {
      const data = getCurrentState(state.data);
      state.data = { ...data, display_name: action.payload };
    },
    setUserAvatar(state, action) {
      const data = getCurrentState(state.data);
      state.data = { ...data, avatar: action.payload };
    },
  },
});

export const userReducer = userSlice.reducer;
export const {
  fetchUserSuccess,
  fetchUserLoading,
  fetchUserError,
  setUserName,
  setUserSubscriptions,
} = userSlice.actions;
