import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const PromoButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      {"промокод".split("").map((letter, i) => (
        <Letter key={i}>
          <SvgSelector svg={"promocode-letter"} />
          <span>{letter}</span>
        </Letter>
      ))}
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  gap: 1px;
  padding: 4px;
  margin: 0 auto;
  @media ${breakpoints.desktopXl} {
    padding: 0.31vw;
    gap: 0.08vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.39vw;
    gap: 0.1vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.54vw;
    gap: 0.13vw;
  }
  @media ${breakpoints.mobile} {
    margin: 8.78vw auto 0;
    padding: 1.25vw;
    gap: 0.31vw;
  }

  transition: color 0.3s;
  color: var(--wh3);
  &:hover {
    color: var(--wh7);
  }
  &:active {
    color: var(--wh3);
  }
`;
const Letter = styled.div`
  width: 10px;
  height: 12px;
  @media ${breakpoints.desktopXl} {
    width: 0.78vw;
    height: 0.94vw;
  }
  @media ${breakpoints.tabletL} {
    width: 0.98vw;
    height: 1.17vw;
  }
  @media ${breakpoints.tabletM} {
    width: 1.35vw;
    height: 1.62vw;
  }
  @media ${breakpoints.mobile} {
    width: 3.13vw;
    height: 3.76vw;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & > span {
    text-transform: uppercase;
    ${fonts.artel6}
    ${mixins.fs7}
    line-height: 114%;
  }
`;
