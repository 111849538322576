import { useEffect, useState } from "react";
import styled from "styled-components";
import { LoginTabs } from "./loginTabs/LoginTabs";
import { Dialog, Fade, Slide, Zoom, useMediaQuery } from "@mui/material";
import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { Artel, CloseButton, PopupHeader } from "./loginTabs/components";
import { useAccess } from "@/hooks/useAccess";
import { isUnknown } from "@/shared/constants/access";
import { AlertRetry } from "./loginTabs/AlertRetry";

export const UserDropdown = ({ isNotPromo = true }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [access] = useAccess();

  useEffect(() => {
    if (isNotPromo && isUnknown(access)) setOpen(true);
  }, [access]);

  return (
    <>
      <ButtonUser onClick={handleOpen}>Войти</ButtonUser>
      <DialogSt
        fullScreen={isMobile}
        onClose={handleClose}
        id="user-menu"
        TransitionComponent={isMobile ? Slide : Zoom}
        TransitionProps={{ direction: "up" }}
        open={open}
      >
        {isMobile && (
          <Fade in={true} timeout={700}>
            <PopupHeader>
              <Artel>
                <SvgSelector svg={"logo-artel"} />
              </Artel>
              <CloseButton aria-label="Close" onClick={handleClose}>
                <SvgSelector svg={"close-popup"} />
              </CloseButton>
            </PopupHeader>
          </Fade>
        )}
        <LoginTabs />
      </DialogSt>
      <AlertRetry setOpenPopup={setOpen} />
    </>
  );
};

export const DialogSt = styled(Dialog)`
  z-index: 1302 !important;
  & .MuiPaper-root {
    box-shadow: none;
    margin: 0;
    background-color: transparent;
    overflow-y: hidden;
    max-width: none;
  }
  & .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.65);
  }
`;
const ButtonUser = styled.button`
  position: absolute;
  bottom: initial;
  left: initial;
  top: 12px;
  color: var(--wh6);
  ${mixins.fs14}
  line-height: 112%;
  letter-spacing: 0.43px;
  right: 16px;
  padding: 16px;
  transition: color 0.3s;
  &:hover {
    color: var(--wh4);
  }
  &:active {
    color: var(--wh3);
  }

  @media ${breakpoints.mobile} {
    right: 2vw;
    font-size: 3.762vw;
    padding: 1.254vw;
    top: 6.583vw;
  }
`;
