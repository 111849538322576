import { createSlice } from "@reduxjs/toolkit";

const prevSearchSlice = createSlice({
  name: "prevSearch",
  initialState: {
    data: [],
  },
  reducers: {
    addPrevSearch: (state, action) => {
      const prevSearch = state.data;
      if (!prevSearch.includes(action.payload)) {
        if (prevSearch.length === 3) prevSearch.shift();
        prevSearch.push(action.payload);
      }
    },

    removePrevSearch: (state, action) => {
      const filteredSearches = state.data.filter(
        (item) => item != action.payload
      );
      state.data = filteredSearches;
    },
  },
});

export const prevSearchReducer = prevSearchSlice.reducer;
export const { addPrevSearch, removePrevSearch } = prevSearchSlice.actions;
