import { CloseButton } from "@/components/header/loginTabs/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { helpLink } from "@/shared/constants/links";
import { ROUTES } from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import { DialogContent, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { ListItem, ListItemOpenCampus } from "./ListItem";
import {
  ArtelPlusButton,
  BackButton,
  BoxA,
  HeadA,
  Help,
  Main,
  Title,
  Wrap8,
  Wrap8Box,
} from "./components";

const classroom = [
  {
    title: "Айдентика через метафоры",
    label: "Курс",
    url: ROUTES.CAMPUS_CLASSROOM_GRAPHIC,
    svg: "campus-bird",
  },
  { title: "Артдирекшен" },
];

const services = [
  {
    title: "Шрифтотека",
    label: "Библиотека букв",
    url: ROUTES.LIBRARY,
  },
  { title: "Справочник" },
  { title: "Инвентарь" },
  { title: "Мокапы" },
];

export const ArtelPlusContent = ({ handleClose }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [openCampus, setOpenCampus] = useState(false);

  return (
    <BoxA>
      {isMobile && (
        <ArtelPlusButton>
          <SvgSelector svg={`artel-plus-button-mobile-dialog`} />
        </ArtelPlusButton>
      )}
      <HeadA>
        {openCampus && (
          <BackButton aria-label="back" onClick={() => setOpenCampus(false)}>
            <SvgSelector svg={"team-back"} />
            <span>Артель+</span>
          </BackButton>
        )}
        <span>{openCampus ? "Кампус" : "Артель+"}</span>

        {isMobile && (
          <CloseButton aria-label="Close" onClick={handleClose}>
            <SvgSelector svg={"close-popup"} />
          </CloseButton>
        )}
      </HeadA>
      <DialogContent scroll="paper">
        <Main>
          {openCampus ? (
            <Wrap8>
              <Title>Учебный класс</Title>
              <Wrap8Box>
                <ListItem data={classroom[0]} i={0} />
                {classroom.slice(1).map((item, i) => (
                  <ListItem key={i} data={item} i={i + 1} />
                ))}
              </Wrap8Box>
            </Wrap8>
          ) : (
            <>
              <Wrap8>
                <Title>Кампус</Title>
                <Wrap8Box disablePadding>
                  <ListItemOpenCampus onClick={() => setOpenCampus(true)} />
                </Wrap8Box>
              </Wrap8>
              <Wrap8>
                <Title>Сервисы</Title>
                <Wrap8Box>
                  <ListItem data={services[0]} i={0} />
                  {services.slice(1).map((item, i) => (
                    <ListItem key={i} data={item} i={i + 1} />
                  ))}
                </Wrap8Box>
              </Wrap8>
            </>
          )}
        </Main>
        <Help href={helpLink} target="_blank" rel="noopener noreferrer">
          Помощь
        </Help>
      </DialogContent>
    </BoxA>
  );
};
