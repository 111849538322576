import { useEffect, useState } from "react";
import { DialogAlert, Label } from "../userContent/settings/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { Zoom } from "@mui/material";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setOpenAlertRetry } from "@/redux/alertRetrySlice";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";

export const AlertRetry = ({ setOpenPopup }) => {
  const { open, retryTime } = useSelector((state) => state.alertRetry);
  const [tick, setTick] = useState(0);
  const dispatch = useDispatch();

  let interval;
  let timer;

  useEffect(() => {
    if (open && retryTime) {
      setTick(retryTime);
      setOpenPopup(false);
      interval = setInterval(() => {
        setTick((prev) => prev - 1);
      }, 1000);
      timer = setTimeout(() => {
        dispatch(setOpenAlertRetry(false));
        clearInterval(interval);
        setOpenPopup(true);
        setTick(undefined);
      }, retryTime * 1000);
    }
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [open, retryTime]);

  return (
    <Dialog
      onClose={() => {
        dispatch(setOpenAlertRetry(false));
        setOpenPopup(true);
        clearTimeout(timer);
        clearInterval(interval);
        setTick(undefined);
      }}
      time={retryTime}
      open={open}
      id="alert-retry"
      TransitionComponent={Zoom}
    >
      <SvgSelector svg="alert-file" />
      <span>Ещё {tick} сек.</span>
      <Label>
        Вы отправили слишком много писем,
        <br /> совам нужно отдохнуть
      </Label>
    </Dialog>
  );
};

const Dialog = styled(DialogAlert)`
  position: relative;
  & .MuiPaper-root {
    width: 370px;
    @media ${breakpoints.desktopXl} {
      width: 28.929vw;
    }
    @media ${breakpoints.tabletL} {
      width: 36.168vw;
    }
    @media ${breakpoints.tabletM} {
      width: 49.798vw;
    }
    @media ${breakpoints.mobile} {
      width: 85vw;
    }

    &::after {
      animation-duration: ${({ time }) => `${time}s;`};
    }
  }
  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.65);
  }
  & ${Label} {
    position: absolute;
    bottom: -70%;
    left: 4%;
    line-height: 110%;
    letter-spacing: 0.24px;
    ${fonts.manrope5};
    ${mixins.fs12};
    text-transform: none;
  }
`;
