export const social = [
  {
    type: "tg",
    url: "https://",
  },
  // {
  //   type: "yandex",
  //   url: "https://",
  // },
  // {
  //   type: "vk",
  //   url: "https://",
  // },
  // {
  //   type: "google",
  //   url: "https://",
  // },
  // {
  //   type: "mail",
  //   url: "https://",
  // },
  // {
  //   type: "fb",
  //   url: "https://",
  // },
];
