import { CloseButton } from "@/components/header/loginTabs/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints } from "@/styles/variables/variables";
import { DialogContent, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { HelpSupport } from "./HelpSupport";
import { BackButton, HeadS, Main, Requisites, Wr } from "./components";
import { Roles } from "./Roles";
import { BoxT } from "./components";
import { RolesButton } from "./RolesButton";
import { News } from "./News";

export const TeamContent = ({ handleClose }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const [openTeam, setOpenTeam] = useState(false);

  return (
    <BoxT>
      <HeadS>
        {openTeam && (
          <BackButton aria-label="back" onClick={() => setOpenTeam(false)}>
            <SvgSelector svg={"team-back"} />
            <span>Ну мы</span>
          </BackButton>
        )}
        <span>{openTeam ? "В главных ролях" : "Ну мы"}</span>

        {isMobile && (
          <CloseButton aria-label="Close" onClick={handleClose}>
            <SvgSelector svg={"close-popup"} />
          </CloseButton>
        )}
      </HeadS>
      <DialogContent scroll="paper">
        <Main>
          {openTeam ? (
            <Roles />
          ) : (
            <>
              <Wr>
                <HelpSupport />
                <RolesButton setOpenTeam={setOpenTeam} />
                <News />
                <Requisites>
                  ИНН 246214805759 ОГРНИП 324237500253378 <br /> ИП Павлушин
                  Андрей Андреевич
                </Requisites>
              </Wr>
            </>
          )}
        </Main>
      </DialogContent>
    </BoxT>
  );
};
