import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import { Skelet } from "@/shared/UI/Skelet";
import SvgSelector from "@/shared/UI/SvgSelector";
import { helpLink } from "@/shared/constants/links";
import { breakpoints } from "@/styles/variables/variables";
import { Fade } from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AvatarS, Item, SpanRole, Title, Wrap8, Wrap8Box } from "./components";
import { HoverLink } from "./components";
import { getTeamKey } from "./getTeamKey";

export const Roles = () => {
  const { pathname } = useRouter();
  const data = useSelector((state) => state.team.data[getTeamKey(pathname)]);

  return (
    <>
      {data ? (
        data.map((group, i) => (
          <Wrap8 key={i}>
            <Title>{group?.group}</Title>
            <Wrap8Box>
              {group?.members?.map((member, i) => (
                <ItemS key={i} data={member} i={i} />
              ))}
            </Wrap8Box>
          </Wrap8>
        ))
      ) : (
        <SkeletWr>
          {Array.from(Array(5)).map((i) => (
            <TeamSkelet key={i}>
              <Skelet />
            </TeamSkelet>
          ))}
        </SkeletWr>
      )}
    </>
  );
};

const ItemS = ({ data, i }) => {
  const [hover, setHovered] = useState(false);
  return (
    <Fade in={true} timeout={700} style={{ transitionDelay: 30 * i }}>
      <div>
        <Item
          gap={16}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          href={data.telegram_link ?? helpLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <AvatarS avatar={data.avatar ?? "/assets/avatar.jpg"} />
            <p>{data.display_name}</p>
          </div>
          <HoverTwoSpan active={hover}>
            <SpanRole>
              <HoverLink>
                <SvgSelector svg="social-telegram" />
                <span>Написать</span>
              </HoverLink>
            </SpanRole>
            <SpanRole>{data.role}</SpanRole>
          </HoverTwoSpan>
        </Item>
      </div>
    </Fade>
  );
};

const SkeletWr = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1em;
`;
const TeamSkelet = styled.div`
  height: 44px;
  @media ${breakpoints.desktopXl} {
    height: 3.44vw;
  }
  @media ${breakpoints.tabletL} {
    height: 4.301vw;
  }
  @media ${breakpoints.tabletM} {
    height: 5.922vw;
  }
  @media ${breakpoints.mobile} {
    height: 13.793vw;
  }
`;
