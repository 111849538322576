import { breakpoints } from "@/styles/variables/variables";
import styled from "styled-components";
import { CardFooter, CardMain } from "./card-components";
import { Skelet } from "@/shared/UI/Skelet";

export const CardSkeleton = () => (
  <SkeletonWrapper>
    <header>
      <Skelet borderRadius="24px" />
    </header>
    <CardMain>
      {[1, 2, 3, 4].map((i) => (
        <Skelet key={i} />
      ))}
    </CardMain>
    <CardFooter>
      <Skelet />
    </CardFooter>
  </SkeletonWrapper>
);

export const SkeletonWrapper = styled.section`
  border-radius: 24px;
  border: 1.7px solid var(--wh04);
  padding: 7px 7px 10px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: 6px;
  grid-template-rows: 1fr 0.95fr 12px;
  height: 380px;
  width: 256px;

  @media ${breakpoints.desktopXl} {
    grid-template-rows: 1fr 0.95fr 0.9375vw;
    padding: 0.546vw 0.546vw 0.78vw;
    gap: 0.468vw;
    height: 29.69vw;
    width: 20.016vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.68vw 0.68vw 0.98vw;
    grid-template-rows: 1fr 0.95fr 1.17vw;
    gap: 0.585vw;
    height: 37.15vw;
    width: 25.024vw;
  }
  @media ${breakpoints.tabletM} {
    grid-template-rows: 1fr 0.95fr 1.6vw;
    padding: 0.94vw 0.94vw 1.34vw;
    gap: 0.8vw;
    height: 51.144vw;
    width: 34.455vw;
  }
  @media ${breakpoints.mobile} {
    grid-template-rows: 1fr 0.95fr 3.75vw;
    gap: 1.875vw;
    padding: 2.19vw 2.19vw 3.125vw;
    height: 118.75vw;
    width: 80.251vw;
  }
  & > header {
    height: 99%;
    width: 100%;
  }
  & > main {
    & > span {
      &:nth-child(3),
      &:nth-child(4) {
        grid-column: span 2;
      }
    }
  }
  & > footer {
    width: 65%;
  }
`;
