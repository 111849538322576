import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    data: [],
    loading: false,
    error: "",
  },
  reducers: {
    fetchFiltersSuccess(state, action) {
      state.data = action.payload;
    },
    fetchFiltersLoading(state, action) {
      state.loading = action.payload;
    },
    fetchFiltersError(state, action) {
      state.error = action.payload;
    },
  },
});

export const filtersReducer = filtersSlice.reducer;
export const { fetchFiltersSuccess, fetchFiltersLoading, fetchFiltersError } =
  filtersSlice.actions;
