import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints } from "@/styles/variables/variables";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const Notification = ({ storageKey, mb = 0, children }) => {
  const { telegram } = useSelector(
    (state) => state.user.data?.auth_connections
  );

  const [show, setShow] = useState(
    (telegram || localStorage.getItem(storageKey)) ?? "true"
  );

  return (
    <Box mb={mb} show={show === "true"}>
      <Close
        aria-label="Close"
        onClick={() => {
          setShow("false");
          localStorage.setItem(storageKey, "false");
        }}
      >
        <SvgSelector svg={"notification-close"} />
      </Close>
      {children}
    </Box>
  );
};

const Box = styled.section`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--greenDark);
  background: var(--green04);
  box-shadow: 0px 0px 32px 0px var(--green16);
  position: relative;
  width: 100%;
  transition: height 0.3s, opacity 0.3s, margin-bottom 0.3s, padding 0.3s;
  margin-bottom: ${({ mb }) => mb}px;

  @media ${breakpoints.mobile} {
    padding: 5vw;
  }

  ${({ show }) =>
    show ||
    `
    margin-bottom: 0px;
    opacity: 0;
    pointer-events: none;
    padding: 0px !important;
    height: 0px;
    `}
`;

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 100%);
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    & path {
      stroke-opacity: 0.4;
    }
  }
  &:active {
    & path {
      stroke-opacity: 0.3;
    }
  }
  @media ${breakpoints.mobile} {
    width: 5vw;
    height: 5vw;
  }
`;
