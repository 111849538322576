import { InfiniteLoader } from "./card/InfiniteLoader";
import { CardDialog } from "./card/cardOpen/components";
import { CardOpen } from "./card/cardOpen/CardOpen";
import { Zoom, useMediaQuery } from "@mui/material";
import { setActiveCard } from "@/redux/fetchSlices/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { breakpoints } from "@/styles/variables/variables";
import { useAccess } from "@/hooks/useAccess";
import { isFull, isOverdue, isUnknown } from "@/shared/constants/access";
import { CardsLocked } from "./card/CardsLocked";

const CardsContainer = () => {
  const [access] = useAccess();

  const dispatch = useDispatch();

  const isMobile = useMediaQuery(breakpoints.mobile);

  const { activeCard } = useSelector((state) => state.cards);

  return (
    <>
      {isFull(access) && <InfiniteLoader />}
      {(isOverdue(access) || isUnknown(access)) && <CardsLocked />}
      {isMobile || (
        <CardDialog
          id="card-popup-menu"
          TransitionComponent={Zoom}
          onClose={() => dispatch(setActiveCard(undefined))}
          open={activeCard !== undefined}
          scroll={"paper"}
        >
          <CardOpen data={activeCard} />
        </CardDialog>
      )}
    </>
  );
};

export default CardsContainer;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @media ${breakpoints.desktopXl} {
    gap: 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.95vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 2.69vw;
  }
  @media ${breakpoints.mobile} {
    gap: 12.5vw;
  }
`;
export const Container = styled.div`
  display: grid;
  gap: 40px 20px;
  grid-template-columns: repeat(5, 1fr);
  /* grid-auto-rows: 380px; */
  @media ${breakpoints.desktopXl} {
    gap: 3.125vw 1.56vw;
    grid-template-columns: repeat(4, 1fr);
    /* grid-auto-rows: 27.94vw; */
  }
  @media ${breakpoints.tabletL} {
    gap: 3.9vw 1.95vw;
    grid-template-columns: repeat(3, 1fr);
    /* grid-auto-rows: 37.5vw; */
  }

  @media ${breakpoints.tabletM} {
    gap: 5.376vw 2.69vw;
    grid-template-columns: repeat(2, 1fr);
    /* grid-auto-rows: 51.7vw; */
  }

  @media ${breakpoints.mobile} {
    gap: 12.5vw;
    grid-template-columns: 1fr;
    /* grid-auto-rows: 119.3vw; */
  }
`;
