import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { DialogSt } from "@/components/header/UserDropdown";
import { Box } from "@/components/header/userContent/components";
import { Head } from "@/components/index-page/search/popup/components";
import { Avatar } from "@/components/header/userContent/settings/components";

export const TeamDialog = styled(DialogSt)`
  & .MuiDialog-container {
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 55px;
    padding-bottom: 55px;
    @media ${breakpoints.desktopXl} {
      padding-right: 4.3vw;
      padding-bottom: 4.3vw;
    }
    @media ${breakpoints.tabletL} {
      padding-right: 5.376vw;
      padding-bottom: 5.376vw;
    }
    @media ${breakpoints.tabletM} {
      padding-right: 7.402vw;
      padding-bottom: 7.402vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0;
    }
  }
`;

export const HeadS = styled(Head)`
  color: var(--wh3);
  ${fonts.manrope6}
  ${mixins.fs16}
  letter-spacing: -0.16px;
  width: 100%;
  padding: 28px 24px 0;
  @media ${breakpoints.desktopXl} {
    padding: 2.189vw 1.876vw 0;
  }
  @media ${breakpoints.tabletL} {
    padding: 2.737vw 2.346vw 0;
  }
  @media ${breakpoints.tabletM} {
    padding: 3.769vw 3.23vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 7.52vw;
    margin: 0;
    align-items: center;
  }
`;

export const Title = styled.h3`
  color: var(--wh3);
  ${fonts.manrope7}
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-self: flex-start;
  padding: 0 8px;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.63vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0 1.08vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 2.5vw;
  }
`;

export const Main = styled.main`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 12px;
  @media ${breakpoints.desktopXl} {
    gap: 0.94vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 1.17vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 1.62vw;
  }
  @media ${breakpoints.mobile} {
    padding-bottom: 20vw;
    gap: 3.76vw;
    max-height: 100%;
    justify-content: center;
  }
`;

export const BoxT = styled(Box)`
  height: 432px;
  background-color: var(--wh04);

  @media ${breakpoints.desktopXl} {
    height: 33.78vw;
  }
  @media ${breakpoints.tabletL} {
    height: 42.23vw;
  }
  @media ${breakpoints.tabletM} {
    height: 58.14vw;
  }
  @media ${breakpoints.mobile} {
    height: 100%;
    margin-top: 0;
  }
  & .MuiDialogContent-root {
    width: 100%;
    padding: 0 24px 24px;
    @media ${breakpoints.desktopXl} {
      padding: 0 1.88vw 1.88vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0 2.35vw 2.35vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 0 3.23vw 3.23vw;
    }
    @media ${breakpoints.mobile} {
      padding: 0 7.52vw 7.52vw;
    }
  }
`;

export const Desc = styled.p`
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  padding-left: 8px;
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.63vw;
    padding-left: 0.63vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 0.78vw;
    padding-left: 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    padding-left: 1.08vw;
    margin-bottom: 1.08vw;
  }
  @media ${breakpoints.mobile} {
    padding-left: 2.51vw;
    margin-bottom: 2.51vw;
  }
`;
export const HoverLink = styled.span`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
  & > svg {
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.9375vw;
      height: 0.9375vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.17vw;
      height: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.6vw;
      height: 1.6vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.75vw;
      height: 3.75vw;
    }
    & path {
      fill-opacity: 0.3;
    }
  }
`;

export const Wrap8 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${mixins.gap8}
  width: 100%;
  & ~ svg {
    width: 45px;
    height: 24px;
    margin-top: 12px;
    @media ${breakpoints.desktopXl} {
      width: 3.518vw;
      height: 1.876vw;
      margin-top: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.399vw;
      height: 2.346vw;
      margin-top: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      width: 6.057vw;
      height: 3.23vw;
      margin-top: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      margin-top: 5vw;
      width: 14.107vw;
      height: 7.524vw;
    }
  }
`;
export const Wrap8Box = styled.div`
  width: 100%;
  background-color: var(--wh02);
  padding: 4px 0;
  overflow: hidden;
  border-radius: 18px;

  @media ${breakpoints.desktopXl} {
    border-radius: 1.41vw;
    padding: 0.31vw 0;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 1.76vw;
    padding: 0.39vw 0;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.42vw;
    padding: 0.54vw 0;
  }
  @media ${breakpoints.mobile} {
    padding: 1.25vw 0;
    border-radius: 5.64vw;
  }
`;

export const AvatarS = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin-right: 0;

  @media ${breakpoints.desktopXl} {
    width: 1.876vw;
    height: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    width: 2.346vw;
    height: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    width: 3.23vw;
    height: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    width: 7.524vw;
    height: 7.524vw;
  }
`;

const ItemTitle = styled.a`
  pointer-events: none;
  /* background-color: var(--wh02); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 20px;
  @media ${breakpoints.desktopXl} {
    padding: 0.782vw 1.251vw 0.782vw 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.978vw 1.564vw 0.978vw 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.346vw 2.153vw 1.346vw 2.692vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.135vw 4.5vw;
  }
  & > div:first-child {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 24px auto;
    @media ${breakpoints.desktopXl} {
      grid-template-columns: 1.876vw auto;
    }
    @media ${breakpoints.tabletL} {
      grid-template-columns: 2.346vw auto;
    }
    @media ${breakpoints.tabletM} {
      grid-template-columns: 3.23vw auto;
    }
    @media ${breakpoints.mobile} {
      grid-template-columns: 7.524vw auto;
    }
    color: var(--wh6);
    ${fonts.manrope5}
    ${mixins.fs12}
    line-height: 116%;
    letter-spacing: 0.24px;
    & > svg {
      width: 16px;
      height: 16px;
      @media ${breakpoints.desktopXl} {
        width: 1.251vw;
        height: 1.251vw;
      }
      @media ${breakpoints.tabletL} {
        width: 1.564vw;
        height: 1.564vw;
      }
      @media ${breakpoints.tabletM} {
        width: 2.153vw;
        height: 2.153vw;
      }
      @media ${breakpoints.mobile} {
        width: 5.016vw;
        height: 5.016vw;
      }
    }
    ${({ gap }) => `
    gap: ${gap}px;
  @media ${breakpoints.desktopXl} {
    gap: ${gap * 0.078}vw;
  }
  @media ${breakpoints.tabletL} {
    gap: ${gap * 0.098}vw;
  }
  @media ${breakpoints.tabletM} {
    gap: ${gap * 0.1346}vw;
  }
  @media ${breakpoints.mobile} {
    gap: ${gap * 0.31348}vw;
  }
    `}
  }
`;

export const SpanRole = styled.span`
  color: var(--wh3);
  text-align: right;
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  pointer-events: none;
`;
export const GridWrapper = styled.div`
  display: grid;
`;
export const SpanRoleEmail = styled(SpanRole)`
  grid-column: 1;
  grid-row: 1;
  transition: transform 0.3s, opacity 0.3s;
  ${({ active }) => `
  opacity: ${active ? 1 : 0};
  &:first-of-type {
    transform: translateY(${active ? "0" : "30px"});
  }
  &:nth-of-type(2) {
    transform: translateY(${active ? "0" : "-30px"});
  }
  &:nth-of-type(3) {
    transform: translateY(${active ? "0" : "-30px"});
  }
  `};
`;

export const Item = styled(ItemTitle)`
  pointer-events: auto;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
`;
export const ItemE = styled(Item)`
  position: relative;
  ${({ animated }) => animated && `pointer-events: none;`}
  &::after {
    content: "";
    top: 0;
    height: 100%;
    left: 0;
    position: absolute;
    background-color: var(--wh04);
    ${({ animated }) => animated && `animation: showBg 4s linear 1 forwards;`}
  }
  @keyframes showBg {
    0% {
      right: 100%;
    }
    100% {
      right: 0;
    }
  }
`;

export const ErrorLink = styled.a`
  ${fonts.manrope5}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.2px;
  margin-top: 8px;
  @media ${breakpoints.desktopXl} {
    margin-top: 0.625vw;
  }
  @media ${breakpoints.tabletL} {
    margin-top: 0.782vw;
  }
  @media ${breakpoints.tabletM} {
    margin-top: 1.077vw;
  }
  @media ${breakpoints.mobile} {
    margin-top: 2.508vw;
  }
  & > span {
    transition: color 0.3s;
    color: var(--wh4);
    &:hover {
      color: var(--wh2);
    }
    &:active {
      color: var(--wh12);
    }
  }
`;
export const Wr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media ${breakpoints.mobile} {
    gap: 6.27vw;
    justify-content: center;
  }
`;
export const Requisites = styled.p`
  text-align: center;
  ${fonts.artel4}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  color: var(--wh3);
  @media ${breakpoints.mobile} {
    bottom: 7.52vw;
    position: absolute;
    width: 100%;
    left: 0;
  }
`;
export const BackButton = styled.button`
  text-align: left;
  ${fonts.artel4}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 0.03em;
  color: var(--wh3);
  display: flex;
  align-items: center;
  align-self: center;
  width: fit-content;
  & > svg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.94vw;
      height: 0.94vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.17vw;
      height: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.62vw;
      height: 1.62vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.76vw;
      height: 3.76vw;
    }
  }
  transition: color 0.3s;
  & path {
    transition: stroke-opacity 0.3s;
  }
  &:hover {
    color: var(--wh6);
    & path {
      stroke-opacity: 0.6;
    }
  }
  &:active {
    color: var(--wh3);
    & path {
      stroke-opacity: 0.3;
    }
  }
`;
