import { DialogSt } from "@/components/header/UserDropdown";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const SearchDialogSt = styled(DialogSt)``;

export const ButtonTune = styled.button`
  z-index: 10;
  background-color: var(--wh04);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  color: var(--wh6);
  ${fonts.manrope5};
  ${mixins.fs14}
  letter-spacing: 0.43px;
  transition: width 0.4s, background-color 0.3s, opacity 0.4s, border-color 0.3s;
  border: 1.4px solid transparent;
  ${({ active }) => active && `border-color: var(--wh3);`};
  ${({ openSearch }) => openSearch && `width: 0 !important; opacity: 0;`};
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 159px;
  overflow: hidden;
  
  border-radius: 22px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.72vw;
    width: 12.42vw;
    gap: 0.469vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 2.151vw;
    width: 15.5vw;
    gap: 0.587vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.961vw;
    width: 21.37vw;
    gap: 0.808vw;
  }
  @media ${breakpoints.mobile} {
    width: 42.32vw;
    font-size: 4.389vw;
    border-radius: 6.897vw;
    gap: 1.881vw;
    flex-shrink: 0;
  }
  ${({ hasChecked }) =>
    hasChecked &&
    `width: 42px;
  margin-right: 4px;
  @media ${breakpoints.desktopXl} {
    margin-right: 0.313vw;
    width: 3.284vw;
  }
  @media ${breakpoints.tabletL} {
    margin-right: 0.391vw;
    width: 4.106vw;
  }
  @media ${breakpoints.tabletM} {
    margin-right: 0.538vw;
    width: 5.653vw;
  }
  @media ${breakpoints.mobile} {
    width: 11.285vw;
    margin-right: 1.254vw;
  }`};

  & > button {
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.938vw;
      height: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.173vw;
      height: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.615vw;
      height: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      width: 4vw;
      height: 4vw;
    }
    &:hover {
      & path {
        stroke-opacity: 0.4;
      }
    }
    &:active {
      & path {
        stroke-opacity: 0.2;
      }
    }
    & > svg {
      width: 100%;
      height: 100%;
      & path {
        transition: stroke-opacity 0.3s;
        stroke-opacity: 0.6;
      }
      @media ${breakpoints.mobile} {
        transform: translateY(-0.313vw);
      }
    }
  }

  &:hover {
    background-color: var(--wh08);
  }
  &:disabled {
    background-color: var(--wh02);
    color: var(--wh2);
  }
`;

export const SpanReset = styled.span`
  pointer-events: none;
  text-align: center;
`;

// -- content

export const BoxTune = styled.section`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--wh04);
  background-color: var(--wh02);
  /* box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.32); */
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  color: var(--wh4);
  width: 476px;
  overflow: hidden;
  border-radius: 72px;
  @media ${breakpoints.desktopXl} {
    border-radius: 5.63vw;
    width: 37.22vw;
    padding: 1.56vw 0;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 7.04vw;
    width: 46.5vw;
    padding: 1.95vw 0;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 9.69vw;
    width: 64vw;
    padding: 2.69vw 0;
  }
  @media ${breakpoints.mobile} {
    width: auto;
    padding: 0;
    justify-content: center;
    background-color: #141414;
    height: 100%;
    border-radius: 0px;
  }

  & .MuiDialogContent-root {
    overflow-x: hidden;
    overflow-y: auto;
    /* overflow-y: hidden; */
    padding: 0px 28px;
    align-self: stretch;
    @media ${breakpoints.desktopXl} {
      padding: 0 2.189vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0 2.7vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 0 3.76vw;
    }
    @media ${breakpoints.mobile} {
      padding: 4.375vw 5.644vw;
    }
  }
`;

// -- header

export const Head = styled.header`
  color: var(--graphit-bb);
  text-align: center;
  ${fonts.manrope7}
  ${mixins.fs20}
  line-height: 100%;
  letter-spacing: -0.2px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: end;
  width: 83%;
  & > span {
    grid-column: 2;
  }

  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 2.692vw;
  }
  @media ${breakpoints.mobile} {
    margin-top: 4.389vw;
    margin-bottom: 8.15vw;
    color: var(--wh3);
    ${fonts.manrope6}
    ${mixins.fs16}
    letter-spacing: -0.16px;
    width: 96%;
  }
`;

// -- main

export const Filters = styled.main`
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media ${breakpoints.desktopXl} {
    gap: 2.189vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 2.7vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 3.76vw;
  }
  @media ${breakpoints.mobile} {
    gap: 7.524vw;
  }
`;

export const FiltersBottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media ${breakpoints.mobile} {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    gap: 5.016vw;
    & > div:nth-child(2) {
      order: -1;
      grid-column: span 2;
    }
  }
`;

// -- footer

export const Reset = styled.button`
  display: block;
  color: var(--wh4);
  transition: color 0.3s;
  text-align: center;
  ${fonts.manrope5}
  ${mixins.fs12}
  line-height: 116%;
  letter-spacing: 0.24px;
  margin: 0 auto;
  padding-top: 20px;
  @media ${breakpoints.desktopXl} {
    padding-top: 1.564vw;
  }
  @media ${breakpoints.tabletL} {
    padding-top: 1.955vw;
  }
  @media ${breakpoints.tabletM} {
    padding-top: 2.692vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.135vw;
    padding-top: 7vw;
    margin-bottom: 8.777vw;
  }
  &:hover {
    color: var(--wh3);
  }
  &:disabled,
  &:active {
    color: var(--wh12);
  }
`;

// -- shared

export const Title = styled.h3`
  color: var(--wh2);
  text-align: center;
  ${fonts.manrope7}
  ${mixins.fs10}
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media ${breakpoints.mobile} {
    ${({ mt }) => mt && `margin-top: 5.016vw;`}
  }
`;
