import { Skeleton } from '@mui/material';

export const Skelet = (props) => (
  <Skeleton
    sx={{
      borderRadius: props.borderRadius || "8px",
    }}
    animation="none"
    variant="rectangular"
    width={"100%"}
    height={"100%"}
  />
);
