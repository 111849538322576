import { getCurrentState } from "@/services/utils";
import { createSlice } from "@reduxjs/toolkit";

const hbCardsSlice = createSlice({
  name: "hbCards",
  initialState: {
    data: [],
    count: 0,
    loading: false,
    error: "",
    isBookmarked: false,
  },
  reducers: {
    fetchHbCardsSuccess(state, action) {
      state.data = [...getCurrentState(state.data), ...action.payload.results];
      state.count = action.payload.count;
    },
    fetchHbCardsLoading(state, action) {
      state.loading = action.payload;
    },
    fetchHbCardsError(state, action) {
      state.error = action.payload;
    },
    setHbCardBookmarkedBySlug(state, action) {
      const dataChangedByBookmark = getCurrentState(state.data);
      state.data = dataChangedByBookmark.map((card) => {
        const bookmarked = card.is_bookmarked;
        return card.slug === action.payload.slug
          ? { ...card, is_bookmarked: !bookmarked }
          : card;
      });
    },
    resetHbCardsToEmpty(state) {
      state.data = [];
    },
    showHbBookmarked(state, action) {
      state.isBookmarked = action.payload;
    },
  },
});

export const hbCardsReducer = hbCardsSlice.reducer;
export const {
  fetchHbCardsSuccess,
  fetchHbCardsLoading,
  fetchHbCardsError,
  resetHbCardsToEmpty,
  setHbCardBookmarkedBySlug,
  showHbBookmarked,
} = hbCardsSlice.actions;
