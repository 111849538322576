import { CardSkeleton } from "./CardSkeleton";
import Card from "./Card";
import { FlexContainer } from "../CardsContainer";
import { CardNotFound } from "../../search/CardNotFound";
import { useSelector } from "react-redux";

export const FlexContent = ({
  cards: { loading, data, isBookmarked },
  index,
}) => {
  const { filterSearch, searchOpen } = useSelector(
    (state) => state.searchInput
  );
  return (
    <>
      <FlexContainer>
        {(searchOpen &&
          (filterSearch === undefined || filterSearch.trim() === "")) ||
          (data && data.map((item) => <Card data={item} key={item.id} />))}

        {loading &&
          !searchOpen &&
          Array.from(Array(index)).map((skel) => <CardSkeleton key={skel} />)}
      </FlexContainer>
      {data.length === 0 && !loading && (
        <CardNotFound bookmarkMode={isBookmarked} />
      )}
    </>
  );
};
