import api from "./api";

const intervalTime = 2000;
const timeoutTime = 180000;

const getAuthСookie = async (key) => {
  await api.post(`/api/auth/telegram/complete/`, {
    key: key,
  });
};

const checkAuth = async (key) => {
  const res = await api.post(`/api/auth/telegram/check/`, {
    key: key,
  });

  if (res.status === 200 && isRegisterData(res)) {
    return res.data;
  }
};

const postRegister = async (register) => {
  const res = await api.post(
    `/api/auth/telegram/${register ? "register" : "login"}/`
  );

  if (res.status === 201 && isRegisterData(res)) {
    return await res.data;
  }
};

const isRegisterData = (value) => {
  return !!value && !!value?.data?.key && !!value?.data?.url;
};

export const handleLinkTelegram = async (register = false) => {
  try {
    const tgWindow = window.open();
    const { url, key } = await postRegister(register);
    tgWindow.location.href = url;

    const timer = setInterval(async () => {
      const { is_authorized } = await checkAuth(key);
      if (is_authorized) {
        await getAuthСookie(key);
        tgWindow.close();
        window.location.reload();
        clearInterval(timer);
        clearTimeout(timeout);
      }
    }, intervalTime);

    const timeout = setTimeout(() => {
      tgWindow.close();
      clearInterval(timer);
    }, timeoutTime);
  } catch (error) {
    console.log("произошла ошибка регистрации: ", error);
  }
};

const connect = async () => {
  const res = await api.post(`/api/auth/telegram/connect/`);

  if (res.status === 201) {
    return await res.data;
  }
};

export const connectTelegram = async () => {
  try {
    const tgWindow = window.open();
    const { url, key } = await connect();
    tgWindow.location.href = url;

    const timer = setInterval(async () => {
      const { is_authorized } = await checkAuth(key);
      if (is_authorized) {
        tgWindow.close();
        window.location.reload();
        clearInterval(timer);
        clearTimeout(timeout);
      }
    }, intervalTime);

    const timeout = setTimeout(() => {
      tgWindow.close();
      clearInterval(timer);
    }, timeoutTime);
  } catch (error) {
    console.log("произошла ошибка привязки: ", error);
  }
};

export const disconnectTelegram = async () => {
  try {
    const res = await api.post(`/api/auth/telegram/disconnect/`);

    if (res.status === 204) {
      window.location.reload();
    }
  } catch (error) {
    console.log("произошла ошибка отвязки: ", error);
  }
};
