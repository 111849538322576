import { GlobalStyle } from "@/shared/UI/global";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import styled from "styled-components";
import { breakpoints } from "@/styles/variables/variables";
import { useRouter } from "next/router";
import Wrapper from "@/shared/UI/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "@/redux/thunks/fetchUser";
import { useEffect } from "react";
import { isPromo, isThanks } from "@/shared/constants/routes";

const Layout = ({ children, logoSvg }) => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { data } = useSelector((state) => state.user);
  useEffect(() => {
    if (data.length === 0) {
      dispatch(fetchUser());
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <Header logoSvg={logoSvg} />
      <Main promo={isPromo(pathname)} thanks={isThanks(pathname)}>
        {children}
      </Main>
      <Footer />
    </>
  );
};

export default Layout;

const Main = styled.main`
  position: relative;

  ${({ promo }) =>
    promo
      ? `
      padding-bottom: 0 !important;
      & ${Wrapper} {
        margin-left: auto !important;
      }
      `
      : `
      padding-bottom: 52px;
      @media ${breakpoints.desktopXl} {
        padding-bottom: 4.066vw;
      }
      @media ${breakpoints.tabletL} {
        padding-bottom: 5.083vw;
      }
      @media ${breakpoints.tabletM} {
        padding-bottom: 6.999vw;
      }
      @media ${breakpoints.mobile} {
        padding-bottom: 16.301vw;
      }
      @media ${breakpoints.mobile}{
          align-items: flex-start;
          & > div:last-child {
            margin: 0 0 0 3.762vw;
          }
      }`};
  ${({ thanks }) =>
    thanks &&
    `min-height: calc(100vh - 68px);
  justify-content: center;
  padding-bottom:0px !important;

  @media ${breakpoints.desktopXl} {
    min-height: calc(100vh - 5.317vw);
  }
  @media ${breakpoints.tabletL} {
    min-height: calc(100vh - 6.647vw);
  }
  @media ${breakpoints.tabletM} {
    min-height: calc(100vh - 9.152vw);
  }
  @media ${breakpoints.mobile} {
    min-height: auto;
    & > div:last-child {
      margin: 0;
    };
  }`}
`;
