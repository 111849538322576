import SvgSelector from "@/shared/UI/SvgSelector";
import { Switch } from "./components";

export const SwitchUpperCase = ({ upper, setUpper, text_case }) => {
  return (
    <Switch
      disabled={text_case !== "both"}
      upper={upper}
      onClick={() => setUpper(!upper)}
    >
      <div>
        <SvgSelector svg={"open-card-lowercase"} />
        <SvgSelector svg={"open-card-uppercase"} />
      </div>
    </Switch>
  );
};
