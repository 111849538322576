import { Font } from "./components";

export const FontItem = ({
  fontfamily,
  title,
  text,
  upper,
  fontsize,
  text_case,
}) => {
  return (
    <Font font={fontfamily} text_case={text_case} upper={upper} fontsize={fontsize}>
      <h3>{title}</h3>
      <p>{text || " "}</p>
    </Font>
  );
};
