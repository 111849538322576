import SvgSelector from "@/shared/UI/SvgSelector";
import { handleLinkTelegram } from "@/services/tgBot";
import styled from "styled-components";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";

export const TgButton = ({ register }) => {
  return (
    <SocialLink
      aria-label="Telegram"
      onClick={() => handleLinkTelegram(register)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* <SvgSelector svg={`login-base`} /> */}
      <SvgSelector svg={`login-tg`} />
      <span>Телеграм</span>
    </SocialLink>
  );
};

const SocialLink = styled.a`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fonts.manrope5}
  ${mixins.fs16}
  ${mixins.gap4}
  line-height: normal;
  letter-spacing: 0.01em;
  color: var(--wh3);
  background-color: var(--wh04);
  transition: background-color 0.3s;
  border-radius: 16px;
  height: 52px;
  @media ${breakpoints.desktopXl} {
    height: 4.066vw;
  }
  @media ${breakpoints.tabletL} {
    height: 5.083vw;
  }
  @media ${breakpoints.tabletM} {
    height: 6.999vw;
  }
  @media ${breakpoints.mobile} {
    height: 16.301vw;
  }
  &:hover {
    background-color: var(--wh08);
  }
  &:active {
    background-color: var(--wh04);
  }
  & > svg {
    width: 24px;
    height: 24px;
    @media ${breakpoints.desktopXl} {
      width: 1.876vw;
      height: 1.876vw;
    }
    @media ${breakpoints.tabletL} {
      width: 2.346vw;
      height: 2.346vw;
    }
    @media ${breakpoints.tabletM} {
      width: 3.23vw;
      height: 3.23vw;
    }
    @media ${breakpoints.mobile} {
      width: 7.524vw;
      height: 7.524vw;
    }
  }
`;

// const SocialLink = styled.a`
//   background-position: center center;
//   width: 48px;
//   height: 48px;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   & > svg:first-child {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     & > path {
//       transition: fill-opacity 0.3s;
//     }
//   }
//   &:hover {
//     & > svg:first-child > path {
//       fill-opacity: 0.04;
//     }
//   }
//   @media ${breakpoints.desktopXl} {
//     width: 3.7vw;
//     height: 3.7vw;
//     & > svg:last-child {
//       width: 55%;
//       height: auto;
//     }
//   }
//   @media ${breakpoints.tabletL} {
//     width: 4.6vw;
//     height: 4.6vw;
//   }
//   @media ${breakpoints.tabletM} {
//     width: 6.6vw;
//     height: 6.6vw;
//   }
//   @media ${breakpoints.mobile} {
//     width: 12.8vw;
//     height: 12.8vw;
//   }
// `;
