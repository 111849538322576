import { breakpoints } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";

export const useIndexByQuery = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const isTabletM = useMediaQuery(breakpoints.tabletM);
  const isTabletL = useMediaQuery(breakpoints.tabletL);
  const isDesktopXl = useMediaQuery(breakpoints.desktopXl);

  let index = 10;
  if (isMobile) {
    index = 4;
  } else if (isTabletM) {
    index = 6;
  } else if (isTabletL) {
    index = 9;
  } else if (isDesktopXl) {
    index = 12;
  }

  return { index };
};
