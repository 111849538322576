import { ROUTES } from "@/shared/constants/routes";

const KEYS = {
  CAMPUS: "campus",
  FONTS: "fonts",
  HANDBOOK: "handbook",
  ARTPREPARATION: "artpreparation",
};

export const getTeamKey = (pathname) => {
  if (pathname.includes(ROUTES.CAMPUS)) {
    return KEYS.HANDBOOK;
    // return KEYS.CAMPUS;
  }
  if (pathname.includes(ROUTES.HANDBOOK)) {
    return KEYS.HANDBOOK;
  }
  if (pathname.includes(ROUTES.FONTS)) {
    return KEYS.FONTS;
  }
  if (pathname.includes(ROUTES.ARTPREPARATION)) {
    return KEYS.ARTPREPARATION;
  }
};
