import SvgSelector from "@/shared/UI/SvgSelector";
import { FavButtonStyled } from "./card-components";
import { putCardtoFav } from "@/services/requests";
import { useDispatch } from "react-redux";
import { setCardBookmarkedById } from "@/redux/fetchSlices/cardsSlice";

export const FavButton = ({ fav, dialog = false, cardId }) => {
  const dispatch = useDispatch();

  return (
    <FavButtonStyled
      dialog={dialog}
      onClick={() => {
        putCardtoFav(fav, cardId);
        dispatch(setCardBookmarkedById({ id: cardId, dialog: dialog }));
      }}
      isfav={fav}
    >
      <SvgSelector svg={"card-btn-bg"} />
      <SvgSelector svg={"card-btn-fav"} />
    </FavButtonStyled>
  );
};
