import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchCards } from "@/redux/thunks/fetchCards";
import { resetCardsToEmpty } from "@/redux/fetchSlices/cardsSlice";
import { FlexContent } from "./FlexContent";
import { useIndexByQuery } from "@/hooks/useIndexByQuery";
import { Loader } from "./card-components";

export const InfiniteLoader = () => {
  const { index } = useIndexByQuery();

  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();
  const {
    filter,
    cards,
    searchInput: { filterSearch, searchOpen },
  } = useSelector((state) => state);

  const bookmark = cards.isBookmarked;

  useEffect(() => {
    dispatch(resetCardsToEmpty());
    dispatch(
      fetchCards(
        0,
        index,
        bookmark || filterSearch ? undefined : filter, // ignore filter if another mode
        bookmark,
        filterSearch
      )
    );
    setOffset(0);
  }, [filter, bookmark, filterSearch]);

  const fetchMoreData = useCallback(async () => {
    dispatch(
      fetchCards(
        offset + 1,
        index,
        bookmark || filterSearch ? undefined : filter,
        bookmark,
        filterSearch
      )
    );
    setOffset(offset + 1);
  });

  return (
    <InfiniteScroll
      style={{
        overflow: "initial",
      }}
      hasMore={
        cards.loading
          ? false
          : searchOpen &&
            (filterSearch === undefined || filterSearch.trim() === "")
          ? false
          : cards.count > cards.data.length
      }
      dataLength={cards.data.length}
      next={fetchMoreData}
      loader={
        <Loader onClick={fetchMoreData}>
          <button>Загрузить ещё</button>
        </Loader>
      }
      scrollThreshold="500px"
    >
      <FlexContent cards={cards} index={index} />
    </InfiniteScroll>
  );
};
