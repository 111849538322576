import { DialogSt } from "@/components/header/UserDropdown";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { ButtonDownload, FontName } from "../card-components";
import { CloseButton, Input, InputWrapper } from '@/components/search/components';

export const CardDialog = styled(DialogSt)``;

export const Box = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 56px;
  border: 1px solid var(--wh04);
  background-color: var(--wh02);
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
  color: var(--wh6);
  overflow: hidden;
  width: 848px;
  padding: 28px 24px;
  align-items: stretch;
  gap: 32px;

  @media ${breakpoints.desktopXl} {
    gap: 2.502vw;
    width: 66.302vw;
    padding: 2.189vw 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 3.128vw;
    width: 82.893vw;
    padding: 2.737vw 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    width: 80.754vw;
    gap: 4.307vw;
    padding: 3.769vw 3.23vw;
  }
`;

export const Head = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  ${mixins.gap12}

  & ${FontName} {
    font-size: 32px;
    ${fonts.manrope6}
    letter-spacing: -0.64px;
    text-transform: none;
    @media ${breakpoints.desktopXl} {
      font-size: 2.502vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 3.128vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 4.307vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 10.031vw;
    }
  }
`;
export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
`;
export const Settings = styled.div`
  display: grid;
  ${mixins.gap8}
  justify-content: center;
  grid-template-rows: 36px;
  grid-template-columns: 1fr 92px 72px 168px;
  padding: 0 4px;
  @media ${breakpoints.desktopXl} {
    grid-template-rows: 2.815vw;
    grid-template-columns: 1fr 7.193vw 5.629vw 13.135vw;
    padding: 0 0.313vw;
  }
  @media ${breakpoints.tabletL} {
    grid-template-rows: 3.519vw;
    grid-template-columns: 1fr 8.993vw 7.038vw 16.422vw;
    padding: 0 0.391vw;
  }
  @media ${breakpoints.tabletM} {
    row-gap: 2.153vw;
    grid-template-rows: 4.845vw 4.845vw;
    grid-template-columns: 1fr 12.382vw 9.69vw 22.611vw 1fr;
    grid-template-areas: "input input input input input" ". fs aa btn .";
    padding: 0 3.769vw;
  }

  & ${ButtonDownload} {
    border-radius: 50px;
    grid-column: auto;
    color: var(--wh6);
    @media ${breakpoints.tabletM} {
      grid-area: btn;
    }
    & > a {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & ${InputWrapper} {
    width: auto;
    @media ${breakpoints.tabletM} {
      grid-area: input;
    }
    & > svg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 8.2%;
      height: 100%;
      left: 4px;
      @media ${breakpoints.desktopXl} {
        left: 0.313vw;
      }
      @media ${breakpoints.tabletL} {
        left: 0.391vw;
      }
      @media ${breakpoints.tabletM} {
        left: 0.538vw;
      }
    }
    & ${Input} {
      ${mixins.fs14}
      letter-spacing: 0;
      padding: 0 40px;
      text-overflow: ellipsis;
      @media ${breakpoints.desktopXl} {
        padding: 0 3.127vw;
      }
      @media ${breakpoints.tabletL} {
        padding: 0 3.91vw;
      }
      @media ${breakpoints.tabletM} {
        padding: 0 5.384vw;
      }
      &::placeholder {
        color: var(--wh12);
      }
    }
    & ${CloseButton} {
      width: 8.2%;
      height: 100%;
      right: 4px;
      @media ${breakpoints.desktopXl} {
        right: 0.313vw;
      }
      @media ${breakpoints.tabletL} {
        right: 0.391vw;
      }
      @media ${breakpoints.tabletM} {
        right: 0.538vw;
      }
    }
  }
`;

export const Font = styled.li`
  display: flex;
  flex-direction: column;
  ${mixins.gap8}
  padding: 12px 0 28px;

  @media ${breakpoints.desktopXl} {
    padding: 0.938vw 0 2.189vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.173vw 0 2.737vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.615vw 0 3.769vw;
  }
  & > h3 {
    color: var(--wh2);
    ${fonts.manrope6}
    ${mixins.fs8}
    line-height: 100%;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  & > p {
    font-family: ${({ font }) => (font ? font : "ArtelSans")};
    /* font-style: normal; */
    color: var(--wh6);
    line-height: 130%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;

    ${({ fontsize }) => `font-size: ${fontsize ? fontsize : 48}px;`}
    ${({ text_case }) =>
      `text-transform: ${
        text_case === "upper"
          ? "uppercase"
          : text_case === "lower"
          ? "lowercase"
          : "none"
      };`}
    ${({ upper }) => upper && `text-transform: uppercase !important;`}
    will-change: font-size;
  }
`;

export const FontList = styled.ul`
  border-radius: 32px;
  background-color: var(--wh02);
  overflow: hidden;
  & > div {
    padding: 8px 20px 12px;
    max-height: 368px;
    @media ${breakpoints.desktopXl} {
      max-height: 28.772vw;
      padding: 0.625vw 1.564vw 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      max-height: 35.973vw;
      padding: 0.782vw 1.955vw 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      max-height: 42.53vw;
      padding: 1.077vw 2.692vw 1.615vw;
    }
    overflow-y: auto;
  }

  font-style: normal;
`;

export const Switch = styled.button`
  padding: 4px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--wh04);
  overflow: hidden;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh08);
    & > div::before {
      background-color: var(--wh08);
    }
  }

  @media ${breakpoints.desktopXl} {
    padding: 0.313vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.391vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.538vw;
    grid-area: aa;
  }
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 6px;
    @media ${breakpoints.desktopXl} {
      padding: 0 0.469vw;
    }
    @media ${breakpoints.tabletL} {
      padding: 0 0.587vw;
    }
    @media ${breakpoints.tabletM} {
      padding: 0 0.808vw;
    }
    &::before {
      content: "";
      position: absolute;
      border-radius: 100%;
      background-color: var(--wh04);

      bottom: 0;
      height: 100%;
      width: 28px;
      transition: background-color 0.3s, right 0.3s;

      @media ${breakpoints.desktopXl} {
        width: 2.189vw;
      }
      @media ${breakpoints.tabletL} {
        width: 2.737vw;
      }
      @media ${breakpoints.tabletM} {
        width: 3.769vw;
      }

      ${({ upper }) => `right: ${upper ? "0" : "56%"};`}
    }
  }
  & svg {
    width: 16px;
    @media ${breakpoints.desktopXl} {
      width: 1.251vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.564vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.153vw;
    }
    & path {
      transition: fill-opacity 0.3s;
    }
    &:first-of-type {
      & path {
        ${({ upper }) => upper || `fill-opacity: 0.6;`}
      }
    }
    &:last-of-type {
      & path {
        ${({ upper }) => upper && `fill-opacity: 0.6;`}
      }
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const UpWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
  padding: 6px 4.5px;
  width: 100%;

  @media ${breakpoints.desktopXl} {
    padding: 0.469vw 0.352vw;
    gap: 0.235vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.587vw 0.44vw;
    gap: 0.293vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.808vw 0.606vw;
    gap: 0.404vw;
  }
  & > svg {
    width: 22px;
    height: 11.6px;
    flex-shrink: 0;
    @media ${breakpoints.desktopXl} {
      width: 1.72vw;
      height: 0.907vw;
    }
    @media ${breakpoints.tabletL} {
      width: 2.151vw;
      height: 1.134vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.961vw;
      height: 1.561vw;
    }
  }

  & .MuiInputBase-root {
    width: 48px;
    @media ${breakpoints.desktopXl} {
      width: 3.753vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.692vw;
    }
    @media ${breakpoints.tabletM} {
      width: 6.46vw;
    }
    &::after,
    &::before {
      display: none;
    }
    & > input {
      border-radius: 30px;
      background-color: var(--wh04);
      padding: 0;
      color: var(--wh6);
      text-align: center;
      ${fonts.manrope5}
      ${mixins.fs14}
      line-height: 128%;
      padding: 3px 8px;
      height: 24px;
      box-sizing: border-box;
      border: 1px solid transparent;
      transition: border 0.3s, background-color 0.3s, color 0.3s;
      &:hover {
        background-color: var(--wh08);
      }

      @media ${breakpoints.desktopXl} {
        padding: 0.235vw 0.625vw;
        height: 1.876vw;
      }
      @media ${breakpoints.tabletL} {
        padding: 0.293vw 0.782vw;
        height: 2.346vw;
      }
      @media ${breakpoints.tabletM} {
        padding: 0.404vw 1.077vw;
        height: 3.23vw;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
`;
export const SizeRangeS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--wh04);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  overflow: hidden;
  @media ${breakpoints.tabletM} {
    grid-area: fs;
  }
  flex-direction: column;
  position: relative;

  & .MuiSlider-root {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 83%;
    padding: 0;
    color: var(--wh12);
    & .MuiSlider-rail,
    & .MuiSlider-track {
      height: 2px;
      @media ${breakpoints.desktopXl} {
        height: 0.156vw;
      }
      @media ${breakpoints.tabletL} {
        height: 0.196vw;
      }
      @media ${breakpoints.tabletM} {
        height: 0.269vw;
      }
      opacity: 1;
      background-color: var(--wh04);
    }
    & .MuiSlider-track {
      background-color: var(--wh09);
      transition: background-color 0.2s;
    }
    & .MuiSlider-thumb {
      background-color: #383838;
      box-shadow: none;
      opacity: 1;
      width: 4px;
      height: 4px;
      transition: left 0.15s, background-color 0.2s;
      @media ${breakpoints.desktopXl} {
        width: 0.313vw;
        height: 0.313vw;
      }
      @media ${breakpoints.tabletL} {
        width: 0.391vw;
        height: 0.391vw;
      }
      @media ${breakpoints.tabletM} {
        width: 0.538vw;
        height: 0.538vw;
      }
      &:after {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      & .MuiSlider-thumb {
        background-color: #484848;
      }
      & .MuiSlider-track {
        background-color: #383838;
      }
    }
    &:active {
      & .MuiSlider-thumb {
        background-color: #7c7c7c;
      }
      & .MuiSlider-track {
        background-color: var(--wh3);
      }
    }
  }
`;
