import css from "styled-jsx/css";

export const sizes = {
  desktopXxlWidth: `1727px`,
  desktopXlWidth: `1279px`,
  tabletLWidth: `1023px`,
  tabletMWidth: `743px`,
  mobileWidth: `319px`,
};

export const breakpoints = {
  desktopXl: `(max-width: ${sizes.desktopXxlWidth})`,
  tabletL: `(max-width: ${sizes.desktopXlWidth})`,
  tabletM: `(max-width: ${sizes.tabletLWidth})`,
  mobile: `(max-width: ${sizes.tabletMWidth})`,
};

export const fonts = {
  manrope4: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: 400;
  `,
  manrope5: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: 400;
  `,
  manrope6: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: bold;
  `,
  manrope7: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: bold;
  `,
  artel4: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: 400;
  `,
  artel6: css`
    font-family: ArtelSans;
    font-style: normal;
    font-weight: bold;
  `,
};

export const mixins = {
  fs7: css`
    font-size: 7px;
    
    @media ${breakpoints.desktopXl} {
      font-size: 0.55vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 0.68vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 0.94vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 2.19vw;
    }
  `,
  fs8: css`
    font-size: 8px;

    @media ${breakpoints.desktopXl} {
      font-size: 0.625vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 0.78vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 1.075vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 2.5vw;
    }
  `,
  fs10: css`
    font-size: 10px;

    @media ${breakpoints.desktopXl} {
      font-size: 0.74vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 0.92vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 1.32vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.1vw;
    }
  `,
  fs11: css`
    font-size: 11px;
    @media ${breakpoints.desktopXl} {
      font-size: 0.86vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.075vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 1.48vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.448vw;
    }
  `,
  fs12: css`
    font-size: 12px;
    @media ${breakpoints.desktopXl} {
      font-size: 0.9375vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.17vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 1.6vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.75vw;
    }
  `,
  fs14: css`
    font-size: 14px;
    @media ${breakpoints.desktopXl} {
      font-size: 1.09375vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.367vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 1.88vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 4.375vw;
    }
  `,
  fs16: css`
    font-size: 16px;
    @media ${breakpoints.desktopXl} {
      font-size: 1.25vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.56vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 2.15vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 5vw;
    }
  `,
  fs18: css`
    font-size: 18px;
    @media ${breakpoints.desktopXl} {
      font-size: 1.407vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.76vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 2.423vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 5.643vw;
    }
  `,
  fs20: css`
    font-size: 20px;
    @media ${breakpoints.desktopXl} {
      font-size: 1.56vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 1.95vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 2.68vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 6.25vw;
    }
  `,
  gap12: css`
    gap: 12px;
    @media ${breakpoints.desktopXl} {
      gap: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      gap: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      gap: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      gap: 3.762vw;
    }
  `,
  gap8: css`
    gap: 8px;
    @media ${breakpoints.desktopXl} {
      gap: 0.625vw;
    }
    @media ${breakpoints.tabletL} {
      gap: 0.78vw;
    }
    @media ${breakpoints.tabletM} {
      gap: 1.075vw;
    }
    @media ${breakpoints.mobile} {
      gap: 2.5vw;
    }
  `,
  gap4: css`
    gap: 4px;
    @media ${breakpoints.desktopXl} {
      gap: 0.313vw;
    }
    @media ${breakpoints.tabletL} {
      gap: 0.391vw;
    }
    @media ${breakpoints.tabletM} {
      gap: 0.538vw;
    }
    @media ${breakpoints.mobile} {
      gap: 1.254vw;
    }
  `,
};

export const mixinsCampus = {
  fs8: css`
    font-size: 0.463vw;
    @media ${breakpoints.desktopXl} {
      font-size: 0.625vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 2.5vw;
    }
  `,
  fs10: css`
    font-size: 0.58vw;
    @media ${breakpoints.desktopXl} {
      font-size: 0.78vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.1vw;
    }
  `,
  fs11: css`
    font-size: 0.637vw;
    @media ${breakpoints.desktopXl} {
      font-size: 0.86vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.448vw;
    }
  `,
  fs12: css`
    font-size: 0.695vw;
    @media ${breakpoints.desktopXl} {
      font-size: 0.9375vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.75vw;
    }
  `,
  fs14: css`
    font-size: 0.811vw;
    @media ${breakpoints.desktopXl} {
      font-size: 1.09375vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 4.375vw;
    }
  `,
  fs16: css`
    font-size: 0.926vw;
    @media ${breakpoints.desktopXl} {
      font-size: 1.25vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 5vw;
    }
  `,
  fs18: css`
    font-size: 1.042vw;
    @media ${breakpoints.desktopXl} {
      font-size: 1.407vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 5.643vw;
    }
  `,
  fs20: css`
    font-size: 1.158vw;
    @media ${breakpoints.desktopXl} {
      font-size: 1.56vw;
    }
    @media ${breakpoints.mobile} {
      font-size: 6.25vw;
    }
  `,
  gap12: css`
    gap: 0.695vw;
    @media ${breakpoints.desktopXl} {
      gap: 0.938vw;
    }
    @media ${breakpoints.mobile} {
      gap: 3.762vw;
    }
  `,
  gap8: css`
    gap: 0.463vw;
    @media ${breakpoints.desktopXl} {
      gap: 0.625vw;
    }
    @media ${breakpoints.mobile} {
      gap: 2.5vw;
    }
  `,
  gap4: css`
    gap: 0.232vw;
    @media ${breakpoints.desktopXl} {
      gap: 0.313vw;
    }
    @media ${breakpoints.mobile} {
      gap: 1.254vw;
    }
  `,
};
