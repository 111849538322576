import {
  Author,
  CardCirclesWrapper,
  CardFooter,
  CardHeader,
  CardHeaderBottom,
  CardMain,
  CardStyled,
  Circle,
  FontName,
  FontSample,
  HeaderCurrencyNumber,
  Textarea,
  TypefaceCount,
} from "./card-components";
import { FontTooltip } from "./Tooltip";
import { TypefaceSelect } from "./TypefaceSelect";
import { FavButton } from "./FavButton";
import { ExpandButton } from "./cardOpen/ExpandButton";
import { pluralize } from "@/services/utils";
import { ButtonDownloadOrLock } from "./ButtonDownload";
import { Currency } from "./Currency";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPrevSearch } from "@/redux/prevSearchSlice";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";
import { IsNew } from "./IsNew";
import { TextParseSpacing } from "./TextParseSpacing";

const Card = ({ data }) => {
  const [currentTypeface, setCurrentTypeface] = useState(0);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const {
    cards: { isBookmarked: bookmarkMode },
    searchInput: { searchOpen },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const handleCardClick = () => {
    if (searchOpen) dispatch(addPrevSearch(data?.name));
  };

  useEffect(() => {
    if (data && data.variations) {
      const font = new FontFace(
        `${data.name} ${data.variations[currentTypeface].name}`,
        `url(${data.variations[currentTypeface].preview_file})`
      );
      document.fonts.add(font);
    }
  }, [currentTypeface]);

  return (
    <CardStyled
      disableInBookmarkMode={bookmarkMode ? !data.is_bookmarked : undefined}
      onClick={handleCardClick}
      fontname={`${data.name} ${data.variations[currentTypeface].name}`}
      published={data.is_active}
    >
      <IsNew date={data?.date_published} />
      <CardCirclesWrapper>
        <FavButton cardId={data.id} fav={data.is_bookmarked} />
        {isMobile || <ExpandButton card={data} />}
        {data.variations && data.variations.length > 0 && data.image && (
          <>
            <CardHeader image={data?.image}>
              <FontName>{data.name}</FontName>
              <FontSample
                fs={data.sample_text_size}
                ls={data.sample_text_letter_spacing}
                yoffset={data.sample_text_y_offset}
              >
                <TextParseSpacing text={data.sample_text} />
              </FontSample>
              <CardHeaderBottom>
                <Currency currency={data.currencies} />
                <HeaderCurrencyNumber
                  fs={data.sample_digits_size}
                  ls={data.sample_digits_letter_spacing}
                  yoffset={data.sample_digits_y_offset}
                >
                  <TextParseSpacing text={data?.sample_digits} />
                </HeaderCurrencyNumber>
              </CardHeaderBottom>
            </CardHeader>
            <CardMain>
              <TypefaceCount>
                {data.variations.length}{" "}
                {pluralize(
                  data.variations.length,
                  "начертание",
                  "начертания",
                  "начертаний"
                )}
              </TypefaceCount>
              <TypefaceSelect
                setFont={setCurrentTypeface}
                options={data.variations}
                defaultoption={currentTypeface}
              />
              <Textarea
                fs={data.preview_size}
                ls={data.preview_letter_spacing}
                lh={data.preview_line_height}
                preview_case={data.preview_case}
                maxLength={100}
                name="fontExample"
                placeholder={
                  data.preview_text ||
                  "Здесь можно примерить свой текст, если нужно."
                }
              ></Textarea>
            </CardMain>
          </>
        )}
        <ButtonDownloadOrLock downloadUrl={data.download_url} />
        <CardFooter>
          <FontTooltip
            text={
              data.is_semi_free
                ? "SemiFree (Личн) — шрифт для персонального использования, разрешён для учебных, личных и некоммерческих проектов"
                : "Free (Фри) — полностью бесплатный шрифт, разрешён для использования в любых проектах без ограничений"
            }
          />
          <Author
            href={data.author_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.is_semi_free && "Semi"}Free by {data.author_name}
          </Author>
        </CardFooter>
        <Circle color={data.card_color_top} />
        <Circle color={data.card_color_bottom} />
      </CardCirclesWrapper>
    </CardStyled>
  );
};

export default Card;
