import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { Avatar } from "../AuthorizedUserDropdown";

export const Box = styled.div`
  border-radius: 32px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: var(--wh04);
  border: 1px solid var(--wh04);
  box-shadow: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: var(--wh9);
  width: 373px;
  overflow: hidden;
  & .MuiDialogContent-root {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 24px 28px;
    max-width: 100%;

    @media ${breakpoints.mobile} {
      padding: 0 7.5vw 7.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  @media ${breakpoints.desktopXl} {
    width: 29.14vw;
  }
  @media ${breakpoints.tabletL} {
    width: 36.4vw;
  }
  @media ${breakpoints.tabletM} {
    width: 50.13vw;
  }
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 100%;
    margin-top: 15.5vw;
    border-radius: 0;
    justify-content: center;
    background-color: #141414;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.32);
    align-items: center;
    overflow-y: auto;
  }
`;

export const Head = styled.header`
  display: flex;
  align-items: center;
  ${mixins.gap12}
  color: var(--wh9);
  text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
  ${mixins.fs20}
  ${fonts.manrope7}
  line-height: normal;
  letter-spacing: -0.2px;
  margin-bottom: 28px;
  padding-top: 28px;
  ${Avatar} {
    margin-right: 0;
  }

  & > h2 {
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 83%;
  }
  @media ${breakpoints.desktopXl} {
    margin-bottom: 2.187vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.73vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.76vw;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 3.75vw;
    margin-bottom: 8.75vw;
    width: 100%;
  }
`;

export const Subscribes = styled.main`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h3`
  padding: 0 8px;
  margin-bottom: 8px;
  color: var(--wh3);
  ${fonts.manrope7}
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-self: flex-start;
  @media ${breakpoints.mobile} {
    padding: 0 2.5vw;
    margin-bottom: 2.5vw;
  }
`;

export const SettingsButtons = styled.div`
  display: flex;
  flex-direction: column;
  ${mixins.gap8}
  width: 100%;
  & > button {
    display: flex;
    align-items: center;
    ${mixins.gap8}
    padding: 10px 8px;
    border-radius: 12px;
    transition: background-color 0.3s;
    color: var(--wh3);
    ${fonts.manrope5}
    ${mixins.fs16}
    line-height: 100%;
    letter-spacing: 0.16px;
    &:hover {
      background-color: var(--wh02);
    }
    &:active {
      background-color: var(--wh04);
    }
  }
`;

// --- NOTIFICATION

export const NotWrapper = styled.div`
  color: var(--green9);
  & > main {
    color: var(--green9);
    ${fonts.manrope4}
    ${mixins.fs12};
    line-height: 117%;
    letter-spacing: -0.12px;
    margin-bottom: 20px;
    @media ${breakpoints.desktopXl} {
      margin-bottom: 1.56vw;
    }
    @media ${breakpoints.tabletL} {
      margin-bottom: 1.95vw;
    }
    @media ${breakpoints.tabletM} {
      margin-bottom: 2.68vw;
    }
  }
`;
export const NotHead = styled.header`
  display: flex;
  align-items: center;
  ${mixins.gap4}
  ${fonts.manrope7}
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    margin-bottom: 0.625vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 0.78vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 1.075vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 2.5vw;
  }
  & > h2 {
    ${mixins.fs16}
    line-height: 125%;
    letter-spacing: -0.16px;
  }
  & > div {
    padding: 4px 6px;
    border-radius: 6px;
    border: 1px solid var(--green9);
    ${mixins.fs8}
    line-height: 100%;
    letter-spacing: 0.96px;
  }
`;
export const NotFooter = styled.footer`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  ${mixins.gap12}
  & > ul {
    display: flex;
    align-items: center;
    ${mixins.gap8}
  }
`;
export const Social = styled.li`
  width: 16px;
  height: 16px;
  & path {
    fill: var(--color-green);
    fill-opacity: 0.6;
  }
  @media ${breakpoints.desktopXl} {
    width: 1.25vw;
    height: 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    width: 1.56vw;
    height: 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    width: 2.15vw;
    height: 2.15vw;
  }
  @media ${breakpoints.mobile} {
    width: 5vw;
    height: 5vw;
  }
`;
export const NotButton = styled.button`
  border-radius: 8px;
  background-color: var(--green08);
  padding: 6px 16px 8px;
  transition: background-color 0.3s;
  ${fonts.manrope6}
  ${mixins.fs12};
  line-height: 133%;
  letter-spacing: 0.12px;
  &:hover {
    background-color: var(--green12);
  }
  &:active {
    background-color: var(--green08);
  }
  @media ${breakpoints.mobile} {
    padding: 1.87vw 4.8vw 2.5vw;
  }
`;
