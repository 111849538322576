import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filterSlice",
  initialState: {
    category: undefined,
    subcategory: undefined,
    archetype: undefined,
    license: undefined,
    shape: undefined,
    script: undefined,
    style: undefined,
  },
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
      state.subcategory = undefined;
    },
    setCategoryType: (state, action) => {
      state.subcategory = action.payload;
    },
    setArchetype: (state, action) => {
      state.archetype = action.payload;
    },
    setLicense: (state, action) => {
      state.license = action.payload;
    },
    setForm: (state, action) => {
      state.shape = action.payload;
    },
    setAlphabet: (state, action) => {
      state.script = action.payload;
    },
    setGraph: (state, action) => {
      state.style = action.payload;
    },
    resetFilter: (state) => {
      Object.keys(state).forEach((key) => (state[key] = undefined));
    },
  },
});

export const filterReducer = filterSlice.reducer;
export const {
  resetFilter,
  setCategory,
  setCategoryType,
  setArchetype,
  setLicense,
  setForm,
  setAlphabet,
  setGraph,
} = filterSlice.actions;
