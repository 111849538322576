import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { New } from "../index-page/cards/card/card-components";
import Link from "next/link";
import { Container } from "../index-page/cards/CardsContainer";

export const PromoWr = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 52px;
  min-height: calc(100vh - 68px);
  padding-bottom: 96px;

  @media ${breakpoints.desktopXl} {
    min-height: calc(100vh - 5.317vw);
    padding-bottom: 7.506vw;
    gap: 4.066vw;
  }
  @media ${breakpoints.tabletL} {
    min-height: calc(100vh - 6.647vw);
    padding-bottom: 9.384vw;
    gap: 5.083vw;
  }
  @media ${breakpoints.tabletM} {
    min-height: calc(100vh - 9.152vw);
    padding-bottom: 12.921vw;
    gap: 6.999vw;
  }
  @media ${breakpoints.mobile} {
    min-height: calc(100vh - 15.987vw);
    padding-bottom: 24.138vw;
    gap: 10.031vw;
  }
  & > div:last-child {
    min-height: auto;
    max-width: 1109px;

    @media ${breakpoints.desktopXl} {
      max-width: 85vw;
    }
    @media ${breakpoints.tabletL} {
      max-width: 79vw;
    }
    @media ${breakpoints.tabletM} {
      max-width: 72vw;
    }
    @media ${breakpoints.mobile} {
      max-width: 80vw;
    }
  }
`;

export const Wr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    ${mixins.gap4}
    margin-bottom: 4px;
    @media ${breakpoints.desktopXl} {
      margin-bottom: 0.313vw;
    }
    @media ${breakpoints.tabletL} {
      margin-bottom: 0.391vw;
    }
    @media ${breakpoints.tabletM} {
      margin-bottom: 0.538vw;
    }
    @media ${breakpoints.mobile} {
      margin-bottom: 2.821vw;
      align-items: center;
    }

    & ${New} {
      position: static;
    }
    & > p {
      color: var(--wh9);
      ${fonts.manrope5}
      ${mixins.fs10}
      line-height: 120%;
      letter-spacing: 0.2px;
      @media ${breakpoints.mobile} {
        font-size: 2.508vw;
      }
    }
  }
  & > h1 {
    color: var(--wh9);
    text-align: center;
    text-shadow: 0px 0px 36px rgba(255, 255, 255, 0.35);
    ${fonts.manrope6}
    line-height: 93%;
    letter-spacing: -1.8px;
    font-size: 60px;
    margin-bottom: 20px;
    @media ${breakpoints.desktopXl} {
      font-size: 4.691vw;
      margin-bottom: 1.564vw;
    }
    @media ${breakpoints.tabletL} {
      font-size: 5.865vw;
      margin-bottom: 1.955vw;
    }
    @media ${breakpoints.tabletM} {
      font-size: 8.075vw;
      margin-bottom: 2.692vw;
    }
    @media ${breakpoints.mobile} {
      margin-bottom: 5.016vw;
      font-size: 12.539vw;
    }
  }
`;

export const Label = styled.p`
  color: var(--wh3);
  text-align: center;
  ${fonts.manrope5}
  ${mixins.fs20}
  line-height: 120%;
  letter-spacing: -0.2px;
  margin-bottom: 40px;

  @media ${breakpoints.desktopXl} {
    margin-bottom: 3.127vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 3.91vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 5.384vw;
  }
  @media ${breakpoints.mobile} {
    font-size: 3.762vw;
    margin-bottom: 6.27vw;
    max-width: 55%;
    & > br {
      display: none;
    }
  }
`;

export const ButtonTry = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 32px;
  padding: 20px 52px 24px;
  margin-bottom: 8px;
  @media ${breakpoints.desktopXl} {
    padding: 1.564vw 4.066vw 1.876vw;
    margin-bottom: 0.625vw;
    border-radius: 2.502vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.955vw 5.083vw 2.346vw;
    margin-bottom: 0.782vw;
    border-radius: 3.128vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 2.692vw 6.999vw 3.23vw;
    margin-bottom: 1.077vw;
    border-radius: 4.307vw;
  }
  @media ${breakpoints.mobile} {
    padding: 15.5px 39px 21px;
    margin-bottom: 2.508vw;
    border-radius: 10.031vw;
  }

  transition: background-color 0.3s;
  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
  & > div {
    position: absolute;
    filter: blur(24px);
    -webkit-filter: blur(24px);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--wh12);
    border-radius: 50px;
  }
  & > img {
    margin-right: 8px;
    width: 62px;
    height: 24px;
    @media ${breakpoints.desktopXl} {
      margin-right: 0.625vw;
      width: 4.848vw;
      height: 1.876vw;
    }
    @media ${breakpoints.tabletL} {
      margin-right: 0.782vw;
      width: 6.061vw;
      height: 2.346vw;
    }
    @media ${breakpoints.tabletM} {
      margin-right: 1.077vw;
      width: 8.345vw;
      height: 3.23vw;
    }
    @media ${breakpoints.mobile} {
      margin-right: 1.254vw;
      width: 14.734vw;
      height: 5.643vw;
    }
  }
  & > span {
    color: var(--wh9);
    ${fonts.manrope5}
    ${mixins.fs16}
    line-height: 112%;
    letter-spacing: 0.48px;
    &:last-child {
      display: inline-block;
      margin-left: 2px;
    }
    @media ${breakpoints.mobile} {
      font-size: 3.762vw;
      letter-spacing: 0.24px;
    }
  }
`;

export const LabelSmall = styled(Label)`
  ${mixins.fs10}
  margin: 0 !important;

  & > p {
    color: var(--wh12);
  }
`;

export const ContainerCards = styled(Container)`
  animation: showContainer 700ms ease 500ms 1 forwards;
  grid-template-columns: repeat(4, 1fr);
  padding: 16px;
  row-gap: 17.8px;
  pointer-events: none;
  transform: translateY(100%);
  user-select: none;
  @media ${breakpoints.tabletL} {
    row-gap: 1.74vw;
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${breakpoints.tabletM} {
    row-gap: 2.396vw;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${breakpoints.mobile} {
    row-gap: 5.58vw;
    grid-template-columns: 1fr;
    padding: 0;
  }
  & ${New} {
    display: none;
  }

  @keyframes showContainer {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0px);
    }
  }
`;

export const Gradient = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, #0d0d0d 31.25%, rgba(13, 13, 13, 0) 100%);
  height: 300px;
  @media ${breakpoints.desktopXl} {
    height: 23.456vw;
  }
  @media ${breakpoints.tabletL} {
    height: 29.326vw;
  }
  @media ${breakpoints.tabletM} {
    height: 40.377vw;
  }
  @media ${breakpoints.mobile} {
    height: 94.044vw;
  }
`;

// animation

export const AnimatedImage = styled.div`
  width: 100%;
  height: 380px;
  width: 256px;
  transition: background-image 0.3s;
  /* ${({ src }) => `
    background-image: url(${src});
  `}
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;

  @media ${breakpoints.desktopXl} {
    width: 20.016vw;
    height: 29.711vw;
  }
  @media ${breakpoints.tabletL} {
    width: 25.024vw;
    height: 37.146vw;
  }
  @media ${breakpoints.tabletM} {
    width: 34.455vw;
    height: 51.144vw;
  }
  @media ${breakpoints.mobile} {
    width: 80.251vw;
    height: 119.122vw;
  }
`;

export const HiddenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  ${({ show }) => `opacity:${show ? 1 : 0}`}
`;
