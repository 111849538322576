import { LoginLabel } from "@/components/header/loginTabs/components";
import { setUserSubscriptions } from "@/redux/fetchSlices/userSlice";
import api from "@/services/api";
import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const errorMessages = {
  // empty: "Поле не может быть пустым",
  does_not_exist:
    "Такого промокода у нас нет, где-то ошибка, проверьте, попробуйте снова или напишите нам",
  invalidPattern:
    "Чёт не похоже на промокод, где-то ошибка, проверьте, попробуйте снова или напишите нам",
  promo_code_applied: "Вы уже активировали этот промокод",
  promo_code_expired: "Срок действия этого промокода истёк",
};

export const PromoInput = ({ setDisabled, success, setSuccess }) => {
  const [errorType, setErrorType] = useState();
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const inputRef = useRef();

  const handleDisabled = (e) => {
    if (e.target.value.trim().length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
      setErrorType();
      setSuccess(false);
    }
    setValue(e.target.value.trim().toUpperCase());
  };

  const handleSubmit = (e) => {
    setSuccess(false);
    e.preventDefault();
    const input = e.target[0];
    if (input.validity.patternMismatch) {
      setErrorType("invalidPattern");
    } else {
      const formData = new FormData(input.form);
      const formProps = Object.fromEntries(formData);

      api.post(`/api/apply-promo-code/`, formProps).then(
        (res) => {
          setSuccess(true);
          dispatch(setUserSubscriptions(res.data));
        },
        (error) => {
          const errorCode = error.response.data?.promo_code[0]?.code;
          setErrorType(errorCode);
          input.blur();
          console.log("произошла ошибка отправки промокода: ", error);
        }
      );
    }
  };
  const resetInput = () => {
    const input = inputRef?.current;
    setDisabled(true);
    setErrorType();
    setSuccess(false);
    setValue("");
    input.focus();
  };

  const isError =
    errorType === "does_not_exist" ||
    errorType === "invalidPattern" ||
    errorType === "promo_code_applied" ||
    errorType === "promo_code_expired";

  return (
    <>
      <Form noValidate onSubmit={handleSubmit} id="promocode">
        <Wr success={success} error={isError}>
          <Input
            value={value}
            disabled={success}
            ref={inputRef}
            autocomplete="off"
            placeholder="промокод"
            type="text"
            name="promo_code"
            id="promocode-text"
            required
            maxLength={40}
            pattern="[0-9A-Za-z\-]+"
            onChange={handleDisabled}
          />
          <Icon type="button" disabled={!isError} onClick={resetInput}>
            <SvgSelector
              svg={
                success
                  ? "promocode-form-success"
                  : isError
                  ? "promocode-form-error"
                  : "promocode-form-def"
              }
            />
          </Icon>
        </Wr>
      </Form>
      <ErrorLabel success={success}>
        {success
          ? "Промокод активирован!"
          : errorMessages[errorType] ?? "Введите промокод"}
      </ErrorLabel>
    </>
  );
};

const Form = styled.form`
  width: 100%;
`;
const Icon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 100%;
    height: 100%;
  }
  flex-basis: 14.5%;
  flex-shrink: 0;
  @media ${breakpoints.mobile} {
    flex-basis: 22%;
  }
`;
const ErrorLabel = styled(LoginLabel)`
  text-align: left;
  transition: color 0.3s;
  ${({ success }) => success && `color: #fff !important;`}
`;
const Wr = styled.div`
  display: flex;
  align-items: center;
  background: var(--wh02);
  border-radius: 16px;
  height: 56px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.25vw;
    height: 4.38vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 1.56vw;
    height: 5.47vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.15vw;
    height: 7.54vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 5.02vw;
    height: 17.55vw;
    display: flex;
  }
  border: 1.4px solid transparent;
  transition: border-color 0.2s;
  ${({ success }) =>
    success &&
    `border-color: var(--wh3);
  & > input{
    color: #fff !important;
  }
  `}
  ${({ error }) => error && `border-color: var(--wh12);`};
`;
const Input = styled.input`
  height: 100%;
  flex: auto;
  text-transform: uppercase;
  background-color: transparent;
  padding-left: 1.39vw;
  @media ${breakpoints.desktopXl} {
    padding-left: 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    padding-left: 1.96vw;
  }
  @media ${breakpoints.tabletM} {
    padding-left: 2.69vw;
  }
  @media ${breakpoints.mobile} {
    padding-left: 6.27vw;
    width: 78%;
  }

  ${mixins.fs20}
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--wh6);
  &::placeholder {
    color: var(--wh3);
  }
  &:disabled {
    color: #fff;
  }
`;
